import { styled } from '@mui/material';

const PreviewActions = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  // opacity: 0,
  display: 'flex',
  alignItems: 'center',
  zIndex: 1,
}));

PreviewActions.displayName = 'PreviewActions';

export default PreviewActions;
