import PreviewAction from '@asaprint/asap/components/PreviewAction.js';
import PreviewActions from '@asaprint/asap/components/PreviewActions.js';
import PreviewMark from '@asaprint/asap/components/PreviewMark.js';
import PreviewProgress from '@asaprint/asap/components/PreviewProgress.js';
import PreviewProgressBar from '@asaprint/asap/components/PreviewProgressBar.js';
import ScrollContainer from '@asaprint/asap/components/ScrollContainer.js';
import { useHTMLContext } from '@asaprint/asap/components/slate/context.js';
import useFileUpload, { UploadStatus } from '@asaprint/asap/hooks/useFileUpload.js';
import { Resource } from '@asaprint/asap/schema.client.types.js';
import { getPath, UPLOAD_SIZES } from '@asaprint/common/helpers/resource.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import {
  DROPZONE_FIELD_UPLOADING,
  IMAGE_FIELD_REMOVE_IMAGE_TOOLTIP,
  IMAGE_FIELD_UPLOAD_LABEL,
} from '@engined/client/locales.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloudUpload, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  Tooltip,
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, useController } from 'react-hook-form';

const logger = getLogger('@ui/components/forms/fields/ImageField');

function fileKey(file: File) {
  return `${file.name}-${file.size}-${file.lastModified}`;
}

interface OwnProps {
  className?: string;
  id?: string;
  name: string;
  label: React.ReactNode;
  size?: keyof typeof UPLOAD_SIZES;
  fullWidth?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  multiple?: boolean;
  uploadButtonLabel?: React.ReactNode;
  uploadMoreButtonLabel?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  authorization?: string;
  control?: Control;
}

type Props = OwnProps;

const ImageField: React.FunctionComponent<Props> = ({
  className,
  label,
  name,
  size,
  id,
  fullWidth,
  variant = 'outlined',
  multiple = false,
  uploadButtonLabel,
  uploadMoreButtonLabel,
  startAdornment,
  endAdornment,
  authorization,
  control,
}) => {
  const { t } = useLocale();

  const [uploading, setUploading] = useState<boolean>(false);
  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      validate: (value) => {
        if (uploading) {
          return t(DROPZONE_FIELD_UPLOADING);
        }
      },
    },
  });

  const error = fieldState.error?.message;
  const htmlId = id || name;

  const inputProps: ImageInputOwnProps = useMemo(() => {
    const onUploaded = (resource: Resource) => {
      field.onChange(multiple ? [...(field.value as Resource[]), resource] : resource);
    };

    const onRemove = (resource: Resource) => {
      field.onChange(multiple ? (field.value as Resource[]).filter((r) => r !== resource) : null);
    };

    const onUploadStart = () => {
      setUploading(true);
    };

    const onUploadEnd = () => {
      setUploading(false);
    };

    return {
      multiple,
      name,
      onUploaded,
      onUploadStart,
      onUploadEnd,
      value: field.value,
      uploadButtonLabel,
      uploadMoreButtonLabel,
      onRemove,
      size,
      authorization,
    };
  }, [multiple, field, uploadButtonLabel, uploadMoreButtonLabel, authorization, size, name]);

  return (
    <ImageFieldInner
      className={className}
      error={error}
      fullWidth={fullWidth}
      variant={variant}
      htmlId={htmlId}
      label={label}
      inputProps={inputProps}
      endAdornment={endAdornment}
      startAdornment={startAdornment}
    />
  );
};

ImageField.displayName = 'ImageField';

export default ImageField;

interface ImageFieldInnerOwnProps {
  className: string;
  error: string;
  fullWidth: boolean;
  variant: OwnProps['variant'];
  htmlId: string;
  label: React.ReactNode;
  inputProps: ImageInputOwnProps;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

let ImageFieldInner: React.FunctionComponent<ImageFieldInnerOwnProps> = ({
  className,
  error,
  fullWidth,
  variant,
  htmlId,
  label,
  inputProps,
  startAdornment,
  endAdornment,
}) => (
  <FormControl variant={variant} className={className} error={!!error} fullWidth={fullWidth}>
    <InputLabel htmlFor={htmlId} shrink>
      {label}
    </InputLabel>
    {variant === 'outlined' ? (
      <OutlinedInput
        type="file"
        notched
        label={label}
        inputComponent={ImageInput}
        inputProps={inputProps}
        fullWidth={fullWidth}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
    ) : (
      <Input
        type="file"
        inputComponent={ImageInput}
        inputProps={inputProps}
        fullWidth={fullWidth}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
    )}
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

ImageFieldInner.displayName = 'ImageFieldInner';
ImageFieldInner = React.memo(ImageFieldInner);

const UploadMore = styled(ButtonBase)<{ width: number; height: number }>(({ theme, width, height }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: width,
  height: height,
  cursor: 'pointer',
  border: '1px dashed #ddd',
  borderRadius: 4,
  background: '#eee',
}));

UploadMore.displayName = 'UploadMore';

interface ImageInputOwnProps {
  multiple?: boolean;
  name: string;
  value: Resource | Resource[];
  uploadButtonLabel: React.ReactNode;
  uploadMoreButtonLabel: React.ReactNode;
  size?: keyof typeof UPLOAD_SIZES;
  authorization?: string;
  onUploaded(resource: Resource);
  onUploadStart?();
  onUploadEnd?();
  onRemove(resource: Resource);
}

let ImageInput = React.forwardRef<HTMLDivElement, ImageInputOwnProps>(
  (
    {
      multiple,
      name,
      value,
      onUploaded,
      onUploadEnd,
      onUploadStart,
      uploadButtonLabel,
      uploadMoreButtonLabel,
      onRemove,
      size = 'i400x400',
      authorization,
    },
    ref,
  ) => {
    const { t } = useLocale();
    const [files, setFiles] = useState<File[]>([]);
    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
      accept: {
        'image/*': ['.png', '.jpeg', '.jpg'],
      },
      multiple,
      noClick: true,
      noKeyboard: true,
      onDrop: (acceptedFiles) => {
        setFiles((s) => {
          const alreadyInserted = multiple ? s.map(fileKey) : [];
          return [...(multiple ? s : []), ...acceptedFiles.filter((a) => !alreadyInserted.includes(fileKey(a)))];
        });
      },
    });
    const uploading = useRef<Map<File, boolean>>(new Map<File, boolean>());
    const resourceToFile = useRef<Map<Resource['id'], File>>(new Map<Resource['id'], File>());
    const fileToResource = useRef<Map<File, Resource['id']>>(new Map<File, Resource['id']>());

    const onStart = useEventCallback((file: File) => {
      uploading.current.set(file, true);
      if (onUploadStart && uploading.current.size === 1) {
        onUploadStart();
      }
    });

    const onDone = useEventCallback((file: File) => {
      const hasSome = uploading.current.size > 0;
      uploading.current.delete(file);
      if (onUploadEnd && hasSome && uploading.current.size === 0) {
        onUploadEnd();
      }
    });

    const onRemoveFile = useEventCallback((file: File) => {
      setFiles((s) => (multiple ? s.filter((f) => f !== file) : []));
      if (fileToResource.current.has(file)) {
        const resourceId = fileToResource.current.get(file);
        const resource = multiple
          ? (value as Resource[]).find((r) => r.id === resourceId)
          : (value as Resource).id === resourceId
          ? (value as Resource)
          : null;

        if (resource === null) {
          logger.warn('File not found in the field.value');
        } else {
          onRemove(resource);
        }
        resourceToFile.current.delete(resourceId);
        fileToResource.current.delete(file);
      }
    });

    const onUploadedCallback = useEventCallback((resource: Resource, file: File) => {
      resourceToFile.current.set(resource.id, file);
      fileToResource.current.set(file, resource.id);
      onUploaded(resource);
    });

    return (
      <ScrollContainer sx={{ p: 1 }} ref={ref}>
        <div {...getRootProps()}>
          <input name={name} {...getInputProps()} />
          {files.length === 0 && (multiple ? (value as Resource[])?.length === 0 : !value) ? (
            <Button variant="contained" startIcon={<CloudUpload />} onClick={open}>
              {uploadButtonLabel ?? t(IMAGE_FIELD_UPLOAD_LABEL)}
            </Button>
          ) : (
            <Stack alignItems="center" flexWrap="wrap" direction="row">
              {multiple ? (
                (value as Resource[]).map((resource: Resource) =>
                  resourceToFile.current.has(resource.id) ? null : (
                    <Uploaded
                      key={resource.id}
                      resource={resource}
                      onRemove={onRemove}
                      size={size}
                      multiple={multiple}
                    />
                  ),
                )
              ) : value && !resourceToFile.current.has((value as Resource).id) ? (
                <Uploaded resource={value as Resource} onRemove={onRemove} size={size} multiple={multiple} />
              ) : null}
              {files.map((file, i) => (
                <Preview
                  key={fileKey(file)}
                  file={file}
                  size={size}
                  onUploaded={onUploadedCallback}
                  onStart={onStart}
                  onDone={onDone}
                  onRemove={onRemoveFile}
                  multiple={multiple}
                  authorization={authorization}
                />
              ))}
              {multiple && (
                <UploadMore
                  focusRipple
                  type="button"
                  onClick={open}
                  width={UPLOAD_SIZES[size].width}
                  height={UPLOAD_SIZES[size].height}
                >
                  <CloudUpload />
                  <span>{uploadMoreButtonLabel}</span>
                </UploadMore>
              )}
            </Stack>
          )}
        </div>
      </ScrollContainer>
    );
  },
);

ImageInput.displayName = 'ImageInput';
ImageInput = React.memo(ImageInput);

const PreviewRoot = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => !['height', 'width', 'multiple'].includes(propName as string),
})<{ width: number; height: number; multiple: boolean }>(({ theme, width, height, multiple }) => ({
  marginRight: theme.spacing(2),
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: multiple ? width : undefined,
  height: multiple ? height : undefined,
  border: multiple ? '1px dashed #ddd' : undefined,
  borderRadius: multiple ? 4 : undefined,
  background: multiple ? '#fff' : undefined,
  // '&:hover $actions': {
  //   opacity: 1,
  // },
  // '& $actions': {
  //   [theme.breakpoints.down('lg')]: {
  //     opacity: 1,
  //   },
  // },
}));

PreviewRoot.displayName = 'PreviewRoot';

const ImageTag = styled('img', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'height' && propName !== 'width',
})(({ width, height, theme }) => ({
  display: 'block',
  maxWidth: width,
  maxHeight: height,
}));

ImageTag.displayName = 'ImageTag';

interface PreviewOwnProps {
  file: File;
  size: keyof typeof UPLOAD_SIZES;
  multiple: boolean;
  authorization?: string;

  onUploaded(resource: Resource, file: File);
  onStart?(file: File);
  onDone?(file: File, error?: string);
  onRemove?(file: File);
}

const Preview: React.FunctionComponent<PreviewOwnProps> = ({
  file,
  onUploaded,
  onStart,
  onDone,
  onRemove,
  size,
  multiple,
  authorization,
}) => {
  // const classes = usePreviewStyles({
  //   width: UPLOAD_SIZES[size].width,
  //   height: UPLOAD_SIZES[size].height,
  //   multiple,
  // });
  const { t } = useLocale();
  const [url, setUrl] = useState<string>(null);
  const { baseUrl } = useHTMLContext();
  const [imageLoadError, setImageLoadError] = useState<string>(null);

  const onUploadedCallback = useEventCallback(async (resource: Resource) => {
    const url = `${baseUrl}${getPath(resource, size)}`;
    const image = new Image();
    image.onload = () => {
      setUrl(url);
      onUploaded(resource, file);
    };
    image.onerror = (error) => {
      setImageLoadError(typeof error === 'string' ? error : error.type);
      logger.error(error);
    };
    image.src = url;
  });

  const onRemoveCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove(file);
  });

  const { status, preview, error, progress } = useFileUpload(file, {
    onStart,
    onDone,
    onUploaded: onUploadedCallback,
    authorization,
  });

  return (
    <PreviewRoot width={UPLOAD_SIZES[size].width} height={UPLOAD_SIZES[size].height} multiple={multiple}>
      <div>
        <ImageTag
          src={url || preview}
          alt={file.name}
          width={UPLOAD_SIZES[size].width}
          height={UPLOAD_SIZES[size].height}
        />
      </div>
      <PreviewProgress
        processing={status === UploadStatus.PROCESSING}
        complete={status === UploadStatus.COMPLETE || status === UploadStatus.ERROR}
      >
        <PreviewProgressBar>
          <CircularProgress variant="determinate" value={progress} />
        </PreviewProgressBar>
      </PreviewProgress>
      {status === UploadStatus.COMPLETE && !imageLoadError && (
        <PreviewMark status={UploadStatus.COMPLETE}>
          <Box component="span" sx={{ fontSize: 40 }}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </Box>
        </PreviewMark>
      )}
      {(status === UploadStatus.ERROR || imageLoadError) && (
        <PreviewMark status={UploadStatus.ERROR}>
          <Box component="span" sx={{ fontSize: 40 }}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </Box>
          <Box sx={{ mt: 3, textAlign: 'center' }}>{error ?? imageLoadError}</Box>
        </PreviewMark>
      )}
      {status === UploadStatus.COMPLETE && (
        <PreviewActions>
          <PreviewAction>
            <Tooltip title={t(IMAGE_FIELD_REMOVE_IMAGE_TOOLTIP)}>
              <IconButton color="danger" onClick={onRemoveCallback} size="large">
                <Delete />
              </IconButton>
            </Tooltip>
          </PreviewAction>
        </PreviewActions>
      )}
    </PreviewRoot>
  );
};

interface UploadedOwnProps {
  resource: Resource;
  size: keyof typeof UPLOAD_SIZES;
  multiple: boolean;

  onRemove?(resource: Resource);
}

const Uploaded: React.FunctionComponent<UploadedOwnProps> = ({ resource, size, onRemove, multiple }) => {
  const { t } = useLocale();
  const { baseUrl } = useHTMLContext();
  const url = `${baseUrl}${getPath(resource, size)}`;

  const onRemoveCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove(resource);
  });

  return (
    <PreviewRoot width={UPLOAD_SIZES[size].width} height={UPLOAD_SIZES[size].height} multiple={multiple}>
      <div>
        <ImageTag src={url} alt={resource.name} width={UPLOAD_SIZES[size].width} height={UPLOAD_SIZES[size].height} />
      </div>
      <PreviewActions>
        <PreviewAction>
          <Tooltip title={t(IMAGE_FIELD_REMOVE_IMAGE_TOOLTIP)}>
            <IconButton color="danger" onClick={onRemoveCallback} size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </PreviewAction>
      </PreviewActions>
    </PreviewRoot>
  );
};
