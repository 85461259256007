import { styled } from '@mui/material';

const PreviewAction = styled('div')(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '50%',
  marginLeft: theme.spacing(1),
}));

PreviewAction.displayName = 'PreviewAction';

export default PreviewAction;
