
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            
            const doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FinishOrderReceivedDialog_Save"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"OrderReceivedInput"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"orderReceivedSave"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tasks"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"activeTimeEntry"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]}]}}]}}]}}]}}],"loc":{"start":0,"end":259}};
            doc.loc.source = {"body":"\n          mutation FinishOrderReceivedDialog_Save($input: OrderReceivedInput!) {\n  orderReceivedSave(input: $input) {\n    id\n    updatedAt\n    tasks {\n      id\n      updatedAt\n      activeTimeEntry {\n        id\n        updatedAt\n      }\n    }\n  }\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  

              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const FinishOrderReceivedDialog_Save = oneQuery(doc, "FinishOrderReceivedDialog_Save");
                
