import { isEmpty } from '@asaprint/asap/components/slate/helpers.js';
import { EditorFile, HTMLFile } from '@asaprint/asap/components/slate/plugins/withFiles.js';
import { EditorImage, HTMLImage } from '@asaprint/asap/components/slate/plugins/withImages.js';
import { EditorLink, HTMLLink } from '@asaprint/asap/components/slate/plugins/withLinks.js';
import { EditorMention, HTMLMention } from '@asaprint/asap/components/slate/plugins/withMentions.js';
import { Box } from '@mui/material';
import React from 'react';
import type { Element as SlateElement, FileElement, ImageElement, LinkElement, MentionElement } from 'slate';

interface OwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: SlateElement;
  mode?: 'editor' | 'html';
}

type Props = OwnProps;

const Element: React.FunctionComponent<Props> = ({ attributes, children, element, mode = 'editor' }) => {
  // if (mode === 'html' && isEmpty(element)) {
  //   return null;
  // }

  if (!('type' in element)) {
    return <p {...attributes}>{children}</p>;
  }

  switch (element.type) {
    // case 'block-quote':
    //   return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'list-item':
      return (
        <Box component="li" sx={{ '&:first-child': { marginTop: 0 } }} {...attributes}>
          {children}
        </Box>
      );
    case 'numbered-list':
      return (
        <Box component="ol" sx={{ '&:first-child': { marginTop: 0 } }} {...attributes}>
          {children}
        </Box>
      );
    case 'link':
      if (mode === 'html') {
        return (
          <HTMLLink attributes={attributes} element={element as LinkElement}>
            {children}
          </HTMLLink>
        );
      } else {
        return (
          <EditorLink attributes={attributes} element={element as LinkElement}>
            {children}
          </EditorLink>
        );
      }
    case 'image':
      if (mode === 'html') {
        return (
          <HTMLImage attributes={attributes} element={element as ImageElement}>
            {children}
          </HTMLImage>
        );
      } else {
        return (
          <EditorImage attributes={attributes} element={element as ImageElement}>
            {children}
          </EditorImage>
        );
      }
    case 'file':
      if (mode === 'html') {
        return (
          <HTMLFile attributes={attributes} element={element as FileElement}>
            {children}
          </HTMLFile>
        );
      } else {
        return (
          <EditorFile attributes={attributes} element={element as FileElement}>
            {children}
          </EditorFile>
        );
      }
    case 'mention':
      if (mode === 'html') {
        return (
          <HTMLMention attributes={attributes} element={element as MentionElement}>
            {children}
          </HTMLMention>
        );
      } else {
        return (
          <EditorMention attributes={attributes} element={element as MentionElement}>
            {children}
          </EditorMention>
        );
      }
    default:
      return (
        <Box component="p" m={0} {...attributes}>
          {mode === 'html' && isEmpty(element) ? ' ' : children}
        </Box>
      );
  }
};

Element.displayName = 'Element';

export default React.memo<Props>(Element);
