import { UploadStatus } from '@asaprint/asap/hooks/useFileUpload.js';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material';

const passingThrough = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
`;
const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
    background-color: rgba(0, 0, 0, 0);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, .6);
  }
`;

const PreviewMark = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'status',
})<{ status: UploadStatus }>(({ status, theme }) => ({
  pointerEvents: 'none',
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color:
    status === UploadStatus.COMPLETE
      ? theme.palette.success.main
      : status === UploadStatus.ERROR
      ? theme.palette.error.main
      : null,
  opacity: status === UploadStatus.COMPLETE ? 0 : null,
  backgroundColor: status === UploadStatus.ERROR ? 'rgba(0, 0, 0, .6)' : null,
  animation:
    status === UploadStatus.COMPLETE
      ? `${passingThrough} 3s cubic-bezier(0.77, 0, 0.175, 1)`
      : status === UploadStatus.ERROR
      ? `${slideIn} 3s cubic-bezier(0.77, 0, 0.175, 1)`
      : null,
}));

PreviewMark.displayName = 'PreviewMark';

export default PreviewMark;
