import SectionTitle from '@asaprint/asap/components/SectionTitle.js';
import useInput from '@asaprint/asap/hooks/useInput.js';
import { OrderReceivedInput } from '@asaprint/asap/schema.client.types.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import React, { useCallback } from 'react';
import { TextField } from '@mui/material';

interface OwnProps {
  orderReceived: {
    id: string;
    updatedAt: string;
    expeditionNote?: string;
    productionNote?: string;
    packagingNote?: string;
    assemblyNote?: string;
    invoicingNote?: string;
    accountManagerNote?: string;
  };

  onOrderReceivedUpdate(orderReceived: OrderReceivedInput);
}

type Props = OwnProps;

const OrderReceivedPhasesNotes: React.FunctionComponent<Props> = ({ orderReceived, onOrderReceivedUpdate }) => {
  const save = useCallback(
    (
      name:
        | 'expeditionNote'
        | 'invoicingNote'
        | 'assemblyNote'
        | 'packagingNote'
        | 'productionNote'
        | 'accountManagerNote',
      value: string,
    ) => {
      if (orderReceived[name] !== value) {
        onOrderReceivedUpdate({
          id: orderReceived.id,
          updatedAt: orderReceived.updatedAt,
          [name]: value,
        });
      }
    },
    [onOrderReceivedUpdate, orderReceived],
  );

  const onProductionNoteBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    save('productionNote', value);
  });

  const { inputProps: productionNoteInputProps } = useInput<HTMLInputElement, string>(
    orderReceived.productionNote || '',
    {
      onBlur: onProductionNoteBlur,
      resetIfChanged: true,
    },
  );

  const onPackagingNoteBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    save('packagingNote', value);
  });

  const { inputProps: packagingNoteInputProps } = useInput<HTMLInputElement, string>(
    orderReceived.packagingNote || '',
    {
      onBlur: onPackagingNoteBlur,
      resetIfChanged: true,
    },
  );

  const onAssemblyNoteBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    save('assemblyNote', value);
  });

  const { inputProps: assemblyNoteInputProps } = useInput<HTMLInputElement, string>(orderReceived.assemblyNote || '', {
    onBlur: onAssemblyNoteBlur,
    resetIfChanged: true,
  });

  const onExpeditionNoteBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    save('expeditionNote', value);
  });

  const { inputProps: expeditionNoteInputProps } = useInput<HTMLInputElement, string>(
    orderReceived.expeditionNote || '',
    {
      onBlur: onExpeditionNoteBlur,
      resetIfChanged: true,
    },
  );

  const onInvoicingNoteBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    save('invoicingNote', value);
  });

  const { inputProps: invoicingNoteInputProps } = useInput<HTMLInputElement, string>(
    orderReceived.invoicingNote || '',
    {
      onBlur: onInvoicingNoteBlur,
      resetIfChanged: true,
    },
  );

  const onAccountManagerNoteBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    save('accountManagerNote', value);
  });

  const { inputProps: accountManagerNoteInputProps } = useInput<HTMLInputElement, string>(
    orderReceived.accountManagerNote || '',
    {
      onBlur: onAccountManagerNoteBlur,
      resetIfChanged: true,
    },
  );

  return (
    <div>
      <div>
        <SectionTitle>Poznámka pre výrobu</SectionTitle>
        <TextField multiline fullWidth name="productionNote" {...productionNoteInputProps} />
      </div>
      <div>
        <SectionTitle>Poznámka pre balenie</SectionTitle>
        <TextField multiline fullWidth name="packagingNote" {...packagingNoteInputProps} />
      </div>
      <div>
        <SectionTitle>Poznámka pre montáž</SectionTitle>
        <TextField multiline fullWidth name="assemblyNote" {...assemblyNoteInputProps} />
      </div>
      <div>
        <SectionTitle>Poznámka pre kontrolu (account managera)</SectionTitle>
        <TextField multiline fullWidth name="accountManagerNote" {...accountManagerNoteInputProps} />
      </div>
      <div>
        <SectionTitle>Poznámka pre expedíciu</SectionTitle>
        <TextField multiline fullWidth name="expeditionNote" {...expeditionNoteInputProps} />
      </div>
      <div>
        <SectionTitle>Poznámka pre fakturáciu</SectionTitle>
        <TextField multiline fullWidth name="invoicingNote" {...invoicingNoteInputProps} />
      </div>
    </div>
  );
};

OrderReceivedPhasesNotes.displayName = 'OrderReceivedPhasesNotes';

export default React.memo<Props>(OrderReceivedPhasesNotes);
