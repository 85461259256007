import HasPermission from '@asaprint/asap/components/HasPermission.js';
import { Permission } from '@asaprint/common/access.js';
import { splitNote } from '@asaprint/common/helpers/MoneyOrderReceivedItem.js';
import Nl2Br from '@engined/client/components/Nl2Br.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box } from '@mui/material';
import React from 'react';

const logger = getLogger('components/OrderReceivedNote');

interface OwnProps {
  className?: string;
  note: string;
}

type Props = OwnProps;

const OrderReceivedNote: React.FunctionComponent<Props> = ({ className, note }) => {
  const { firstNote, columns, lastNote } = splitNote(note);

  return (
    <Box fontStyle="italic">
      {firstNote && (
        <Box mb={2}>
          <Nl2Br text={firstNote} />
        </Box>
      )}

      {columns.customer.length + columns.production.length + columns.account.length > 0 && (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
          <HasPermission permission={Permission.ProductsSpecificationCustomer}>
            <Box flex={1}>
              <h3>Špecifikácia pre zákazníka</h3>
              {columns.customer.map((section, index) => (
                <Box mb={4} key={index}>
                  <strong>{section.split(/\r?\n/)[0]}</strong>
                  <br />
                  <Nl2Br text={section.split(/\r?\n/).slice(1).join('\n')} />
                </Box>
              ))}
            </Box>
          </HasPermission>
          <Box flex={1}>
            <h3>Špecifikácia pre výrobu</h3>
            {columns.production.map((section, index) => (
              <Box mb={4} key={index}>
                <strong>{section.split(/\r?\n/)[0]}</strong>
                <br />
                <Nl2Br text={section.split(/\r?\n/).slice(1).join('\n')} />
              </Box>
            ))}
          </Box>
          <HasPermission permission={Permission.ProductsSpecificationAccount}>
            <Box flex={1}>
              <h3>Špecifikácia pre accounta</h3>
              {columns.account.map((section, index) => (
                <Box mb={4} key={index}>
                  <strong>{section.split(/\r?\n/)[0]}</strong>
                  <br />
                  <Nl2Br text={section.split(/\r?\n/).slice(1).join('\n')} />
                </Box>
              ))}
            </Box>
          </HasPermission>
        </Box>
      )}

      {lastNote && (
        <Box mb={2}>
          <Nl2Br text={lastNote} />
        </Box>
      )}
    </Box>
  );
};

OrderReceivedNote.displayName = 'OrderReceivedNote';

export default React.memo<Props>(OrderReceivedNote);
