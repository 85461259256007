import { styled } from '@mui/material';

const Widget = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  position: 'relative',
  margin: theme.spacing(2, 0),
  boxShadow: selected ? '0 0 0 3px #B4D5FF' : undefined,
}));

Widget.displayName = 'Widget';

export default Widget;
