import HTML from '@asaprint/asap/components/slate/HTML.js';
import { ESTIMATED_TIME_TYPE_NAMES, PRODUCT_STATUS_NAMES } from '@asaprint/common/constants/Product.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import { secondsToHours } from '@engined/core/helpers/date.js';
import { Box } from '@mui/material';
import React from 'react';

interface Changes {
  itemsIds: {
    removed?: true;
    added?: true;
    from?: number;
    to?: number;
    item: {
      id: string;
      name: string;
    };
    product: {
      id: string;
      number: string;
      name: string;
    };
  };
  [key: string]: {
    before?: any;
    after?: any;

    removed?: true;
    added?: true;
    from?: number;
    to?: number;
    item?: {
      id: string;
      name: string;
    };
    product?: {
      id: string;
      number: string;
      name: string;
    };
  };
}

interface OwnProps {
  className?: string;
  changes: Changes;
}

type Props = OwnProps;

const ProductLogChanges: React.FunctionComponent<Props> = ({ className, changes }) => {
  return (
    <Box>
      {Object.keys(changes).map((key) => {
        switch (key) {
          case 'status':
            return (
              <div key={key}>
                <strong>Stav</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z <em>{PRODUCT_STATUS_NAMES[changes[key].before]}</em>{' '}
                  </>
                ) : null}
                na <em>{PRODUCT_STATUS_NAMES[changes[key].after]}</em>
              </div>
            );
          case 'expeditionPlacement':
            return (
              <div key={key}>
                <strong>Pozícia v expedícii</strong> zmenená{' '}
                {changes[key].before ? (
                  <>
                    z <em>{changes[key].before}</em>{' '}
                  </>
                ) : null}
                na <em>{changes[key].after}</em>
              </div>
            );
          case 'graphicAssignment':
            return (
              <div key={key}>
                <strong>Zadanie pre grafika</strong> zmenené{' '}
                {changes[key].before ? (
                  <>
                    z:
                    <Box p={1}>
                      <HTML value={changes[key].before} />
                    </Box>{' '}
                  </>
                ) : null}
                na:
                <Box p={1}>
                  <HTML value={changes[key].after} />
                </Box>
              </div>
            );
          case 'phase':
            return (
              <div key={key}>
                <strong>Fáza produktu</strong> zmenená{' '}
                {changes[key].before ? (
                  <>
                    z <em>{changes[key].before.name}</em>{' '}
                  </>
                ) : null}
                na <em>{changes[key].after.name}</em>
              </div>
            );
          case 'photobank':
            return (
              <div key={key}>
                <strong>Počet fotografií</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z <em>{changes[key].before}</em>{' '}
                  </>
                ) : null}
                na <em>{changes[key].after}</em>
              </div>
            );
          case 'responsibleUsers':
            return (
              <div key={key}>
                <strong>Priradená osoba</strong> zmenená z{' '}
                {changes[key].before?.length ? (
                  <em>{changes[key].before.map(displayName).join(', ')}</em>
                ) : (
                  <em>nikto</em>
                )}{' '}
                na{' '}
                {changes[key].after?.length ? (
                  <em>{changes[key].after.map(displayName).join(', ')}</em>
                ) : (
                  <em>nikto</em>
                )}
              </div>
            );
          case 'estimatedTimeType':
            return (
              <div key={key}>
                <strong>Čas - nacenený (typ)</strong> zmenený z{' '}
                <em>{ESTIMATED_TIME_TYPE_NAMES[changes[key].before || 0]}</em> na{' '}
                <em>{ESTIMATED_TIME_TYPE_NAMES[changes[key].after || 0]}</em>
              </div>
            );
          case 'estimatedTime':
            return (
              <div key={key}>
                <strong>Čas - nacenený</strong> zmenený z <em>{secondsToHours(changes[key].before || 0)} hod.</em> na{' '}
                <em>{secondsToHours(changes[key].after || 0)} hod.</em>
              </div>
            );
          case 'productionNote':
            return (
              <div key={key}>
                <strong>Poznámka pre výrobu</strong> zmenená{' '}
                {changes[key].before ? (
                  <>
                    z <em>{changes[key].before}</em>{' '}
                  </>
                ) : null}
                na <em>{changes[key].after}</em>
              </div>
            );
          case 'name':
            return (
              <div key={key}>
                <strong>Názov</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z <em>{changes[key].before}</em>{' '}
                  </>
                ) : null}
                na <em>{changes[key].after}</em>
              </div>
            );
          case 'itemsIds':
            if (changes[key].removed === true) {
              return (
                <div key={key}>
                  Položka <strong>{changes[key].item.name}</strong>{' '}
                  {changes[key].product ? (
                    <>
                      presunutá do produktu <strong>{changes[key].product.name}</strong> na pozíciu{' '}
                      <strong>{changes[key].to + 1}</strong>
                    </>
                  ) : (
                    <>odobratá z produktu</>
                  )}
                </div>
              );
            } else if (changes[key].added === true) {
              return (
                <div key={key}>
                  Položka <strong>{changes[key].item.name}</strong>{' '}
                  {changes[key].product ? (
                    <>
                      presunutá z produktu <strong>{changes[key].product.name}</strong>
                    </>
                  ) : (
                    <>pridaná do produktu</>
                  )}{' '}
                  na pozíciu <strong>{changes[key].to + 1}</strong>
                </div>
              );
            } else {
              return (
                <div key={key}>
                  Položka <strong>{changes[key].item.name}</strong> presunutá na pozíciu{' '}
                  <strong>{changes[key].to + 1}</strong> z pozície <strong>{changes[key].from + 1}</strong>
                </div>
              );
            }
          case 'graphicDesignStartedAt':
            return (
              <div key={key}>
                <strong>Dátum začatia grafických prác</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'productionStartedAt':
            return (
              <div key={key}>
                <strong>Dátum začatia výroby</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          default:
            return (
              <div key={key}>
                <strong>{key}</strong> zmenené{' '}
                {changes[key].before ? (
                  <>
                    z <em>{JSON.stringify(changes[key].before)}</em>{' '}
                  </>
                ) : null}
                na <em>{JSON.stringify(changes[key].after)}</em>
              </div>
            );
        }
      })}
    </Box>
  );
};

ProductLogChanges.displayName = 'ProductLogChanges';

export default React.memo<Props>(ProductLogChanges);
