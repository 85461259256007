import { OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import Nl2Br from '@engined/client/components/Nl2Br.js';
import { Alert, AlertTitle, SxProps } from '@mui/material';
import React from 'react';

interface OwnProps {
  sx?: SxProps;
  orderReceived: {
    phase: string;
    expeditionNote?: string;
    productionNote?: string;
    packagingNote?: string;
    assemblyNote?: string;
    invoicingNote?: string;
    accountManagerNote?: string;
  };
}

const headline: { [key in OrderReceivedPhase]?: string } = {
  [OrderReceivedPhase.PRODUCTION]: 'výrobu',
  [OrderReceivedPhase.PACKAGING]: 'balenie',
  [OrderReceivedPhase.ASSEMBLY]: 'montáž',
  [OrderReceivedPhase.EXPEDITION]: 'expedíciu',
  [OrderReceivedPhase.INVOICE]: 'fakturáciu',
  [OrderReceivedPhase.WAITING_FOR_INVOICE]: 'fakturáciu',
  [OrderReceivedPhase.DELIVERY]: 'expedíciu',
  [OrderReceivedPhase.CHECK_1]: 'kontrolu (account manager)',
  [OrderReceivedPhase.CHECK_2]: 'kontrolu (account manager)',
  [OrderReceivedPhase.PLANNING]: 'kontrolu (account manager)',
};

type Props = OwnProps;

const OrderReceivedPhaseNote: React.FunctionComponent<Props> = ({ orderReceived, sx }) => {
  let note: string;
  if (orderReceived.phase === OrderReceivedPhase.PRODUCTION && orderReceived.productionNote) {
    note = orderReceived.productionNote;
  } else if (orderReceived.phase === OrderReceivedPhase.PACKAGING && orderReceived.packagingNote) {
    note = orderReceived.packagingNote;
  } else if (orderReceived.phase === OrderReceivedPhase.ASSEMBLY && orderReceived.assemblyNote) {
    note = orderReceived.assemblyNote;
  } else if (
    [OrderReceivedPhase.EXPEDITION, OrderReceivedPhase.DELIVERY].includes(orderReceived.phase as OrderReceivedPhase) &&
    orderReceived.expeditionNote
  ) {
    note = orderReceived.expeditionNote;
  } else if (
    (orderReceived.phase === OrderReceivedPhase.INVOICE ||
      orderReceived.phase === OrderReceivedPhase.WAITING_FOR_INVOICE) &&
    orderReceived.invoicingNote
  ) {
    note = orderReceived.invoicingNote;
  } else if (
    (orderReceived.phase === OrderReceivedPhase.CHECK_1 ||
      orderReceived.phase === OrderReceivedPhase.CHECK_2 ||
      orderReceived.phase === OrderReceivedPhase.PLANNING) &&
    orderReceived.accountManagerNote
  ) {
    note = orderReceived.accountManagerNote;
  }

  if (!note) {
    return null;
  }

  return (
    <Alert severity="warning" sx={sx}>
      <AlertTitle>Poznámka pre {headline[orderReceived.phase]}</AlertTitle>
      <div>
        <Nl2Br text={note} />
      </div>
    </Alert>
  );
};

OrderReceivedPhaseNote.displayName = 'OrderReceivedPhaseNote';

export default React.memo<Props>(OrderReceivedPhaseNote);
