import { styled } from '@mui/material';

const PreviewProgress = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'processing' && propName !== 'complete',
})<{ processing: boolean; complete: boolean }>(({ complete, processing, theme }) => ({
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: complete ? 'opacity .4s ease-in' : processing ? 'all .2s linear' : null,
  opacity: complete ? 0 : 1,
}));

PreviewProgress.displayName = 'PreviewProgress';

export default PreviewProgress;
