import { styled } from '@mui/material';

const PreviewProgressBar = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 4,
  background: 'rgba(0, 0, 0, .4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

PreviewProgressBar.displayName = 'PreviewProgressBar';

export default PreviewProgressBar;
