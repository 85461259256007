import { cyan } from '@asaprint/asap/theme.js';
import { progressBarStripes } from '@asaprint/common/helpers/animations.js';
import AutocompleteField, {
  Option,
  Props as AutocompleteFieldProps,
} from '@engined/client/components/forms/fields/AutocompleteField.js';
import { testId } from '@engined/client/helpers/test.js';
import { Chip, chipClasses, styled } from '@mui/material';
import React from 'react';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));

Root.displayName = 'Root';

export type AssignedUserOption = Option<{ disableRemove: boolean; isWorking: boolean }>;

interface OwnProps {
  className?: string;
  options: AssignedUserOption[];
}

type Props<
  TOption extends Option,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = OwnProps &
  Omit<
    AutocompleteFieldProps<TOption, true, DisableClearable, FreeSolo>,
    'options' | 'loading' | 'onInputChange' | 'multiple'
  >;

function AssignedUsersField<
  TOption extends Option,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({ options, ...rest }: Props<TOption, DisableClearable, FreeSolo>) {
  return (
    <Root {...testId(`relation-field-${rest.name}`)}>
      <AutocompleteField
        {...rest}
        className={rest.className}
        multiple
        options={options}
        disableClearable
        getOptionDisabled={(option: AssignedUserOption) => option.data.disableRemove}
        canChange={(value, reason) => {
          if ((value as AssignedUserOption[]).length === 0) {
            return false;
          }
        }}
        renderTags={(tagValue: AssignedUserOption[], getTagProps, { value }) => {
          return tagValue.map((option, index) => {
            const tagProps = getTagProps({ index });
            return (
              <Chip
                key={option.value}
                size="small"
                label={option.label}
                {...tagProps}
                onDelete={
                  // Disabled or last item in the list
                  option.data.disableRemove || (value as AssignedUserOption[]).length <= 1
                    ? undefined
                    : tagProps.onDelete
                }
                sx={[
                  option.data.isWorking && {
                    color: '#fff',
                    backgroundColor: cyan,
                    backgroundImage:
                      'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
                    backgroundSize: '1rem 1rem',
                    animation: `${progressBarStripes} 1s linear infinite`,
                    [`& .${chipClasses['deleteIcon']}`]: {
                      color: '#fff',
                    },
                    [`& .${chipClasses['deleteIcon']}:hover`]: {
                      color: '#fff',
                    },
                  },
                ]}
              />
            );
          });
        }}
      />
    </Root>
  );
}

export default React.memo(AssignedUsersField);
