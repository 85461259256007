import isFQDN from './isFQDN.js';
import isIP from './isIP.js';

interface IsURLOptions {
  protocols?: Array<string | RegExp>;
  requireTld?: boolean;
  requireProtocol?: boolean;
  requireHost?: boolean;
  requirePort?: boolean;
  requireValidProtocol?: boolean;
  allowUnderscores?: boolean;
  allowTrailingDot?: boolean;
  allowProtocolRelativeUrls?: boolean;
  allowFragments?: boolean;
  allowQueryComponents?: boolean;
  validateLength?: boolean;
  disallowAuth?: boolean;
  hostWhitelist?: Array<string | RegExp>;
  hostBlacklist?: Array<string | RegExp>;
}

const wrapped_ipv6 = /^\[([^\]]+)](?::(\d+))?$/;

function isRegExp(obj: string | RegExp): obj is RegExp {
  return Object.prototype.toString.call(obj) === '[object RegExp]';
}

function checkHost(host: string | undefined | null, matches: Array<string | RegExp>) {
  if (!host) {
    return false;
  }

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    if (host === match || (isRegExp(match) && match.test(host))) {
      return true;
    }
  }
  return false;
}

export default function isURL(
  url: string,
  {
    protocols = ['http', 'https', 'ftp'],
    requireTld = true,
    requireProtocol = false,
    requireHost = true,
    requirePort = false,
    requireValidProtocol = true,
    allowUnderscores = false,
    allowTrailingDot = false,
    allowProtocolRelativeUrls = false,
    allowFragments = true,
    allowQueryComponents = true,
    validateLength = true,
    disallowAuth = false,
    hostWhitelist,
    hostBlacklist,
  }: IsURLOptions = {},
) {
  if (!url || /[\s<>]/.test(url)) {
    return false;
  }
  if (url.indexOf('mailto:') === 0) {
    return false;
  }

  if (validateLength && url.length >= 2083) {
    return false;
  }

  if (!allowFragments && url.includes('#')) {
    return false;
  }

  if (!allowQueryComponents && (url.includes('?') || url.includes('&'))) {
    return false;
  }

  let protocol, auth, host, port, port_str, split, ipv6;

  split = url.split('#');
  url = split.shift()!;

  split = url.split('?');
  url = split.shift()!;

  split = url.split('://');
  if (split.length > 1) {
    protocol = split.shift()!.toLowerCase();
    if (requireValidProtocol && protocols.indexOf(protocol) === -1) {
      return false;
    }
  } else if (requireProtocol) {
    return false;
  } else if (url.substring(0, 2) === '//') {
    if (!allowProtocolRelativeUrls) {
      return false;
    }
    split[0] = url.substring(2);
  }
  url = split.join('://');

  if (url === '') {
    return false;
  }

  split = url.split('/');
  url = split.shift()!;

  if (url === '' && !requireHost) {
    return true;
  }

  split = url.split('@');
  if (split.length > 1) {
    if (disallowAuth) {
      return false;
    }
    if (split[0] === '') {
      return false;
    }
    auth = split.shift()!;
    if (auth.indexOf(':') >= 0 && auth.split(':').length > 2) {
      return false;
    }
    const [user, password] = auth.split(':');
    if (user === '' && password === '') {
      return false;
    }
  }
  const hostname = split.join('@');

  port_str = null;
  ipv6 = null;
  const ipv6_match = hostname.match(wrapped_ipv6);
  if (ipv6_match) {
    host = '';
    ipv6 = ipv6_match[1];
    port_str = ipv6_match[2] || null;
  } else {
    split = hostname.split(':');
    host = split.shift();
    if (split.length) {
      port_str = split.join(':');
    }
  }

  if (port_str !== null && port_str.length > 0) {
    port = parseInt(port_str, 10);
    if (!/^\d+$/.test(port_str) || port <= 0 || port > 65535) {
      return false;
    }
  } else if (requirePort) {
    return false;
  }

  if (hostWhitelist) {
    return checkHost(host, hostWhitelist);
  }
  if (!isIP(host) && !isFQDN(host, { requireTld, allowUnderscores, allowTrailingDot }) && (!ipv6 || !isIP(ipv6, 6))) {
    return false;
  }

  host = host || ipv6;

  if (hostBlacklist && checkHost(host, hostBlacklist)) {
    return false;
  }

  return true;
}
