import { useMutation } from '@apollo/client/index.js';
import { StartProductDialog_Save } from '@asaprint/asap/components/dialogs/StartProductDialog.graphql';
import {
  ProductActions,
  StartProductDialog_SaveMutation,
  StartProductDialog_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import Nl2Br from '@engined/client/components/Nl2Br.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { getLogger } from '@engined/core/services/logger.js';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/StartProductDialog');

interface Product {
  id: string;
  productionNote?: string;
  updatedAt: string;
}

interface OwnProps {
  className?: string;
  open?: boolean;
  product: Product;
  productionNote?: string;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const StartProductDialog: React.FunctionComponent<Props> = ({ className, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <StartProductDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

StartProductDialog.displayName = 'StartProductDialog';

export default StartProductDialog;

interface StartProductDialogContentOwnProps {
  product: Product;
  productionNote?: string;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const StartProductDialogContent: React.FunctionComponent<StartProductDialogContentOwnProps> = ({
  product,
  productionNote,
  refetchQueries,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [startExecute, { loading }] = useMutation<
    StartProductDialog_SaveMutation,
    StartProductDialog_SaveMutationVariables
  >(StartProductDialog_Save, { refetchQueries });

  const onStartClick = useEventCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const response = await startExecute({
        variables: {
          input: {
            id: product.id,
            updatedAt: product.updatedAt,
            action: ProductActions.Start,
          },
        },
      });
      onClose(event);
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  return (
    <>
      <DialogTitle>Spustenie produktu</DialogTitle>
      <DialogContent>
        {productionNote && (
          <Box mb={4}>
            <Box component="h3" mb={1}>
              Poznámka pre výrobu z objednávky
            </Box>
            <div>
              <Nl2Br text={productionNote} />
            </div>
          </Box>
        )}

        {product.productionNote && (
          <Box mb={4}>
            <Box component="h3" mb={1}>
              Poznámka pre výrobu z produktu
            </Box>
            <div>
              <Nl2Br text={product.productionNote} />
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onClose} disabled={loading} size="medium">
          Zavrieť
        </Button>
        <LoadingButton type="submit" loading={loading} onClick={onStartClick} size="medium">
          Začať
        </LoadingButton>
      </DialogActions>
    </>
  );
};

StartProductDialogContent.displayName = 'StartProductDialogContent';
