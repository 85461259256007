import { displayName } from '@asaprint/common/helpers/User.js';
import { Box, Chip, Popper, styled, SxProps } from '@mui/material';
import { matchSorter } from 'match-sorter';
import React, { useState } from 'react';
import { Descendant, Editor, MentionElement, Range, Text, Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected } from 'slate-react';

type User = MentionElement['user'];

const withMentions = (editor: Editor): Editor => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === 'mention' || markableVoid(element);
  };

  return editor;
};

const insertMention = (editor: Editor, user: User) => {
  const mention: MentionElement = {
    type: 'mention',
    user,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

interface EditorMentionProps {
  attributes?: any;
  children?: React.ReactNode;
  element: MentionElement;
}

export const EditorMention: React.FC<EditorMentionProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  const text = element.children[0];
  const sx: SxProps = {
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    fontWeight: Text.isText(text) && text.bold ? 'bold' : undefined,
    fontStyle: Text.isText(text) && text.italic ? 'italic' : undefined,
  };

  return (
    <Box component="span" {...attributes} contentEditable={false}>
      <Chip component="span" label={`@${displayName(element.user)}`} size="small" sx={sx} />
      {children}
    </Box>
  );
};

interface HTMLMentionProps {
  attributes?: any;
  children?: React.ReactNode;
  element: MentionElement;
}

export const HTMLMention: React.FC<HTMLMentionProps> = ({ attributes, children, element }) => {
  const text = element.children[0];
  const sx: SxProps = {
    fontWeight: Text.isText(text) && text.bold ? 'bold' : undefined,
    fontStyle: Text.isText(text) && text.italic ? 'italic' : undefined,
  };

  return (
    <Box component="span" {...attributes} contentEditable={false}>
      <Chip component="span" label={`@${displayName(element.user)}`} size="small" sx={sx} />
      {children}
    </Box>
  );
};

export default withMentions;

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }
`,
);

export interface Option {
  label: string;
  user: MentionElement['user'];
}

export function useWithMentions(editor: Editor, options: Option[]) {
  const [target, setTarget] = useState<Range | undefined>();
  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<number>(0);
  const filteredOptions = matchSorter(options, search, { keys: ['label'] });

  return {
    editor,
    onKeyDown: (event: React.KeyboardEvent): boolean | void => {
      if (!target || !filteredOptions.length) {
        return;
      }

      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          setSelected(selected >= filteredOptions.length - 1 ? 0 : selected + 1);
          return false;
        }
        case 'ArrowUp': {
          event.preventDefault();
          setSelected(selected <= 0 ? filteredOptions.length - 1 : selected - 1);
          return false;
        }
        case 'Home': {
          event.preventDefault();
          setSelected(0);
          return false;
        }
        case 'End': {
          event.preventDefault();
          setSelected(filteredOptions.length - 1);
          return false;
        }
        case 'Tab':
        case 'Enter': {
          event.preventDefault();
          Transforms.select(editor, target);
          insertMention(editor, filteredOptions[selected].user);
          setTarget(null);
          return false;
        }
        case 'Escape': {
          event.preventDefault();
          setTarget(null);
          return false;
        }
      }
    },
    onChange: (value: Descendant[]) => {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        const before = wordBefore && Editor.before(editor, wordBefore);
        const beforeRange = before && Editor.range(editor, before, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);

        if (beforeMatch && afterMatch) {
          setTarget(beforeRange);
          setSearch(beforeMatch[1]);
          setSelected(0);
          return;
        }

        setTarget(null);
      }
    },
    popper: (
      <>
        <Popper
          open={!!target}
          anchorEl={() => {
            if (target) {
              return ReactEditor.toDOMRange(editor, target);
            }

            return null;
          }}
        >
          {filteredOptions.length > 0 ? (
            <Listbox role="listbox" onMouseDown={(event) => event.preventDefault()}>
              {filteredOptions.map((option, index) => (
                <li
                  key={option.user.id}
                  role="option"
                  tabIndex={-1}
                  aria-selected={index === selected}
                  onMouseMove={() => {
                    setSelected(index);
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(editor, option.user);
                    setTarget(null);
                  }}
                  onTouchStart={() => {
                    setSelected(index);
                  }}
                >
                  <span>{option.label}</span>
                </li>
              ))}
            </Listbox>
          ) : null}
        </Popper>
      </>
    ),
  };
}
