import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { SLATE_EDITOR_UNDO } from '@engined/client/locales.js';
import { Undo } from '@mui/icons-material';
import ToggleButtonWithTooltip, {
  Props as ToggleButtonWithTooltipProps,
} from '@asaprint/asap/components/ToggleButtonWithTooltip.js';
import React from 'react';
import { useSlate } from 'slate-react';

interface OwnProps {}

type Props = OwnProps & Omit<ToggleButtonWithTooltipProps, 'type' | 'icon' | 'tooltip' | 'value'>;

const UndoButton: React.FunctionComponent<Props> = ({ ...rest }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const onUndo = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    editor.undo();
  });

  return (
    <ToggleButtonWithTooltip onMouseDown={onUndo} tooltip={t(SLATE_EDITOR_UNDO)} value="undo" {...rest}>
      <Undo />
    </ToggleButtonWithTooltip>
  );
};

UndoButton.displayName = 'UndoButton';

export default React.memo<Props>(UndoButton);
