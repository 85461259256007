import useQuery from '@engined/client/hooks/useQuery.js';
import { ProductHistory_Load } from '@asaprint/asap/components/ProductHistory.graphql';
import ProductLogChanges from '@asaprint/asap/components/ProductLogChanges.js';
import TableHeadCell from '@asaprint/asap/components/TableHeadCell.js';
import { ProductHistory_LoadQuery, ProductHistory_LoadQueryVariables } from '@asaprint/asap/schema.client.types.js';
import { PRODUCT_LOG_TYPE_NAMES } from '@asaprint/common/constants/ProductLog.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import Loading from '@engined/client/components/Loading.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';

interface Props {
  id: string;
}

const ProductHistory: React.FC<Props> = ({ id }) => {
  const [show, setShow] = useState<boolean>(false);
  const onToggleHistoryCollapse = useEventCallback((event: React.MouseEvent<HTMLHeadingElement>) => {
    event.preventDefault();
    setShow((s) => !s);
  });

  const { data, loading, error } = useQuery<ProductHistory_LoadQuery, ProductHistory_LoadQueryVariables>(
    ProductHistory_Load,
    { variables: { id }, skip: !show, fetchPolicy: 'cache-and-network' },
  );

  const logEdges = data?.productLogs?.edges ?? [];

  return (
    <Box display={{ xs: 'none', md: 'block' }}>
      <Box
        component="h3"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: 'pointer' }}
        onClick={onToggleHistoryCollapse}
        p={3}
        bgcolor="#f3f3f4"
        my={1}
        fontWeight="bold"
      >
        <span>História produktu</span>
        <IconButton size="small">{show ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
      </Box>
      <Collapse in={show}>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <TableContainer>
            <Table className="table">
              <colgroup>
                <col style={{ width: 150 }} />
                <col />
                <col />
                <col style={{ width: 150 }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Dátum</TableHeadCell>
                  <TableHeadCell>Typ</TableHeadCell>
                  <TableHeadCell>Zmeny</TableHeadCell>
                  <TableHeadCell>Autor</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logEdges.map(({ node: log }) => (
                  <TableRow key={log.id}>
                    <TableCell>
                      <FormatDate date={log.createdAt} format="dd.MM.yyyy HH:mm:ss" />
                    </TableCell>
                    <TableCell>{PRODUCT_LOG_TYPE_NAMES[log.type]}</TableCell>
                    <TableCell>
                      <ProductLogChanges changes={log.changes} />
                    </TableCell>
                    <TableCell>{log.createdBy ? displayName(log.createdBy) : ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Collapse>
    </Box>
  );
};

export default React.memo(ProductHistory);
