import { useMutation } from '@apollo/client/index.js';
import {
  CreateProductDialog_CreateEmpty,
  CreateProductDialog_CreateEmptyFromItems,
} from '@asaprint/asap/components/dialogs/CreateProductDialog.graphql';
import {
  CreateProductDialog_CreateEmptyFromItemsMutation,
  CreateProductDialog_CreateEmptyFromItemsMutationVariables,
  CreateProductDialog_CreateEmptyMutation,
  CreateProductDialog_CreateEmptyMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/CreateProductDialog');

interface OwnProps {
  open?: boolean;
  orderReceivedId: string;
  ids?: string[];
  refetchQueries?: string[];
  name?: string;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const CreateProductDialog: React.FunctionComponent<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <CreateProductDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

CreateProductDialog.displayName = 'CreateProductDialog';

export default CreateProductDialog;

interface FormValues {
  name: string;
}

interface CreateProductDialogContentOwnProps {
  orderReceivedId: string;
  refetchQueries?: string[];
  ids?: string[];
  name?: string;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const CreateProductDialogContent: React.FunctionComponent<CreateProductDialogContentOwnProps> = ({
  orderReceivedId,
  refetchQueries,
  ids,
  onClose,
  name = 'Bez názvu',
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createEmptyExecute] = useMutation<
    CreateProductDialog_CreateEmptyMutation,
    CreateProductDialog_CreateEmptyMutationVariables
  >(CreateProductDialog_CreateEmpty, { refetchQueries });
  const [createEmptyFromItemsExecute] = useMutation<
    CreateProductDialog_CreateEmptyFromItemsMutation,
    CreateProductDialog_CreateEmptyFromItemsMutationVariables
  >(CreateProductDialog_CreateEmptyFromItems, { refetchQueries });

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    try {
      const response = await (ids?.length
        ? createEmptyFromItemsExecute({
            variables: {
              orderReceivedId,
              name: values.name,
              selected: ids,
            },
          })
        : createEmptyExecute({
            variables: {
              orderReceivedId,
              name: values.name,
            },
          }));
      enqueueSnackbar('Úspešne ste vytvorili nový produkt.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        name: name,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <DialogTitle>Vytvorenie produktu</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          type="text"
          variant="standard"
          maxLength={200}
          autoFocus
          fullWidth
          label="Názov nového produktu"
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

CreateProductDialogContent.displayName = 'CreateProductDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.name) {
    errors.name = 'Názov produktu je povinný';
  }

  return errors;
}

export const CreateProductDialogButton: React.FC<
  Omit<LoadingButtonProps, 'loading' | 'onClick'> & {
    orderReceivedId: string;
    refetchQueries?: string[];
    ids?: string[];
    name?: string;
  }
> = ({ orderReceivedId, refetchQueries, ids, name, ...rest }) => {
  const { open, onClose, onOpen } = useDialog<boolean>(false);

  return (
    <>
      <LoadingButton {...rest} loading={open} onClick={onOpen} />
      <CreateProductDialog
        orderReceivedId={orderReceivedId}
        open={open}
        onClose={onClose}
        refetchQueries={refetchQueries}
        ids={ids}
        name={name}
      />
    </>
  );
};
