import useQuery from '@engined/client/hooks/useQuery.js';
import { useMutation } from '@apollo/client/index.js';
import {
  FinishProductDialog_Load,
  FinishProductDialog_Save,
} from '@asaprint/asap/components/dialogs/FinishProductDialog.graphql';
import TimeEntryForm from '@asaprint/asap/components/forms/TimeEntryForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  FinishProductDialog_LoadQuery,
  FinishProductDialog_LoadQueryVariables,
  FinishProductDialog_SaveMutation,
  FinishProductDialog_SaveMutationVariables,
  ProductActions,
} from '@asaprint/asap/schema.client.types.js';
import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';
import { Phases } from '@asaprint/common/constants/Phase.js';
import { EstimatedTimeType } from '@asaprint/common/constants/Product.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Alert, Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Descendant } from 'slate';

const logger = getLogger('components/modals/FinishProductDialog');

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  role: string;
  username: string;
}

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  user: User;
}

interface Phase {
  id: string;
  name: string;
  color: string;
}

interface Task {
  user: User;
}

interface Product {
  id: string;
  tasks?: Task[];
  phase?: Phase;
  updatedAt: string;
  graphicAssignment?: Descendant[];
  estimatedTimeType?: EstimatedTimeType;
  estimatedTime?: number;
  expeditionPlacement?: string;
  logs?: Array<{
    id: string;
    changes: any;
  }>;
  activeTimeEntries?: TimeEntry[];
}

interface OrderReceived {
  id: string;
  moneyDeliveryType?: {
    id: string;
  };
}

interface OwnProps {
  className?: string;
  open?: boolean;
  product: Product;
  orderReceived: OrderReceived;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const FinishProductDialog: React.FunctionComponent<Props> = ({ className, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <FinishProductDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

FinishProductDialog.displayName = 'FinishProductDialog';

export default FinishProductDialog;

interface TimeEntryFormValues {
  id: string;
  updatedAt: string;
  startAt: Date;
  endAt: Date;
  note: string;
  position: string;
}

interface FormValues {
  myActiveTimeEntry: TimeEntryFormValues;
}

interface FinishProductDialogContentOwnProps {
  product: Product;
  orderReceived: OrderReceived;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const FinishProductDialogContent: React.FunctionComponent<FinishProductDialogContentOwnProps> = ({
  product,
  orderReceived,
  onClose,
  refetchQueries,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [saveExecute] = useMutation<FinishProductDialog_SaveMutation, FinishProductDialog_SaveMutationVariables>(
    FinishProductDialog_Save,
    {
      refetchQueries,
    },
  );
  const { data, loading } = useQuery<FinishProductDialog_LoadQuery, FinishProductDialog_LoadQueryVariables>(
    FinishProductDialog_Load,
    { variables: { id: product.id } },
  );

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            id: product.id,
            updatedAt: product.updatedAt,
            action: ProductActions.Finish,
            myActiveTimeEntry: values.myActiveTimeEntry && {
              ...values.myActiveTimeEntry,
              position: values.myActiveTimeEntry.position ? parseInt(values.myActiveTimeEntry.position) : null,
              startAt: values.myActiveTimeEntry.startAt.toISOString(),
              endAt: values.myActiveTimeEntry.endAt.toISOString(),
            },
          },
        },
      });
      enqueueSnackbar('Úspešne ste ukončili produkt.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const phaseId = product.phase?.id;

  const myActiveTimeEntry = product.activeTimeEntries?.find((timeEntry) => timeEntry.user.id === requestUser.id);
  const initialValues = useMemo((): FormValues => {
    const now = new Date();
    const startAt = myActiveTimeEntry?.startAt ? new Date(myActiveTimeEntry.startAt) : new Date();
    const endAt = startAt > now ? startAt : now;
    return {
      myActiveTimeEntry: myActiveTimeEntry
        ? {
            id: myActiveTimeEntry.id,
            updatedAt: myActiveTimeEntry.updatedAt,
            startAt,
            endAt: myActiveTimeEntry.endAt ? new Date(myActiveTimeEntry.endAt) : endAt,
            note: myActiveTimeEntry.note ?? '',
            position: null,
          }
        : null,
    };
  }, [myActiveTimeEntry]);

  const logEdges = data?.productLogs?.edges;
  const hasBeenInPackaging = useMemo(
    (): boolean =>
      logEdges?.some(
        ({ node }) =>
          node.changes.phase?.after?.id === Phases.BIG_FORMAT_PACKAGING ||
          node.changes.phase?.after?.id === Phases.SMALL_FORMAT_PACKAGING,
      ),
    [logEdges],
  );

  const notEmailDelivery = orderReceived.moneyDeliveryType.id !== DeliveryTypes.EMAIL;

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <DialogTitle>Ukončenie produktu</DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        {notEmailDelivery && !product.expeditionPlacement && (
          <Alert severity="error" sx={{ mx: -6, borderRadius: 0, mb: 2 }}>
            Nevyplnili ste pozíciu v expedícii. Prosím, doplňte ju.
          </Alert>
        )}

        {notEmailDelivery && !hasBeenInPackaging && (
          <Alert severity="warning" sx={{ mx: -6, borderRadius: 0, mb: 2 }}>
            Produkt neprešiel fázou balenie. Skutočne netreba produkt baliť?
          </Alert>
        )}

        {(orderReceived.moneyDeliveryType.id === DeliveryTypes.EMAIL || !!product.expeditionPlacement) && (
          <Box mb={2}>Skutočne chcete dokončiť produkt?</Box>
        )}

        {myActiveTimeEntry && (
          <>
            <Box component="h3" mb={1} mt={0}>
              Strávený čas
            </Box>
            <Box mb={5}>
              <Box component="h5" mb={1} mt={1}>
                {displayName(myActiveTimeEntry.user)}
              </Box>
              <TimeEntryForm prefix={`myActiveTimeEntry.`} />
            </Box>
          </>
        )}
      </DialogContent>
      <ConnectedDialogSubmitButtons
        closeOnClick={() => onClose()}
        submitLabel="Uložiť"
        submitDisabled={notEmailDelivery && !product.expeditionPlacement}
      />
    </Form>
  );
};

FinishProductDialogContent.displayName = 'FinishProductDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (values.myActiveTimeEntry) {
    const myActiveTimeEntryErrors: FormErrors<TimeEntryFormValues> = {};
    if (!values.myActiveTimeEntry.startAt) {
      myActiveTimeEntryErrors.startAt = 'Začiatok je povinný';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }

    if (!values.myActiveTimeEntry.endAt) {
      myActiveTimeEntryErrors.endAt = 'Koniec je povinný';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }

    if (values.myActiveTimeEntry.startAt > values.myActiveTimeEntry.endAt) {
      myActiveTimeEntryErrors.endAt = 'Koniec je pred začiatkom';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }
  }

  return errors;
}
