import { useMutation } from '@apollo/client/index.js';
import { OrderReceivedDeliveryDialog_Save } from '@asaprint/asap/components/dialogs/OrderReceivedDeliveryDialog.graphql';
import UserField from '@asaprint/asap/components/forms/fields/UserField.js';
import {
  OrderReceivedDeliveryDialog_SaveMutation,
  OrderReceivedDeliveryDialog_SaveMutationVariables,
  UserField_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Role } from '@asaprint/common/access.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/OrderReceivedDeliveryDialog');

interface OrderReceived {
  id: string;
  updatedAt: string;
}

interface OwnProps {
  open?: boolean;
  orderReceived: OrderReceived;

  onClose?(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const OrderReceivedDeliveryDialog: React.FunctionComponent<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <OrderReceivedDeliveryDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

OrderReceivedDeliveryDialog.displayName = 'OrderReceivedDeliveryDialog';

export default OrderReceivedDeliveryDialog;

interface FormValues {
  deliveryAssignedTo: Option<UserField_UserFragment>;
}

interface OrderReceivedDeliveryDialogContentOwnProps {
  orderReceived: OrderReceived;

  onClose?(event?: React.MouseEvent<HTMLButtonElement>);
}

const roles = [
  Role.Operator,
  Role.PrintOperator,
  Role.AccountManagerAndOperator,
  Role.OperatorAndGraphicDesigner,
  Role.Admin,
  Role.Expediter,
];

const OrderReceivedDeliveryDialogContent: React.FunctionComponent<OrderReceivedDeliveryDialogContentOwnProps> = ({
  orderReceived,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saveExecute] = useMutation<
    OrderReceivedDeliveryDialog_SaveMutation,
    OrderReceivedDeliveryDialog_SaveMutationVariables
  >(OrderReceivedDeliveryDialog_Save);

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          id: orderReceived.id,
          updatedAt: orderReceived.updatedAt,
          deliveryAssignedTo: values.deliveryAssignedTo?.value ?? null,
        },
      });
      enqueueSnackbar('Úspešne ste nastavili zodpovednú osobu za dopravu ASAPRINT.', {
        variant: 'success',
      });
      onClose?.();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        deliveryAssignedTo: null,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <DialogTitle>Objednávka na dopravu ASAPRINT</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Objednávka bola skontrolovaná a čaká na doručenie k zákazníkovi (Doprava ASAPRINT). Vyberte zodpovednú osobu
          za dopravu.
        </DialogContentText>

        <UserField
          active
          roles={roles}
          name="deliveryAssignedTo"
          inputLabel="Priradená osoba"
          inputVariant="standard"
          autoFocus
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={onClose ? () => onClose() : null} submitLabel="Uložiť" />
    </Form>
  );
};

OrderReceivedDeliveryDialogContent.displayName = 'OrderReceivedDeliveryDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.deliveryAssignedTo) {
    errors.deliveryAssignedTo = 'Vyberte zodpovednú osobu';
  }

  return errors;
}
