import type { UPLOAD_SIZES as T_UPLOAD_SIZES } from '../../../../config.js';

export const UPLOAD_SIZES: typeof T_UPLOAD_SIZES = ENV.UPLOAD_SIZES;

export interface Resource {
  type: string;
  dirname: string;
  name: string;
  path: string;
}

export function isImage(resource: Resource): boolean {
  return /^image/.test(resource.type);
}

export function getPath(resource: Resource, size?: keyof typeof UPLOAD_SIZES): string {
  if (size && isImage(resource)) {
    return `/uploads/${resource.dirname}/${String(size)}/${resource.name}`;
  } else {
    return `/uploads/${resource.path}`;
  }
}

export function getCDNPath(resource: Resource, size?: keyof typeof UPLOAD_SIZES): string {
  return `${ENV.CDN_URL}${getPath(resource, size)}`;
}
