import { useMutation } from '@apollo/client/index.js';
import { FinishOrderReceivedDialog_Save } from '@asaprint/asap/components/dialogs/FinishOrderReceivedDialog.graphql';
import TimeEntryForm from '@asaprint/asap/components/forms/TimeEntryForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  FinishOrderReceivedDialog_SaveMutation,
  FinishOrderReceivedDialog_SaveMutationVariables,
  OrderReceivedActions as Actions,
} from '@asaprint/asap/schema.client.types.js';
import { OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import CheckboxField from '@engined/client/components/forms/fields/CheckboxField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { LocaleContextValue } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('components/modals/FinishOrderReceivedDialog');

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  role: string;
  username: string;
}

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  user: User;
}

interface Task {
  id: string;
  updatedAt?: string;
  user: User;
  activeTimeEntry?: TimeEntry;
}

interface OrderReceived {
  id: string;
  updatedAt: string;
  phase: string;
  packageNumber?: string;
  tasks?: Task[];
}

interface OwnProps {
  className?: string;
  open?: boolean;
  orderReceived: OrderReceived;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const titles = {
  [OrderReceivedPhase.PACKAGING]: 'Zabalené',
  [OrderReceivedPhase.DELIVERY]: 'Odovzdané odberateľovi s dodacím lístom',
  [OrderReceivedPhase.ASSEMBLY]: 'Namontované',
  [OrderReceivedPhase.EXPEDITION]: 'Odovzdané kuriérovi s dodacím listom',
};

const question = {
  [OrderReceivedPhase.PACKAGING]: 'Potvrdzujem, že objednávka bola zabalená',
  [OrderReceivedPhase.DELIVERY]: 'Potvrdzujem, že objednávka bola odovzdaná odberateľovi s dodacím lístom',
  [OrderReceivedPhase.ASSEMBLY]: (
    <>
      <div className="mb-1">Potvrdzujem, že:</div>
      <FormControlLabel
        control={<CheckboxField name="isDiscordUploaded" color="primary" />}
        label="som na Discord nahral foto realizácie"
      />
      <FormControlLabel
        control={<CheckboxField name="isReady" color="primary" />}
        label="objednávka je pripravená na prevzatie zákazníkom (v prípade autopolepu prebehla kontrola kvality)"
      />
    </>
  ),
  [OrderReceivedPhase.EXPEDITION]: 'Potvrdzujem, že objednávka bola odovzdané kuriérovi s dodacím listom',
};

const actions = {
  [OrderReceivedPhase.PACKAGING]: Actions.Packaged,
  [OrderReceivedPhase.DELIVERY]: Actions.Delivered,
  [OrderReceivedPhase.ASSEMBLY]: Actions.Assembled,
  [OrderReceivedPhase.EXPEDITION]: Actions.CourierPickup,
};

const FinishOrderReceivedDialog: React.FunctionComponent<Props> = ({ className, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <FinishOrderReceivedDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

FinishOrderReceivedDialog.displayName = 'FinishOrderReceivedDialog';

export default FinishOrderReceivedDialog;

interface TimeEntryFormValues {
  id: string;
  updatedAt: string;
  startAt: Date;
  endAt: Date;
  note: string;
  position: string;
}

interface FormValues {
  timeEntry: TimeEntryFormValues;
  isDiscordUploaded?: boolean;
  isReady?: boolean;
}

interface FinishOrderReceivedDialogContentOwnProps {
  orderReceived: OrderReceived;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const FinishOrderReceivedDialogContent: React.FunctionComponent<FinishOrderReceivedDialogContentOwnProps> = ({
  orderReceived,
  refetchQueries,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [saveExecute] = useMutation<
    FinishOrderReceivedDialog_SaveMutation,
    FinishOrderReceivedDialog_SaveMutationVariables
  >(FinishOrderReceivedDialog_Save, {
    refetchQueries,
  });

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            id: orderReceived.id,
            updatedAt: orderReceived.updatedAt,
            action: actions[orderReceived.phase],
            myActiveTimeEntry: values.timeEntry && {
              ...values.timeEntry,
              position: values.timeEntry.position ? parseInt(values.timeEntry.position) : null,
              startAt: values.timeEntry.startAt.toISOString(),
              endAt: values.timeEntry.endAt.toISOString(),
            },
          },
        },
      });
      enqueueSnackbar('Úspešne ste dokončili fázu.', { variant: 'success' });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const myTask = orderReceived.tasks.find((t) => t.user.id === requestUser.id && t.activeTimeEntry);
  const myTimeEntry = myTask?.activeTimeEntry;
  const initialValues = useMemo((): FormValues => {
    const now = new Date();
    const startAt = myTimeEntry?.startAt ? new Date(myTimeEntry.startAt) : new Date();
    const endAt = startAt > now ? startAt : now;
    return {
      timeEntry: myTimeEntry
        ? {
            id: myTimeEntry.id,
            updatedAt: myTimeEntry.updatedAt,
            startAt,
            endAt: myTimeEntry.endAt ? new Date(myTimeEntry.endAt) : endAt,
            note: myTimeEntry.note ?? '',
            position: null,
          }
        : null,
      isReady: false,
      isDiscordUploaded: false,
    };
  }, [myTimeEntry]);

  return (
    <Form
      defaultValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      context={{ phase: orderReceived.phase }}
    >
      <DialogTitle>{titles[orderReceived.phase]}</DialogTitle>
      <DialogContent>
        {myTimeEntry && (
          <>
            <Box component="h3" mb={1}>
              Strávený čas
            </Box>
            <Box mb={5}>
              <Box component="h5" mb={1}>
                {displayName(myTask.user)}
              </Box>
              <TimeEntryForm prefix={`timeEntry.`} />
            </Box>
          </>
        )}

        <div>{question[orderReceived.phase]}</div>
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

FinishOrderReceivedDialogContent.displayName = 'FinishOrderReceivedDialogContent';

function validate(values: FormValues, t: LocaleContextValue['t'], { phase }: { phase: OrderReceivedPhase }) {
  const errors: FormErrors<FormValues> = {};

  if (values.timeEntry) {
    const timeEntryErrors: FormErrors<TimeEntryFormValues> = {};
    if (!values.timeEntry.startAt) {
      timeEntryErrors.startAt = 'Začiatok je povinný';
      errors.timeEntry = timeEntryErrors;
    }

    if (!values.timeEntry.endAt) {
      timeEntryErrors.endAt = 'Koniec je povinný';
      errors.timeEntry = timeEntryErrors;
    }

    if (values.timeEntry.startAt > values.timeEntry.endAt) {
      timeEntryErrors.endAt = 'Koniec je pred začiatkom';
      errors.timeEntry = timeEntryErrors;
    }
  }

  if (phase === OrderReceivedPhase.ASSEMBLY) {
    if (!values.isDiscordUploaded) {
      errors.isDiscordUploaded = 'Toto pole je povinné';
    }
    if (!values.isReady) {
      errors.isReady = 'Toto pole je povinné';
    }
  }

  return errors;
}
