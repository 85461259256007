import BlockButton from '@asaprint/asap/components/slate/BlockButton.js';
import Element from '@asaprint/asap/components/slate/Element.js';
import Leaf from '@asaprint/asap/components/slate/Leaf.js';
import MarkButton, { toggleMark } from '@asaprint/asap/components/slate/MarkButton.js';
import withFiles, { FileButton } from '@asaprint/asap/components/slate/plugins/withFiles.js';
import withImages, { ImageButton } from '@asaprint/asap/components/slate/plugins/withImages.js';
import withLinks, { LinkButton, UnlinkButton } from '@asaprint/asap/components/slate/plugins/withLinks.js';
import withMentions, {
  Option as WithMentionOption,
  useWithMentions,
} from '@asaprint/asap/components/slate/plugins/withMentions.js';
import RedoButton from '@asaprint/asap/components/slate/RedoButton.js';
import UndoButton from '@asaprint/asap/components/slate/UndoButton.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import {
  SLATE_EDITOR_BOLD,
  SLATE_EDITOR_BULLETED_LIST,
  SLATE_EDITOR_ITALIC,
  SLATE_EDITOR_NUMBERED_LIST,
  SLATE_EDITOR_UNDERLINE,
} from '@engined/client/locales.js';
import { scrollbar } from '@engined/client/styles/mixins.js';
import { isHotkey } from '@engined/core/helpers/keyboard.js';
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
} from '@mui/icons-material';
import { styled, ToggleButtonGroup, toggleButtonGroupClasses } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { createEditor, Descendant, Editor as SlateEditor, MarkTypes, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable.js';

const classes = {
  editable: 'Editor-editable',
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  position: 'relative',
  [`& .${classes.editable}`]: {
    flexGrow: 1,
    outline: 0,
  },
}));

Root.displayName = 'Root';

const Container = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'maxHeight',
})<{ maxHeight: number | string }>(({ theme, maxHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  flexGrow: 1,
  maxHeight: maxHeight,
  overflow: maxHeight ? 'auto' : undefined,
  ...scrollbar,
}));

Container.displayName = 'Container';

const HOTKEYS: { [key: string]: MarkTypes } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

interface OwnProps {
  id: string;
  className?: string;
  value: Descendant[];
  placeholder?: string;
  autoFocus?: boolean;
  maxHeight?: number | string;

  allowImages?: boolean;
  allowFiles?: boolean;

  mentions?: WithMentionOption[];

  onChange(value: Descendant[]);
}

export type Props = OwnProps & Omit<EditableProps, 'value' | 'onChange'>;

const Editor: React.FunctionComponent<Props> = ({
  className,
  id,
  value,
  onChange,
  maxHeight,
  allowImages = false,
  allowFiles = false,
  mentions = [],
  ...rest
}) => {
  const hasMentions = mentions.length > 0;

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo<SlateEditor>(() => {
    let editor = withMentions(withLinks(withHistory(withReact(createEditor()))));
    if (allowFiles) {
      editor = withFiles(editor);
    }

    if (allowImages) {
      editor = withImages(editor);
    }
    return editor;
  }, [allowImages, allowFiles]);

  const withMentionsState = useWithMentions(editor, mentions);

  const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event.nativeEvent)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
        return;
      }
    }

    if (isHotkey('mod+enter', event.nativeEvent)) {
      event.preventDefault();
      Transforms.insertText(editor, '\n');
      return;
    }

    if (hasMentions && withMentionsState.onKeyDown(event) === false) {
      return;
    }
  });

  const onChangeCallback = useEventCallback((value: Descendant[]) => {
    if (hasMentions) {
      withMentionsState.onChange(value);
    }

    onChange(value);
  });

  return (
    <Root className={className}>
      <Slate editor={editor} initialValue={value} onChange={onChangeCallback}>
        <Toolbar allowImages={allowImages} allowFiles={allowFiles} />
        <Container maxHeight={maxHeight}>
          <Editable
            {...rest}
            id={id}
            className={classes.editable}
            onKeyDown={onKeyDown}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Container>
        {hasMentions && withMentionsState.popper}
      </Slate>
    </Root>
  );
};

Editor.displayName = 'Editor';

export default React.memo<Props>(Editor);

const TextIcon = styled('span')(({ theme }) => ({
  width: theme.typography.pxToRem(24),
  fontWeight: 'bold',
}));

TextIcon.displayName = 'TextIcon';

const ToolbarRoot = styled('div')(({ theme }) => ({
  position: 'sticky',
  display: 'flex',
  width: '100%',
  padding: '0 5px',
  top: '58px', // 48px navbar + 10px margin
  zIndex: 5,
  flexWrap: 'wrap',
  [`& .${toggleButtonGroupClasses['root']}`]: {
    margin: theme.spacing(0, 0, 1, 1),
    backgroundColor: theme.palette.background.default,
  },
}));

ToolbarRoot.displayName = 'ToolbarRoot';

interface ToolbarOwnProps {
  allowImages?: boolean;
  allowFiles?: boolean;
}

let Toolbar: React.FunctionComponent<ToolbarOwnProps> = ({ allowImages = false, allowFiles = false }) => {
  const { t } = useLocale();

  return (
    <ToolbarRoot>
      <ToggleButtonGroup size="small">
        <UndoButton tabIndex={-1} />
        <RedoButton tabIndex={-1} />
      </ToggleButtonGroup>
      <ToggleButtonGroup size="small">
        <MarkButton type="bold" icon={<FormatBold />} tooltip={t(SLATE_EDITOR_BOLD)} tabIndex={-1} />
        <MarkButton type="italic" icon={<FormatItalic />} tooltip={t(SLATE_EDITOR_ITALIC)} tabIndex={-1} />
        <MarkButton type="underline" icon={<FormatUnderlined />} tooltip={t(SLATE_EDITOR_UNDERLINE)} tabIndex={-1} />
      </ToggleButtonGroup>{' '}
      <ToggleButtonGroup exclusive size="small">
        <BlockButton
          type="numbered-list"
          icon={<FormatListNumbered />}
          tooltip={t(SLATE_EDITOR_NUMBERED_LIST)}
          tabIndex={-1}
        />
        <BlockButton
          type="bulleted-list"
          icon={<FormatListBulleted />}
          tooltip={t(SLATE_EDITOR_BULLETED_LIST)}
          tabIndex={-1}
        />
      </ToggleButtonGroup>
      {/*<ToggleButtonGroup exclusive size="small">*/}
      {/*  <BlockButton type="paragraph" icon={<TextIcon>P</TextIcon>} tooltip={t(SLATE_EDITOR_PARAGRAPH)} tabIndex={-1} />*/}
      {/*  <BlockButton*/}
      {/*    type="heading-one"*/}
      {/*    icon={<TextIcon>H1</TextIcon>}*/}
      {/*    tooltip={t(SLATE_EDITOR_HEADING_ONE)}*/}
      {/*    tabIndex={-1}*/}
      {/*  />*/}
      {/*  <BlockButton*/}
      {/*    type="heading-two"*/}
      {/*    icon={<TextIcon>H2</TextIcon>}*/}
      {/*    tooltip={t(SLATE_EDITOR_HEADING_TWO)}*/}
      {/*    tabIndex={-1}*/}
      {/*  />*/}
      {/*  <BlockButton*/}
      {/*    type="heading-three"*/}
      {/*    icon={<TextIcon>H3</TextIcon>}*/}
      {/*    tooltip={t(SLATE_EDITOR_HEADING_THREE)}*/}
      {/*    tabIndex={-1}*/}
      {/*  />*/}
      {/*  <BlockButton*/}
      {/*    type="heading-four"*/}
      {/*    icon={<TextIcon>H4</TextIcon>}*/}
      {/*    tooltip={t(SLATE_EDITOR_HEADING_FOUR)}*/}
      {/*    tabIndex={-1}*/}
      {/*  />*/}
      {/*  <BlockButton*/}
      {/*    type="heading-five"*/}
      {/*    icon={<TextIcon>H5</TextIcon>}*/}
      {/*    tooltip={t(SLATE_EDITOR_HEADING_FIVE)}*/}
      {/*    tabIndex={-1}*/}
      {/*  />*/}
      {/*  <BlockButton*/}
      {/*    type="heading-six"*/}
      {/*    icon={<TextIcon>H6</TextIcon>}*/}
      {/*    tooltip={t(SLATE_EDITOR_HEADING_SIX)}*/}
      {/*    tabIndex={-1}*/}
      {/*  />*/}
      {/*</ToggleButtonGroup>*/}
      <ToggleButtonGroup exclusive size="small">
        <LinkButton tabIndex={-1} />
        <UnlinkButton tabIndex={-1} />
        {allowImages && <ImageButton tabIndex={-1} />}
        {allowFiles && <FileButton tabIndex={-1} />}
      </ToggleButtonGroup>
    </ToolbarRoot>
  );
};

Toolbar.displayName = 'Toolbar';
Toolbar = React.memo(Toolbar);
