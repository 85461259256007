import { Reducer, useCallback, useEffect, useReducer } from 'react';

interface InputProps<TInputElement, TValue> {
  value: TValue;
  error: boolean;
  onChange(event: React.ChangeEvent<TInputElement>): void;
  onFocus(event: React.FocusEvent<TInputElement>): void;
  onBlur(event: React.FocusEvent<TInputElement>): void;
}

interface InputReturn<TInputElement, TValue> {
  error: string | null;
  value: TValue;
  inputProps: InputProps<TInputElement, TValue>;
  setTouched(touched: boolean);
  setValue(value: TValue);
}

interface State<TValue> {
  value: TValue;
  error: string | null;
  isValid: boolean;
  isTouched: boolean;
  isFocused: boolean;
}

function reducer<TValue>(state: State<TValue>, action): State<TValue> {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_VALUE':
      return {
        ...state,
        value: action.payload,
      };
    case 'SET_TOUCHED':
      return {
        ...state,
        isTouched: action.payload,
      };
    case 'SET_VALID':
      return {
        ...state,
        isValid: action.payload,
      };
    case 'SET_FOCUSED':
      return {
        ...state,
        isFocused: action.payload,
      };
  }
}

interface InputOptions<TInputElement, TValue> {
  validate?(value: TValue): string;
  mask?: RegExp;
  onBlur?(event: React.FocusEvent<TInputElement>, value: TValue);
  onFocus?(event: React.FocusEvent<TInputElement>, value: TValue);
  resetIfChanged?: boolean;
  defaultTouched?: boolean;
}

export default function useInput<
  TInputElement extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement | HTMLTextAreaElement,
  TValue extends string | boolean = string | boolean,
>(
  value: TValue,
  {
    validate,
    mask,
    onBlur,
    onFocus,
    defaultTouched = false,
    resetIfChanged = false,
  }: InputOptions<TInputElement, TValue> = {},
): InputReturn<TInputElement, TValue> {
  // State and setters for debounced value
  const [state, dispatch] = useReducer<Reducer<State<TValue>, any>>(reducer, {
    value,
    error: null,
    isValid: true,
    isTouched: defaultTouched,
    isFocused: false,
  });

  const onValidate = useCallback(
    (val) => {
      const error = validate ? validate(val) : null;
      dispatch({ type: 'SET_VALID', payload: !error });
      dispatch({ type: 'SET_ERROR', payload: error });
    },
    [validate, dispatch],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<TInputElement>) => {
      const newValue =
        event.currentTarget.type === 'checkbox'
          ? (event.currentTarget as unknown as HTMLInputElement).checked
          : event.currentTarget.value;

      if (mask && typeof newValue === 'string' && !mask.test(newValue)) {
        return;
      }

      dispatch({ type: 'SET_TOUCHED', payload: true });
      dispatch({ type: 'SET_VALUE', payload: newValue });
      onValidate(newValue);
    },
    [dispatch, onValidate, mask],
  );

  const onFocusCallback = useCallback(
    (event: React.FocusEvent<TInputElement>) => {
      dispatch({ type: 'SET_FOCUSED', payload: true });
      if (onFocus) {
        const newValue = (
          event.currentTarget.type === 'checkbox'
            ? (event.currentTarget as unknown as HTMLInputElement).checked
            : event.currentTarget.value
        ) as TValue;
        onFocus(event, newValue);
      }
    },
    [dispatch, onFocus],
  );

  const onBlurCallback = useCallback(
    (event: React.FocusEvent<TInputElement>) => {
      dispatch({ type: 'SET_FOCUSED', payload: false });
      dispatch({ type: 'SET_TOUCHED', payload: true });
      const newValue = (
        event.currentTarget.type === 'checkbox'
          ? (event.currentTarget as unknown as HTMLInputElement).checked
          : event.currentTarget.value
      ) as TValue;
      onValidate(newValue);
      if (onBlur) {
        onBlur(event, newValue);
      }
    },
    [dispatch, onBlur, onValidate],
  );

  const setTouched = useCallback(
    (touched: boolean) => {
      dispatch({ type: 'SET_TOUCHED', payload: touched });
    },
    [dispatch],
  );

  const setValue = useCallback(
    (value: TValue) => {
      dispatch({ type: 'SET_VALUE', payload: value });
    },
    [dispatch],
  );

  useEffect(() => {
    onValidate(value);
  }, [value, onValidate]);

  useEffect(() => {
    if (resetIfChanged) {
      setValue(value);
    }
  }, [resetIfChanged, value, setValue]);

  return {
    value: state.value,
    error: !state.isValid && state.isTouched && state.error ? state.error : null,
    inputProps: {
      value: state.value,
      error: validate && state.isTouched && state.isValid,
      onChange,
      onFocus: onFocusCallback,
      onBlur: onBlurCallback,
    },
    setTouched,
    setValue,
  };
}
