import ImageField from '@asaprint/asap/components/forms/fields/ImageField.js';
import { useHTMLContext } from '@asaprint/asap/components/slate/context.js';
import Widget from '@asaprint/asap/components/slate/Widget.js';
import WidgetButtons from '@asaprint/asap/components/slate/WidgetButtons.js';
import ToggleButtonWithTooltip from '@asaprint/asap/components/ToggleButtonWithTooltip.js';
import { Resource } from '@asaprint/asap/schema.client.types.js';
import { getPath } from '@asaprint/common/helpers/resource.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import CheckboxField from '@engined/client/components/forms/fields/CheckboxField.js';
import HideableDependentField from '@engined/client/components/forms/fields/HideableDependentField.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import {
  CLOSE,
  FIELD_IS_REQUIRED,
  SLATE_WITH_IMAGES_IMAGE_BUTTON_TOOLTIP,
  SLATE_WITH_IMAGES_IMAGE_DIALOG_RESOURCE,
  SLATE_WITH_IMAGES_IMAGE_DIALOG_RESOURCE_BUTTON,
  SLATE_WITH_IMAGES_IMAGE_DIALOG_SUBMIT_BUTTON,
  SLATE_WITH_IMAGES_IMAGE_DIALOG_TITLE,
  SLATE_WITH_IMAGES_IMAGE_DIALOG_URL,
  SLATE_WITH_IMAGES_IMAGE_DIALOG_URL_IS_INVALID,
  SLATE_WITH_LINKS_LINK_BUTTON_TOOLTIP,
  SLATE_WITH_LINKS_LINK_DIALOG_OPEN_NEW_WINDOW,
  SLATE_WITH_LINKS_LINK_DIALOG_SUBMIT_BUTTON,
  SLATE_WITH_LINKS_LINK_DIALOG_TITLE,
  SLATE_WITH_LINKS_LINK_DIALOG_URL,
  SLATE_WITH_LINKS_UNLINK_BUTTON_TOOLTIP,
} from '@engined/client/locales.js';
import { getLogger } from '@engined/core/services/logger.js';
import isURL from '@engined/core/validators/isURL.js';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  Image as ImageIcon,
  Link,
  LinkOff,
} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link as MUILink,
  styled,
  ToggleButtonGroup,
  ToggleButtonProps,
} from '@mui/material';
import React, { useState } from 'react';
import { Editor, Element, ImageElement, NodeEntry, Range, Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlate, useSlateStatic } from 'slate-react';

const logger = getLogger('@ui/components/slate/plugins/withImages');

const IMAGE_SIZE = 'i1600x1600';

const imageExtensions = [
  'ase',
  'art',
  'bmp',
  'blp',
  'cd5',
  'cit',
  'cpt',
  'cr2',
  'cut',
  'dds',
  'dib',
  'djvu',
  'egt',
  'exif',
  'gif',
  'gpl',
  'grf',
  'icns',
  'ico',
  'iff',
  'jng',
  'jpeg',
  'jpg',
  'jfif',
  'jp2',
  'jps',
  'lbm',
  'max',
  'miff',
  'mng',
  'msp',
  'nitf',
  'ota',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pcx',
  'pdn',
  'pgm',
  'PI1',
  'PI2',
  'PI3',
  'pict',
  'pct',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'pdd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'raw',
  'rle',
  'sct',
  'sgi',
  'rgb',
  'int',
  'bw',
  'tga',
  'tiff',
  'tif',
  'vtf',
  'xbm',
  'xcf',
  'xpm',
  '3dv',
  'amf',
  'ai',
  'awg',
  'cgm',
  'cdr',
  'cmx',
  'dxf',
  'e2d',
  'egt',
  'eps',
  'fs',
  'gbr',
  'odg',
  'svg',
  'stl',
  'vrml',
  'x3d',
  'sxd',
  'v2d',
  'vnd',
  'wmf',
  'emf',
  'art',
  'xar',
  'png',
  'webp',
  'jxr',
  'hdp',
  'wdp',
  'cur',
  'ecw',
  'iff',
  'lbm',
  'liff',
  'nrrd',
  'pam',
  'pcx',
  'pgf',
  'sgi',
  'rgb',
  'rgba',
  'bw',
  'int',
  'inta',
  'sid',
  'ras',
  'sun',
  'tga',
];

function loadImage(url): Promise<HTMLImageElement> {
  return new Promise((res, rej) => {
    const img = new window.Image();
    img.onload = function () {
      res(img);
    };
    img.onerror = function () {
      rej(img);
    };
    img.src = url;
  });
}

const isImageUrl = (url): boolean => {
  if (!url) return false;
  if (!isURL(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext);
};

const withImages = (editor: Editor): Editor => {
  const { insertData, isVoid, normalizeNode } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files?.length > 0) {
      for (const file of Array.from(files)) {
        const [mime] = file.type.split('/');
        if (mime !== 'image') {
          continue;
        }

        const data = new FormData();
        const req = new XMLHttpRequest();
        data.append('file', file);
        req.addEventListener('load', (event) => {
          // Set progress
          if (req.status === 200) {
            const resource = req.response[0];
            const url = getPath(resource, IMAGE_SIZE);
            loadImage(url).then((img) => {
              insertImage(editor, url, img, resource, getPath(resource), true);
            });
          } else {
            logger.error(req.response?.error || req.response);
          }
        });

        req.addEventListener('error', (event) => {
          logger.error(new Error('Cannot upload image!'));
        });

        req.responseType = 'json';
        req.open('post', `/uploads/`);
        req.setRequestHeader('CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
        req.withCredentials = true;
        req.send(data);
      }

      // Pass so that another plugin can process files
      insertData(data);
    } else if (isImageUrl(text)) {
      loadImage(text).then((img) => {
        insertImage(editor, text, img, null, text, true);
      });
    } else {
      insertData(data);
    }
  };

  // Keep paragraphs around image
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0 && (Element.isElement(node) || Editor.isEditor(node))) {
      const length = node.children.length;
      const lastChild = node.children[length - 1];
      const firstChild = node.children[0];
      if (Element.isElement(lastChild) && lastChild.type === 'image') {
        const paragraph: Element = { type: 'paragraph', children: [{ text: '' }] };
        Transforms.insertNodes(editor, paragraph, { at: [length] });
        return;
      }
      if (Element.isElement(firstChild) && firstChild.type === 'image') {
        const paragraph: Element = { type: 'paragraph', children: [{ text: '' }] };
        Transforms.insertNodes(editor, paragraph, { at: [0] });
        return;
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
};

const insertImage = (
  editor: Editor,
  url: string,
  { width, height }: HTMLImageElement,
  resource?: Resource,
  link?: string,
  openNewWindow = false,
) => {
  const text = { text: '' };
  const image: ImageElement = {
    type: 'image',
    children: [text],
    url,
    width,
    height,
    align: 'left',
    resource,
    link,
    openNewWindow,
  };
  Transforms.insertNodes(editor, image);
};

const getActiveImage = (editor: Editor, selection?: Range): NodeEntry<ImageElement> => {
  const [image] = Editor.nodes<ImageElement>(editor, {
    at: selection,
    match: (n) => Element.isElement(n) && n.type === 'image',
  });
  return image;
};

export default withImages;

const Image = styled('img')(({ theme }) => ({
  display: 'block',
  boxShadow: 'none',
  maxWidth: '100%',
  maxHeight: '400px',
  height: 'auto',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
}));

Image.displayName = 'Image';

const ImageContainer = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => !['align', 'width', 'height'].includes(propName as string),
})<Pick<ImageElement, 'align' | 'width' | 'height'>>(({ theme, align, width, height }) => ({
  display: 'flex',
  justifyContent: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center',
  position: 'relative',
  margin: theme.spacing(2, 0),
  [theme.breakpoints.down('sm')]: {
    paddingTop: `${((height / width) * 100).toFixed(2)}%`,
  },
}));

ImageContainer.displayName = 'ImageContainer';

interface EditorImageOwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: ImageElement;
}

export const EditorImage: React.FunctionComponent<EditorImageOwnProps> = ({ attributes, children, element }) => {
  const { t } = useLocale();
  const selected = useSelected();
  const focused = useFocused();
  const editor = useSlateStatic();
  const { open: linkDialogOpen, onClose: linkDialogOnClose, onOpen: linkDialogOnOpen } = useDialog<Range>(false);
  const [selection, setSelection] = useState<Range>(null);

  const onAlignLeftClick = useEventCallback((event) => {
    event.preventDefault();
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { align: 'left' }, { at: path });
  });

  const onAlignCenterClick = useEventCallback((event) => {
    event.preventDefault();
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { align: 'center' }, { at: path });
  });

  const onAlignRightClick = useEventCallback((event) => {
    event.preventDefault();
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { align: 'right' }, { at: path });
  });

  const onLinkDialogCancelCallback = useEventCallback(() => {
    linkDialogOnClose();
    if (selection?.focus || selection?.anchor) {
      Transforms.select(editor, selection);
    }
    setSelection(null);
  });

  const onSubmitCallback = useEventCallback(() => {
    linkDialogOnClose();
    setSelection(null);
  });

  const onLinkButtonClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    linkDialogOnOpen();

    setSelection(editor.selection);
  });

  return (
    <div {...attributes}>
      <Widget contentEditable={false} selected={selected && focused}>
        {selected && focused && (
          <WidgetButtons>
            <ToggleButtonGroup size="small">
              <ToggleButtonWithTooltip
                tooltip="Zarovnať doľava"
                value="left"
                onMouseDown={onAlignLeftClick}
                selected={element.align === 'left'}
              >
                <FormatAlignLeft />
              </ToggleButtonWithTooltip>
              <ToggleButtonWithTooltip
                tooltip="Zarovnať dostredu"
                value="center"
                onMouseDown={onAlignCenterClick}
                selected={element.align === 'center'}
              >
                <FormatAlignCenter />
              </ToggleButtonWithTooltip>
              <ToggleButtonWithTooltip
                tooltip="Zarovnať doprava"
                value="right"
                onMouseDown={onAlignRightClick}
                selected={element.align === 'right'}
              >
                <FormatAlignRight />
              </ToggleButtonWithTooltip>
            </ToggleButtonGroup>
            <ToggleButtonGroup size="small">
              <ToggleButtonWithTooltip
                tooltip={t(SLATE_WITH_LINKS_LINK_BUTTON_TOOLTIP)}
                value="imagelink"
                onMouseDown={onLinkButtonClick}
              >
                <Link />
              </ToggleButtonWithTooltip>
              {element.link && <UnlinkButton />}
            </ToggleButtonGroup>
          </WidgetButtons>
        )}
        <ImageContainer align={element.align} width={element.width} height={element.height}>
          <Image src={element.url} width={element.width} height={element.height} />
        </ImageContainer>
      </Widget>
      {children}
      <Dialog open={linkDialogOpen} onClose={onLinkDialogCancelCallback} fullWidth maxWidth="sm" keepMounted={false}>
        <LinkDialogContent onCancel={onLinkDialogCancelCallback} selection={selection} onSubmit={onSubmitCallback} />
      </Dialog>
    </div>
  );
};

EditorImage.displayName = 'EditorImage';

interface HTMLImageOwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: ImageElement;
}

export const HTMLImage: React.FunctionComponent<HTMLImageOwnProps> = ({ attributes, children, element }) => {
  const { baseUrl } = useHTMLContext();

  const image = (
    <Image
      src={element.url.startsWith('http') || element.url.startsWith('${') ? element.url : `${baseUrl}${element.url}`}
      width={element.width}
      height={element.height}
      loading="lazy"
    />
  );

  const href = element.link
    ? /^(https?|mailto|tel):/.test(element.link) || element.link.startsWith('${')
      ? element.link
      : `${baseUrl}${element.link}`
    : '';

  return (
    <ImageContainer
      {...attributes}
      className={attributes?.className}
      align={element.align}
      width={element.width}
      height={element.height}
    >
      {href ? (
        <MUILink href={href} target={element.openNewWindow ? '_blank' : undefined}>
          {image}
        </MUILink>
      ) : (
        image
      )}
      {children}
    </ImageContainer>
  );
};

HTMLImage.displayName = 'HTMLImage';

type ImageButtonOwnProps = Omit<ToggleButtonProps, 'type' | 'icon' | 'tooltip' | 'value'>;

export const ImageButton: React.FunctionComponent<ImageButtonOwnProps> = ({ ...rest }) => {
  const editor = useSlate();
  const { t } = useLocale();
  const { open, onClose, onOpen } = useDialog(false);
  const [selection, setSelection] = useState<Range>(null);

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!ReactEditor.isFocused(editor)) {
      ReactEditor.focus(editor);
    }
    onOpen();
    setSelection(editor.selection);
  });

  const onCancelCallback = useEventCallback(() => {
    onClose();
    if (selection?.focus || selection?.anchor) {
      Transforms.select(editor, selection);
    }
    setSelection(null);
    ReactEditor.focus(editor);
  });

  const onSubmitCallback = useEventCallback(() => {
    onClose();
    setSelection(null);
    ReactEditor.focus(editor);
  });

  return (
    <>
      <ToggleButtonWithTooltip
        tooltip={t(SLATE_WITH_IMAGES_IMAGE_BUTTON_TOOLTIP)}
        {...rest}
        value="image"
        onMouseDown={onClickCallback}
      >
        <ImageIcon />
      </ToggleButtonWithTooltip>
      <Dialog open={open} onClose={onCancelCallback} fullWidth maxWidth="md" keepMounted={false}>
        <ImageDialogContent onCancel={onCancelCallback} selection={selection} onSubmit={onSubmitCallback} />
      </Dialog>
    </>
  );
};

ImageButton.displayName = 'ImageButton';

interface FormValues {
  url: string;
  resource: Resource;
}

interface ImageDialogContentProps {
  selection: Range | null;
  onSubmit();
  onCancel();
}

let ImageDialogContent: React.FunctionComponent<ImageDialogContentProps> = ({ selection, onCancel, onSubmit }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const onSubmitCallback: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const url = values.resource ? getPath(values.resource, IMAGE_SIZE) : values.url;
      const img = await loadImage(url);
      if (selection?.focus || selection?.anchor) {
        Transforms.select(editor, selection);
      }
      insertImage(editor, url, img, values.resource, values.resource && getPath(values.resource), true);
      if (onSubmit) {
        onSubmit();
      }
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Form
      defaultValues={{
        url: '',
        resource: null,
      }}
      onSubmit={onSubmitCallback}
      validate={validate}
      stopSubmitPropagation
    >
      <DialogTitle>{t(SLATE_WITH_IMAGES_IMAGE_DIALOG_TITLE)}</DialogTitle>
      <DialogContent>
        <HideableDependentField dependsOn="resource" value={null}>
          <TextField
            name="url"
            type="url"
            fullWidth
            label={t(SLATE_WITH_IMAGES_IMAGE_DIALOG_URL)}
            variant="standard"
            autoFocus
          />
        </HideableDependentField>
        <HideableDependentField dependsOn="url" value="">
          <ImageField
            fullWidth
            name="resource"
            variant="standard"
            label={t(SLATE_WITH_IMAGES_IMAGE_DIALOG_RESOURCE)}
            uploadButtonLabel={t(SLATE_WITH_IMAGES_IMAGE_DIALOG_RESOURCE_BUTTON)}
            size="i400x400"
          />
        </HideableDependentField>
      </DialogContent>
      <ConnectedDialogSubmitButtons
        closeOnClick={onCancel}
        closeLabel={t(CLOSE)}
        submitLabel={t(SLATE_WITH_IMAGES_IMAGE_DIALOG_SUBMIT_BUTTON)}
      />
    </Form>
  );
};

ImageDialogContent.displayName = 'ImageDialogContent';
ImageDialogContent = React.memo(ImageDialogContent);

function validate(values: FormValues, t: LocaleContextValue['t']): FormErrors<FormValues> {
  const errors: FormErrors<FormValues> = {};
  if (values.url) {
    if (!isURL(values.url)) {
      errors.url = t(SLATE_WITH_IMAGES_IMAGE_DIALOG_URL_IS_INVALID);
    }
  } else if (!values.resource) {
    errors.url = t(FIELD_IS_REQUIRED);
  }

  return errors;
}

interface LinkFormValues {
  url: string;
  openNewWindow: boolean;
}

interface LinkDialogContentProps {
  selection: Range | null;
  onSubmit();
  onCancel();
}

let LinkDialogContent: React.FunctionComponent<LinkDialogContentProps> = ({ selection, onCancel, onSubmit }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const imageActive = selection ? getActiveImage(editor, selection) : null;

  const onSubmitCallback: OnSubmit<LinkFormValues> = (values: LinkFormValues) => {
    try {
      if (selection?.focus || selection?.anchor) {
        Transforms.select(editor, selection);
      }
      Transforms.setNodes(
        editor,
        { ...imageActive, link: values.url, openNewWindow: values.openNewWindow },
        { at: imageActive[1] },
      );
      if (onSubmit) {
        onSubmit();
      }
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Form
      defaultValues={{
        url: imageActive?.[0]?.link ?? '',
        openNewWindow: imageActive?.[0]?.openNewWindow ?? false,
      }}
      onSubmit={onSubmitCallback}
      validate={validateLink}
      stopSubmitPropagation
    >
      <DialogTitle>{t(SLATE_WITH_LINKS_LINK_DIALOG_TITLE)}</DialogTitle>
      <DialogContent>
        <TextField
          name="url"
          type="url"
          fullWidth
          label={t(SLATE_WITH_LINKS_LINK_DIALOG_URL)}
          autoFocus
          variant="standard"
        />
        <FormControlLabel
          control={<CheckboxField name="openNewWindow" color="primary" />}
          label={t(SLATE_WITH_LINKS_LINK_DIALOG_OPEN_NEW_WINDOW)}
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons
        closeOnClick={onCancel}
        closeLabel={t(CLOSE)}
        submitLabel={t(SLATE_WITH_LINKS_LINK_DIALOG_SUBMIT_BUTTON)}
      />
    </Form>
  );
};

LinkDialogContent.displayName = 'LinkDialogContent';
LinkDialogContent = React.memo(LinkDialogContent);

function validateLink(values: LinkFormValues, t: LocaleContextValue['t']): FormErrors<LinkFormValues> {
  const errors: FormErrors<LinkFormValues> = {};
  if (!values.url) {
    errors.url = t(FIELD_IS_REQUIRED);
  }

  return errors;
}

type UnlinkButtonOwnProps = Omit<ToggleButtonProps, 'type' | 'icon' | 'tooltip' | 'value'>;

export const UnlinkButton: React.FunctionComponent<UnlinkButtonOwnProps> = ({ ...rest }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const imageActive = getActiveImage(editor);
    if (imageActive) {
      Transforms.setNodes(editor, { ...imageActive, link: null, openNewWindow: false }, { at: imageActive[1] });
    }
  });

  return (
    <>
      <ToggleButtonWithTooltip
        tooltip={t(SLATE_WITH_LINKS_UNLINK_BUTTON_TOOLTIP)}
        {...rest}
        value="unlink"
        onMouseDown={onClickCallback}
      >
        <LinkOff />
      </ToggleButtonWithTooltip>
    </>
  );
};

UnlinkButton.displayName = 'UnlinkButton';
