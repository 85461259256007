import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { getLogger } from '@engined/core/services/logger.js';
import { ContentCopy } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';

const logger = getLogger('components/CopyText');

interface OwnProps {
  children: React.ReactNode;
  withIcon?: boolean;
}

type Props = OwnProps;

export const copyTextClasses = {
  button: 'CopyText--button',
};

const CopyText: React.FunctionComponent<Props> = ({ children, withIcon = true }) => {
  const content = useRef<HTMLSpanElement>();
  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = useEventCallback(async (event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>) => {
    event.preventDefault();
    try {
      await navigator.clipboard.writeText(content.current.innerText);
      enqueueSnackbar('Skopírované!', { variant: 'success' });
    } catch (err) {
      logger.error(err);
    }
  });

  return (
    <Box
      component="span"
      sx={{
        cursor: withIcon ? 'pointer' : undefined,
        [`&:hover .${copyTextClasses.button}`]: { display: 'inline-block' },
      }}
      onClick={withIcon ? null : onCopyClick}
    >
      <span ref={content}>{children}</span>
      {withIcon && (
        <IconButton
          className={copyTextClasses.button}
          size="small"
          onClick={onCopyClick}
          disableRipple
          sx={{ display: 'none', ml: 2, lineHeight: '1em', p: 0 }}
        >
          <ContentCopy fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

CopyText.displayName = 'CopyText';

export default React.memo(CopyText);
