import { useMutation } from '@apollo/client/index.js';
import { RenameProductDialog_Save } from '@asaprint/asap/components/dialogs/RenameProductDialog.graphql';
import {
  RenameProductDialog_SaveMutation,
  RenameProductDialog_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/RenameProductDialog');

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  role: string;
  username: string;
}

interface Product {
  id: string;
  updatedAt: string;
  name: string;
}

interface OwnProps {
  open?: boolean;
  product: Product;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const RenameProductDialog: React.FunctionComponent<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <RenameProductDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

RenameProductDialog.displayName = 'RenameProductDialog';

export default RenameProductDialog;

interface FormValues {
  name: string;
}

interface RenameProductDialogContentOwnProps {
  product: Product;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const RenameProductDialogContent: React.FunctionComponent<RenameProductDialogContentOwnProps> = ({
  product,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saveExecute] = useMutation<RenameProductDialog_SaveMutation, RenameProductDialog_SaveMutationVariables>(
    RenameProductDialog_Save,
  );

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            id: product.id,
            updatedAt: product.updatedAt,
            name: values.name,
          },
        },
      });
      enqueueSnackbar('Úspešne ste premenovali produkt.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        name: product.name,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <DialogTitle>Premenovanie produktu</DialogTitle>
      <DialogContent>
        <TextField name="name" type="text" variant="standard" maxLength={200} autoFocus fullWidth />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

RenameProductDialogContent.displayName = 'RenameProductDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.name) {
    errors.name = 'Názov produktu je povinný';
  }

  return errors;
}
