import DisplayDuration from '@asaprint/asap/components/DisplayDuration.js';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

interface Phase {
  id: string;
  name: string;
}

interface TimeEntriesPerPhase {
  [phase: string]: {
    duration: number;
    name: string;
  };
}

interface TimeEstimate {
  estimate: number;
  isOverdue: boolean;
  phase: Phase;
}

interface Props {
  phases: Phase[];
  timeEntriesPerPhase: TimeEntriesPerPhase;
  timeEstimatesPerPhase: { [phase: string]: TimeEstimate };
}

const TimeSpentTable: React.FC<Props> = ({ phases, timeEntriesPerPhase, timeEstimatesPerPhase }) => {
  if (!phases.length && !timeEntriesPerPhase['']) {
    return null;
  }

  return (
    <TableContainer>
      <Table sx={{ '& td, & th': { borderBottom: 0 } }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Kalkulácia
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Realita
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {phases.map((phase) => (
            <TableRow key={phase.id} hover>
              <TableCell sx={{ fontWeight: 'bold' }}>{phase.name}:</TableCell>
              <TableCell align="right">
                {timeEstimatesPerPhase[phase.id] && (
                  <span title="Odhad">
                    <DisplayDuration duration={timeEstimatesPerPhase[phase.id].estimate} />
                  </span>
                )}
              </TableCell>
              <Box
                component={TableCell}
                align="right"
                sx={[
                  timeEstimatesPerPhase[phase.id] &&
                    timeEntriesPerPhase[phase.id] &&
                    timeEstimatesPerPhase[phase.id].isOverdue && {
                      color: 'error.main',
                    },
                  timeEstimatesPerPhase[phase.id] &&
                    timeEntriesPerPhase[phase.id] &&
                    !timeEstimatesPerPhase[phase.id].isOverdue && {
                      color: 'success.main',
                    },
                ]}
              >
                {timeEntriesPerPhase[phase.id] && (
                  <span title="Strávený čas">
                    <DisplayDuration duration={timeEntriesPerPhase[phase.id].duration} />
                  </span>
                )}
              </Box>
            </TableRow>
          ))}
          {timeEntriesPerPhase[''] && (
            <TableRow hover>
              <TableCell>{timeEntriesPerPhase[''].name}:</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <DisplayDuration duration={timeEntriesPerPhase[''].duration} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TimeSpentTable;
