import DisplayDuration from '@asaprint/asap/components/DisplayDuration.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import TimeSpentTable from '@asaprint/asap/components/TimeSpentTable.js';
import { ORDER_RECEIVED_PHASE_NAMES, OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';

interface Phase {
  id: string;
  name: string;
}

interface TimeEstimate {
  estimate: number;
  isOverdue: boolean;
  phase: Phase;
}

interface OrderReceived {
  timeEstimates?: TimeEstimate[];
}

interface TimeEntry {
  productId?: string;
  orderReceivedPhase?: string;
  phase?: Phase;
  duration?: number;
}

interface OwnProps {
  className?: string;
  photoBank: number;
  orderReceived: OrderReceived;
  phases: Phase[];
  timeEntries: TimeEntry[];
}

type Props = OwnProps;

const OrderReceivedTimeSpent: React.FunctionComponent<Props> = ({
  className,
  photoBank,
  orderReceived,
  phases,
  timeEntries,
}) => {
  const timeEntriesPerPhase =
    timeEntries?.reduce<{ [phase: string]: { duration: number; name: string } }>((acc, cur) => {
      if (
        cur.orderReceivedPhase === OrderReceivedPhase.PACKAGING ||
        cur.orderReceivedPhase === OrderReceivedPhase.ASSEMBLY
      ) {
        return acc;
      }
      const key = cur.phase?.id ?? '';
      acc[key] = acc[key] ?? { duration: 0, name: cur.phase?.name ?? 'Nezaradené' };
      acc[key].duration += cur.duration;
      return acc;
    }, {}) ?? {};

  const timeEntriesPerOrderReceivedPhase =
    timeEntries?.reduce<{ [phase: string]: { duration: number; name: string } }>((acc, cur) => {
      if (
        cur.orderReceivedPhase !== OrderReceivedPhase.PACKAGING &&
        cur.orderReceivedPhase !== OrderReceivedPhase.ASSEMBLY
      ) {
        return acc;
      }
      const key = cur.orderReceivedPhase;
      acc[key] = acc[key] ?? { duration: 0, name: ORDER_RECEIVED_PHASE_NAMES[cur.orderReceivedPhase] };
      acc[key].duration += cur.duration;
      return acc;
    }, {}) ?? {};

  const timeEstimates = orderReceived?.timeEstimates;
  const timeEstimatesPerPhase =
    timeEstimates?.reduce<{ [phase: string]: TimeEstimate }>((acc, cur) => {
      acc[cur.phase.id] = cur;
      return acc;
    }, {}) ?? {};

  const timePhases = phases.filter((p) => timeEstimatesPerPhase[p.id] || timeEntriesPerPhase[p.id]);

  return (
    <PagePaper title="Grafický čas a fotografie" sx={{ flexGrow: 1 }}>
      <TimeSpentTable
        phases={timePhases}
        timeEntriesPerPhase={timeEntriesPerPhase}
        timeEstimatesPerPhase={timeEstimatesPerPhase}
      />

      {(timePhases.length || timeEntriesPerPhase['']) && <Box component="hr" borderColor="divider" />}

      <TableContainer>
        {Object.keys(timeEntriesPerOrderReceivedPhase).length > 0 && (
          <>
            <Table sx={{ '& td, & th': { borderBottom: 0 } }}>
              <TableBody>
                {Object.keys(timeEntriesPerOrderReceivedPhase).map((id) => (
                  <TableRow key={id} hover>
                    <TableCell>{timeEntriesPerOrderReceivedPhase[id].name}:</TableCell>
                    <TableCell align="right">
                      <DisplayDuration duration={timeEntriesPerOrderReceivedPhase[id].duration} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box component="hr" borderColor="divider" />
          </>
        )}
        <Table sx={{ '& td, & th': { borderBottom: 0 } }}>
          <TableBody>
            <TableRow hover>
              <TableCell>Počet fotografií:</TableCell>
              <TableCell align="right">{photoBank} ks</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PagePaper>
  );
};

OrderReceivedTimeSpent.displayName = 'OrderReceivedTimeSpent';

export default React.memo(OrderReceivedTimeSpent);
