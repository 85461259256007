import React, { useContext } from 'react';

export interface HTMLContextValue {
  baseUrl: string;
  wrapUrls: boolean;
}

const HTMLContext = React.createContext<HTMLContextValue>({
  baseUrl: '',
  wrapUrls: false,
});

export function useHTMLContext(): HTMLContextValue {
  return useContext(HTMLContext);
}

export default HTMLContext;
