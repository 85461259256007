import CancelProductDialog from '@asaprint/asap/components/dialogs/CancelProductDialog.js';
import FinishProductDialog from '@asaprint/asap/components/dialogs/FinishProductDialog.js';
import FinishProductPhaseDialog from '@asaprint/asap/components/dialogs/FinishProductPhaseDialog.js';
import OpenProductDialog from '@asaprint/asap/components/dialogs/OpenProductDialog.js';
import PauseProductDialog from '@asaprint/asap/components/dialogs/PauseProductDialog.js';
import PrintPackageLabelDialog from '@asaprint/asap/components/dialogs/PrintPackageLabelDialog.js';
import RenameProductDialog from '@asaprint/asap/components/dialogs/RenameProductDialog.js';
import StartProductDialog from '@asaprint/asap/components/dialogs/StartProductDialog.js';
import AssignedUsersField, { AssignedUserOption } from '@asaprint/asap/components/forms/fields/AssignedUsersField.js';
import GraphicAssignment from '@asaprint/asap/components/GraphicAssignment.js';
import HasPermission from '@asaprint/asap/components/HasPermission.js';
import OrderReceivedNote from '@asaprint/asap/components/OrderReceivedNote.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import ProductComments from '@asaprint/asap/components/ProductComments.js';
import ProductHistory from '@asaprint/asap/components/ProductHistory.js';
import TableHeadCell from '@asaprint/asap/components/TableHeadCell.js';
import TimeSpentTable from '@asaprint/asap/components/TimeSpentTable.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import useInput from '@asaprint/asap/hooks/useInput.js';
import { TIME_ENTRIES_PRODUCT_CREATIVE_ROUTE, TIME_ENTRIES_PRODUCT_ROUTE } from '@asaprint/asap/routes.js';
import { ProductActions, ProductInput } from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import {
  ESTIMATED_TIME_TYPE_NAMES,
  EstimatedTimeType,
  PRODUCT_STATUS_NAMES,
  ProductStatus,
  statusSx,
} from '@asaprint/common/constants/Product.js';
import { isDuration } from '@asaprint/common/helpers/date.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { parseDuration, secondsToHours } from '@engined/core/helpers/date.js';
import { url } from '@engined/core/services/routes.js';
import { Edit as EditIcon, ExpandLess, ExpandMore, Print as PrintIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Grid,
  IconButton,
  iconButtonClasses,
  InputAdornment,
  MenuItem,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  tableClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, Location } from 'react-router-dom';
import { Descendant } from 'slate';

interface Task {
  user: User;
}

interface OrderReceivedItem {
  id: string;
  name: string;
  count: number;
  unit: string;
  note?: string;
  items?: OrderReceivedItem[];
}

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  duration?: number;
  position?: number;
  phase?: Phase;
  user: User;
}

interface TimeEstimate {
  estimate: number;
  isOverdue: boolean;
  phase: Phase;
}

interface Product {
  id: string;
  name: string;
  position: number;
  expeditionPlacement?: string;
  updatedAt: string;
  tasks?: Task[];
  phase?: Phase;
  status: string;
  items?: OrderReceivedItem[];
  graphicAssignment?: Descendant[];
  estimatedTimeType?: EstimatedTimeType;
  estimatedTime?: number;
  photobank?: number;
  productionNote?: string;
  logs?: Array<{
    id: string;
    type: number;
    createdAt: string;
    createdBy: User;
    changes: any;
  }>;
  activeTimeEntries?: TimeEntry[];
  timeEstimates?: TimeEstimate[];
}

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  role: string;
  username: string;
}

interface Phase {
  id: string;
  name: string;
  color: string;
}

interface OrderReceived {
  id: string;
  moneyDeliveryType?: {
    id: string;
    name: string;
  };
  approvedAt?: string;
  expeditionAt?: string;
  assemblyAt?: string;
  productionNote?: string;
}

interface OwnProps {
  product: Product;
  orderNumber: string;
  users: User[];
  location: Location;
  orderReceived: OrderReceived;
  timeEntries: TimeEntry[];
  phases: Phase[];
  sx?: SxProps;

  onProductChange(product: ProductInput, notify?: boolean);
}

interface FormValues {
  assignedUsers: AssignedUserOption[];
}

type Props = OwnProps;

const validateEstimatedTime = (value: string): string | null => {
  if (!value) {
    return 'Nacenený čas je povinný';
  }
  return isDuration(value) ? null : 'Neplatný formát naceneného času';
};

const refetchQueries = ['OrderReceived_Load'];

const OrderReceivedProduct: React.FunctionComponent<Props> = ({
  product,
  orderNumber,
  users,
  onProductChange,
  location,
  orderReceived,
  timeEntries,
  phases,
  sx,
}) => {
  const { requestUser } = useAuth();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onEstimatedTimeBlurCallback = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    const error = validateEstimatedTime(value);
    const parsed = parseDuration(value);
    if (!error && parsed !== product.estimatedTime) {
      onProductChange({
        id: product.id,
        updatedAt: product.updatedAt,
        estimatedTime: parsed,
      });
    }
  });

  const {
    error: localEstimatedTimeError,
    inputProps: estimatedTimeInputProps,
    setTouched: estimatedTimeSetTouched,
  } = useInput<HTMLInputElement, string>(product.estimatedTime ? secondsToHours(product.estimatedTime) : '', {
    validate: validateEstimatedTime,
    // defaultTouched: true,
    onBlur: onEstimatedTimeBlurCallback,
    resetIfChanged: true,
  });

  const onPhotobankBlurCallback = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    const newValue = value ? parseInt(value, 10) : null;
    if (newValue !== product.photobank) {
      onProductChange({
        id: product.id,
        updatedAt: product.updatedAt,
        photobank: newValue,
      });
    }
  });

  const { error: photobankError, inputProps: photobankInputProps } = useInput<HTMLInputElement, string>(
    product.photobank ? product.photobank.toString() : '',
    {
      onBlur: onPhotobankBlurCallback,
      resetIfChanged: true,
    },
  );

  const onExpeditionPlacementBlurCallback = useEventCallback(
    (event: React.FormEvent<HTMLInputElement>, value: string) => {
      const trimed = value.trim();
      if (product.expeditionPlacement !== trimed) {
        onProductChange({
          id: product.id,
          updatedAt: product.updatedAt,
          expeditionPlacement: trimed,
        });
      }
    },
  );
  const { inputProps: localExpeditionPlacementInputProps } = useInput(product.expeditionPlacement || '', {
    onBlur: onExpeditionPlacementBlurCallback,
    resetIfChanged: true,
  });

  const assignedUsersOptions = useMemo<AssignedUserOption[]>(
    () =>
      users?.map(
        (u): AssignedUserOption => ({
          label: displayName(u),
          value: u.id,
          data: {
            disableRemove: product.activeTimeEntries.some((te) => te.user.id === u.id),
            isWorking: product.activeTimeEntries.some((te) => te.user.id === u.id),
          },
        }),
      ) ?? [],
    [users, product.activeTimeEntries],
  );
  const assignedUsersValues = useMemo<FormValues>(
    () => ({
      assignedUsers: product.tasks
        .map((t) => t.user)
        .map((u) => assignedUsersOptions.find((o) => o.value === u.id))
        .filter(Boolean),
    }),
    [assignedUsersOptions, product.tasks],
  );

  const assignedUsersOnSubmit: OnSubmit<FormValues> = (values) => {
    const value = !values ? [] : values.assignedUsers.map((o) => o.value);
    onProductChange({
      id: product.id,
      updatedAt: product.updatedAt,
      responsibleUsers: value,
    });
  };

  const onEstimatedTimeTypeChangeCallback = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onProductChange({
      id: product.id,
      updatedAt: product.updatedAt,
      estimatedTimeType: parseInt(event.target.value, 10),
    });
    estimatedTimeSetTouched(true);
  });

  const onToggleCollapse = useEventCallback(
    (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement | HTMLButtonElement>) => {
      event.preventDefault();
      setCollapsed((s) => !s);
    },
  );

  const onProductionNoteBlur = useEventCallback((event: React.FormEvent<HTMLInputElement>, value: string) => {
    if (value !== product.productionNote) {
      onProductChange({
        id: product.id,
        updatedAt: product.updatedAt,
        productionNote: value,
      });
    }
  });

  const { inputProps: productionNoteInputProps, value: productionNoteInputValue } = useInput(
    product.productionNote || '',
    {
      onBlur: onProductionNoteBlur,
      resetIfChanged: true,
    },
  );

  const boxRef = useRef<HTMLDivElement>();

  const { open: openDialogOpen, onClose: openDialogOnClose, onOpen: openDialogOnOpen } = useDialog(false);
  const { open: startDialogOpen, onClose: startDialogOnClose, onOpen: startDialogOnOpen } = useDialog(false);
  const { open: pauseDialogOpen, onClose: pauseDialogOnClose, onOpen: pauseDialogOnOpen } = useDialog(false);
  const {
    open: finishPhaseDialogOpen,
    onClose: finishPhaseDialogOnClose,
    onOpen: finishPhaseDialogOnOpen,
  } = useDialog(false);
  const {
    open: finishProductDialogOpen,
    onClose: finishProductDialogOnClose,
    onOpen: finishProductDialogOnOpen,
  } = useDialog(false);
  const { open: cancelDialogOpen, onClose: cancelDialogOnClose, onOpen: cancelDialogOnOpen } = useDialog(false);
  const {
    open: renameProductDialogOpen,
    onClose: renameProductDialogOnClose,
    onOpen: renameProductDialogOnOpen,
  } = useDialog<boolean>(false);

  useEffect(() => {
    if (location.hash === `#product-${product.id}`) {
      setCollapsed(false);
    }
  }, [location, product.id, setCollapsed]);

  const onCollapseOpened = useCallback(() => {
    if (location.hash === `#product-${product.id}` && boxRef.current) {
      const top = boxRef.current.getBoundingClientRect().top;
      const offsetPosition = top + window.scrollY - document.querySelector('header').getBoundingClientRect().height;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [location.hash, product.id, boxRef]);

  const isIWorking = product.activeTimeEntries.some((t) => t.user.id === requestUser.id);
  const isOthersWorking = product.activeTimeEntries.some((t) => t.user.id !== requestUser.id);

  const canOpen = product.status === ProductStatus.NEW;
  const canProcess =
    (product.status === ProductStatus.OPEN ||
      product.status === ProductStatus.PAUSED ||
      product.status === ProductStatus.PROCESSING) &&
    !isIWorking;
  const canPause = product.status === ProductStatus.PROCESSING && isIWorking;
  const canCancel =
    product.status !== ProductStatus.CANCELED && product.status !== ProductStatus.DONE && !cancelDialogOpen;
  const canFinishPhase =
    (product.status === ProductStatus.PROCESSING || product.status === ProductStatus.PAUSED) && !isOthersWorking;
  const canFinishProduct =
    (product.status === ProductStatus.PROCESSING || product.status === ProductStatus.PAUSED) && !isOthersWorking;

  const {
    open: printPackageLabelDialogOpen,
    onClose: printPackageLabelDialogOnClose,
    onOpen: printPackageLabelDialogOnOpen,
  } = useDialog(false);

  const onStartCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (product.productionNote || productionNoteInputValue || orderReceived.productionNote) {
      startDialogOnOpen();
    } else {
      onProductChange({
        id: product.id,
        updatedAt: product.updatedAt,
        action: ProductActions.Start,
      });
    }
  });

  const onCancelCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (product.status !== ProductStatus.CANCELED) {
      cancelDialogOnOpen();
    }
  });

  const onPauseCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (product.status !== ProductStatus.PAUSED) {
      if (product.activeTimeEntries?.length) {
        pauseDialogOnOpen();
      } else {
        onProductChange({
          id: product.id,
          updatedAt: product.updatedAt,
          action: ProductActions.Pause,
        });
      }
    }
  });

  const timeEntriesPerPhase = useMemo<{ [phase: string]: { duration: number; name: string } }>(
    () =>
      timeEntries?.reduce((acc, cur) => {
        const key = cur.phase?.id ?? '';
        acc[key] = acc[key] ?? { duration: 0, name: cur.phase?.name ?? 'Nezaradené' };
        acc[key].duration += cur.duration;
        return acc;
      }, {}) ?? {},
    [timeEntries],
  );

  const timeEstimates = product.timeEstimates;
  const timeEstimatesPerPhase =
    timeEstimates?.reduce((acc, cur) => {
      acc[cur.phase.id] = cur;
      return acc;
    }, {}) ?? {};

  const timePhases = phases.filter((p) => timeEstimatesPerPhase[p.id] || timeEntriesPerPhase[p.id]);

  return (
    <PagePaper
      ref={boxRef}
      id={`product-${product.id}`}
      sx={{
        scrollMarginTop: '50px', // 48px header + 2px for nice padding
        ...sx,
      }}
      borderTop
    >
      <Box
        display="flex"
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Heading
          title={product.name}
          subtitle={`${orderNumber}-${product.position}`}
          onClick={onToggleCollapse}
          onRenameClick={product.status !== ProductStatus.DONE ? renameProductDialogOnOpen : undefined}
        />
        <Box display="flex" gap={2} ml={{ xs: 0, md: 4 }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box display="flex" alignItems="flex-start" gap={2} mt={3}>
            <HasPermission permission={Permission.TimeEntriesRead}>
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={url(TIME_ENTRIES_PRODUCT_ROUTE, { orderReceived: orderReceived.id, product: product.id })}
                state={{ fromOrderReceived: true }}
              >
                Log
              </Button>{' '}
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={url(TIME_ENTRIES_PRODUCT_CREATIVE_ROUTE, {
                  orderReceived: orderReceived.id,
                  product: product.id,
                })}
                state={{ fromOrderReceived: true }}
              >
                Log: kreatíva
              </Button>{' '}
            </HasPermission>
            <Button
              color="primary"
              variant="outlined"
              onClick={printPackageLabelDialogOnOpen}
              startIcon={<PrintIcon />}
            >
              Štítok
            </Button>
          </Box>
          <TextField label={<>Pozícia v&nbsp;expedícii</>} {...localExpeditionPlacementInputProps} size="small" />
          <Form
            values={assignedUsersValues}
            onSubmit={assignedUsersOnSubmit}
            onChange={(data, { name, type }, form) => {
              form.handleSubmit((values) => assignedUsersOnSubmit(values, form))();
            }}
          >
            <AssignedUsersField
              name="assignedUsers"
              options={assignedUsersOptions}
              inputLabel="Priradená osoba"
              size="small"
              sx={{ minWidth: { xs: '100%', md: '300px' } }}
            />
          </Form>
        </Box>
        <Box display={{ xs: 'none', md: 'flex' }} alignItems="flex-start" mt={3}>
          <IconButton onClick={onToggleCollapse}>{collapsed ? <ExpandMore /> : <ExpandLess />}</IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        mt={1}
      >
        <Box display="flex" gap={2} mb={{ xs: 2, md: 0 }}>
          {product.phase ? (
            <Chip
              label={product.phase.name}
              sx={{
                backgroundColor: `#${product.phase.color}`,
                color: (theme) => theme.palette.getContrastText(`#${product.phase.color}`),
              }}
            />
          ) : null}
          <Chip label={PRODUCT_STATUS_NAMES[product.status]} sx={{ ...statusSx[product.status] }} />
        </Box>
        <Box>
          <ButtonGroup sx={{ mr: 1, mb: 2 }}>
            <Button
              disabled={!canOpen}
              color={canOpen ? 'primary' : 'secondary'}
              variant={canOpen ? 'contained' : 'outlined'}
              onClick={openDialogOnOpen}
              disableElevation
              size="small"
            >
              Spustiť
            </Button>
            <HasPermission permission={Permission.ProductsCancel}>
              <Button
                disabled={!canCancel}
                color={canCancel ? 'primary' : 'secondary'}
                variant={canCancel ? 'contained' : 'outlined'}
                onClick={onCancelCallback}
                size="small"
                disableElevation
                sx={{ borderLeft: 1 }}
              >
                Stornovať
              </Button>
            </HasPermission>
          </ButtonGroup>
          <ButtonGroup sx={{ mr: 1, mb: 2 }}>
            <Button
              disabled={!canProcess}
              color={canProcess ? 'primary' : 'secondary'}
              variant={canProcess ? 'contained' : 'outlined'}
              onClick={onStartCallback}
              disableElevation
              size="small"
            >
              {product.status === ProductStatus.PAUSED ? 'Pokračovať' : 'Začať'}
            </Button>
            <Button
              disabled={!canPause}
              color={canPause ? 'primary' : 'secondary'}
              variant={canPause ? 'contained' : 'outlined'}
              onClick={onPauseCallback}
              disableElevation
              size="small"
              sx={{ borderLeft: 1 }}
            >
              Pozastaviť
            </Button>
          </ButtonGroup>
          <ButtonGroup sx={{ mr: 1, mb: 2 }}>
            <Button
              disabled={!canFinishPhase}
              color={canFinishPhase ? 'primary' : 'secondary'}
              variant={canFinishPhase ? 'contained' : 'outlined'}
              onClick={finishPhaseDialogOnOpen}
              size="small"
              disableElevation
            >
              Dokončiť fázu
            </Button>
            <Button
              disabled={!canFinishProduct}
              color={canFinishProduct ? 'primary' : 'secondary'}
              variant={canFinishProduct ? 'contained' : 'outlined'}
              onClick={finishProductDialogOnOpen}
              size="small"
              disableElevation
              sx={{ borderLeft: 1 }}
            >
              Dokončiť produkt
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Collapse in={!collapsed} onEntered={onCollapseOpened}>
        <SectionTitle>Položky</SectionTitle>
        <TableContainer sx={{ mb: 4 }}>
          <Table>
            <colgroup>
              <col />
              <col style={{ width: 60 }} />
              <col style={{ width: 80 }} />
              <col style={{ width: 60 }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableHeadCell>Názov</TableHeadCell>
                <TableHeadCell color="primary.main" align="right">
                  Počet
                </TableHeadCell>
                <TableHeadCell color="primary.main" align="right">
                  Jednotka
                </TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {product.items.map((item) => (
                <ItemRow key={item.id} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <SectionTitle>Poznámka pre výrobu</SectionTitle>
        <Box mb={4}>
          <TextField
            fullWidth
            placeholder="Sem môžeš napísať textovú poznámku pre výrobu..."
            multiline
            minRows={6}
            {...productionNoteInputProps}
          />
        </Box>

        <SectionTitle>Kalkulovaný a odpracovaný čas</SectionTitle>
        <Box
          mb={4}
          sx={{
            [`& .${tableClasses.root}`]: {
              width: { xs: '100%', md: '50%' },
            },
          }}
        >
          <TimeSpentTable
            phases={timePhases}
            timeEntriesPerPhase={timeEntriesPerPhase}
            timeEstimatesPerPhase={timeEstimatesPerPhase}
          />
        </Box>

        <HasPermission permission={Permission.ProductCommentsManage}>
          <SectionTitle>Zadanie pre grafika</SectionTitle>
          <Box mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Box borderRight={1} borderColor="divider" pr={2} height="100%">
                  <TextField
                    select
                    fullWidth
                    value={product.estimatedTimeType ?? EstimatedTimeType.NOT_SET}
                    onChange={onEstimatedTimeTypeChangeCallback}
                    label="Čas - nacenený"
                  >
                    <MenuItem value={EstimatedTimeType.NOT_SET}>
                      {ESTIMATED_TIME_TYPE_NAMES[EstimatedTimeType.NOT_SET]}
                    </MenuItem>
                    <MenuItem value={EstimatedTimeType.EXACT}>
                      {ESTIMATED_TIME_TYPE_NAMES[EstimatedTimeType.EXACT]}
                    </MenuItem>
                    <MenuItem value={EstimatedTimeType.ORIENTATIONAL}>
                      {ESTIMATED_TIME_TYPE_NAMES[EstimatedTimeType.ORIENTATIONAL]}
                    </MenuItem>
                  </TextField>

                  {(product.estimatedTimeType === EstimatedTimeType.EXACT ||
                    product.estimatedTimeType === EstimatedTimeType.ORIENTATIONAL) && (
                    <TextField
                      fullWidth
                      name="estimatedTime"
                      label="Čas - nacenený - počet hodín"
                      {...estimatedTimeInputProps}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">hod.</InputAdornment>,
                      }}
                      error={!!localEstimatedTimeError}
                      helperText={localEstimatedTimeError}
                    />
                  )}
                  <TextField
                    fullWidth
                    name="photobank"
                    label="Počet fotografií"
                    {...photobankInputProps}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">ks</InputAdornment>,
                    }}
                    inputProps={{ step: '1' }}
                    error={!!photobankError}
                    helperText={photobankError}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <GraphicAssignment product={product} onProductChange={onProductChange} />
              </Grid>
            </Grid>
          </Box>

          {!collapsed && (
            <div>
              <ProductComments productId={product.id} />
            </div>
          )}
        </HasPermission>

        <ProductHistory id={product.id} />
      </Collapse>

      <OpenProductDialog
        open={openDialogOpen}
        showDatesWarning={!orderReceived.assemblyAt || !orderReceived.expeditionAt || !orderReceived.approvedAt}
        product={product}
        onClose={openDialogOnClose}
        productionNote={orderReceived.productionNote}
      />
      <StartProductDialog
        open={startDialogOpen}
        product={product}
        onClose={startDialogOnClose}
        productionNote={orderReceived.productionNote}
        refetchQueries={refetchQueries}
      />
      <PauseProductDialog
        open={pauseDialogOpen}
        product={product}
        onClose={pauseDialogOnClose}
        refetchQueries={refetchQueries}
      />
      <FinishProductPhaseDialog
        open={finishPhaseDialogOpen}
        showDatesWarning={!orderReceived.assemblyAt || !orderReceived.expeditionAt || !orderReceived.approvedAt}
        product={product}
        onClose={finishPhaseDialogOnClose}
        productionNote={orderReceived.productionNote}
        refetchQueries={refetchQueries}
      />
      <FinishProductDialog
        open={finishProductDialogOpen}
        product={product}
        onClose={finishProductDialogOnClose}
        orderReceived={orderReceived}
        refetchQueries={refetchQueries}
      />
      <CancelProductDialog open={cancelDialogOpen} product={product} onClose={cancelDialogOnClose} />
      <PrintPackageLabelDialog
        open={printPackageLabelDialogOpen}
        productId={product.id}
        onClose={printPackageLabelDialogOnClose}
      />
      <RenameProductDialog open={renameProductDialogOpen} product={product} onClose={renameProductDialogOnClose} />
    </PagePaper>
  );
};

OrderReceivedProduct.displayName = 'OrderReceivedProduct';

export default React.memo(OrderReceivedProduct);

interface ItemRowOwnProps {
  item: OrderReceivedItem;
}

const ItemRow: React.FunctionComponent<ItemRowOwnProps> = ({ item }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onToggleCollapse = useEventCallback((event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    event.preventDefault();
    setCollapsed((s) => !s);
  });

  return (
    <>
      <TableRow sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: 0 } }}>
        <TableCell>
          <Box onClick={onToggleCollapse} sx={{ cursor: 'pointer' }}>
            {item.name}
          </Box>
        </TableCell>
        <TableCell align="right">{item.count}</TableCell>
        <TableCell align="right">{item.unit}</TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={onToggleCollapse}>
            {collapsed ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </TableCell>
      </TableRow>
      {item.items.map((subitem, index) => (
        <TableRow key={subitem.id} sx={{ ml: 2, [`& .${tableCellClasses.root}`]: { borderBottom: 0 } }}>
          <TableCell>
            <Box display="flex">
              <Box overflow="hidden" my={-2} width={34}>
                <Box
                  component="svg"
                  width={34}
                  height="100%"
                  viewBox="0 0 150 150"
                  display="block"
                  preserveAspectRatio="xMidYMin"
                >
                  <line
                    x1="20%"
                    y1="0"
                    x2="20%"
                    y2={index + 1 === item.items.length ? '50%' : '1000%'}
                    style={{ stroke: '#000' }}
                  />
                  <line x1="20%" y1="50%" x2="80%" y2="50%" style={{ stroke: '#000' }} />
                </Box>
              </Box>
              <Box flex={1}>{subitem.name}</Box>
            </Box>
          </TableCell>
          <TableCell align="right">{subitem.count}</TableCell>
          <TableCell align="right">{subitem.unit}</TableCell>
          <TableCell />
        </TableRow>
      ))}
      <TableRow>
        <TableCell colSpan={4} sx={{ p: 0 }}>
          <Collapse in={!collapsed}>
            {item.note && (
              <Box p={2}>
                <OrderReceivedNote note={item.note} />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

let Heading: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  onClick(event: React.MouseEvent<HTMLDivElement>);
  onRenameClick?(event: React.MouseEvent<HTMLButtonElement>);
}> = ({ title, subtitle, onClick, onRenameClick }) => {
  return (
    <Box
      onClick={onClick}
      flex={1}
      sx={{ cursor: 'pointer', [`&:hover .${iconButtonClasses.root}`]: { display: 'inline' } }}
    >
      <Box component="h2" my={0} fontSize="1.4rem" fontWeight="300">
        {title}{' '}
        {onRenameClick && (
          <IconButton
            disableRipple
            size="small"
            color="primary"
            onClick={onRenameClick}
            sx={{ display: { xs: 'inline', md: 'none' }, p: 0 }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Box component="h4" my={0}>
        {subtitle}
      </Box>
    </Box>
  );
};
Heading = React.memo(Heading);

const SectionTitle = styled('h4')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#f3f3f4',
  margin: theme.spacing(1, 0),
  fontWeight: 'bold',
}));
