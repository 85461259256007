import { useMutation } from '@apollo/client/index.js';
import { PauseProductDialog_Save } from '@asaprint/asap/components/dialogs/PauseProductDialog.graphql';
import TimeEntryForm from '@asaprint/asap/components/forms/TimeEntryForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  PauseProductDialog_SaveMutation,
  PauseProductDialog_SaveMutationVariables,
  ProductActions,
} from '@asaprint/asap/schema.client.types.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('components/modals/PauseProductDialog');

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  role: string;
  username: string;
}

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  user: User;
}

interface Product {
  id: string;
  updatedAt: string;
  activeTimeEntries?: TimeEntry[];
}

interface OwnProps {
  className?: string;
  open?: boolean;
  product: Product;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const PauseProductDialog: React.FunctionComponent<Props> = ({ className, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <PauseProductDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

PauseProductDialog.displayName = 'PauseProductDialog';

export default PauseProductDialog;

interface TimeEntryFormValues {
  id: string;
  updatedAt: string;
  startAt: Date;
  endAt: Date;
  note: string;
  position: string;
}

interface FormValues {
  myActiveTimeEntry: TimeEntryFormValues;
}

interface PauseProductDialogContentOwnProps {
  product: Product;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const PauseProductDialogContent: React.FunctionComponent<PauseProductDialogContentOwnProps> = ({
  product,
  refetchQueries,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [saveExecute] = useMutation<PauseProductDialog_SaveMutation, PauseProductDialog_SaveMutationVariables>(
    PauseProductDialog_Save,
    {
      refetchQueries,
    },
  );

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            id: product.id,
            updatedAt: product.updatedAt,
            action: ProductActions.Pause,
            myActiveTimeEntry: values.myActiveTimeEntry && {
              ...values.myActiveTimeEntry,
              position: values.myActiveTimeEntry.position ? parseInt(values.myActiveTimeEntry.position) : null,
              startAt: values.myActiveTimeEntry.startAt.toISOString(),
              endAt: values.myActiveTimeEntry.endAt.toISOString(),
            },
          },
        },
      });
      enqueueSnackbar('Úspešne ste pozastavili práce.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const myActiveTimeEntry = product.activeTimeEntries?.find((timeEntry) => timeEntry.user.id === requestUser.id);
  const initialValues = useMemo((): FormValues => {
    const now = new Date();
    const startAt = myActiveTimeEntry?.startAt ? new Date(myActiveTimeEntry.startAt) : new Date();
    const endAt = startAt > now ? startAt : now;
    return {
      myActiveTimeEntry: myActiveTimeEntry
        ? {
            id: myActiveTimeEntry.id,
            updatedAt: myActiveTimeEntry.updatedAt,
            startAt,
            endAt: myActiveTimeEntry.endAt ? new Date(myActiveTimeEntry.endAt) : endAt,
            note: myActiveTimeEntry.note ?? '',
            position: null,
          }
        : null,
    };
  }, [myActiveTimeEntry]);

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <DialogTitle>Pozastavenie</DialogTitle>
      <DialogContent>
        {myActiveTimeEntry && (
          <>
            <Box component="h3" mb={1} mt={0}>
              Strávený čas
            </Box>
            <Box mb={5}>
              <Box component="h5" mb={1} mt={1}>
                {displayName(myActiveTimeEntry.user)}
              </Box>
              <TimeEntryForm prefix={`myActiveTimeEntry.`} />
            </Box>
          </>
        )}
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

PauseProductDialogContent.displayName = 'PauseProductDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (values.myActiveTimeEntry) {
    const myActiveTimeEntryErrors: FormErrors<TimeEntryFormValues> = {};
    if (!values.myActiveTimeEntry.startAt) {
      myActiveTimeEntryErrors.startAt = 'Začiatok je povinný';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }

    if (!values.myActiveTimeEntry.endAt) {
      myActiveTimeEntryErrors.endAt = 'Koniec je povinný';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }

    if (values.myActiveTimeEntry.startAt > values.myActiveTimeEntry.endAt) {
      myActiveTimeEntryErrors.endAt = 'Koniec je pred začiatkom';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }
  }

  return errors;
}
