import { scrollbar } from '@engined/client/styles/mixins.js';
import { styled } from '@mui/material';

const ScrollContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  height: '100%',
  ...scrollbar,
}));

ScrollContainer.displayName = 'ScrollContainer';

export default ScrollContainer;
