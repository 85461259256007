import { useMutation } from '@apollo/client/index.js';
import { CancelProductDialog_Save } from '@asaprint/asap/components/dialogs/CancelProductDialog.graphql';
import {
  CancelProductDialog_SaveMutation,
  CancelProductDialog_SaveMutationVariables,
  ProductActions,
} from '@asaprint/asap/schema.client.types.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/ConfirmModal');

interface Product {
  id: string;
  updatedAt: string;
}

interface OwnProps {
  className?: string;
  open?: boolean;
  product: Product;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const CancelProductDialog: React.FunctionComponent<Props> = ({ className, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <CancelProductDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

CancelProductDialog.displayName = 'CancelProductDialog';

export default CancelProductDialog;

interface FormValues {
  id: string;
  updatedAt: string;
}

interface CancelProductDialogContentOwnProps {
  product: Product;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const CancelProductDialogContent: React.FunctionComponent<CancelProductDialogContentOwnProps> = ({
  product,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saveExecute] = useMutation<CancelProductDialog_SaveMutation, CancelProductDialog_SaveMutationVariables>(
    CancelProductDialog_Save,
  );

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: { input: { ...values, action: ProductActions.Cancel } },
      });
      enqueueSnackbar('Úspešne ste stornovali produkt.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        id: product.id,
        updatedAt: product.updatedAt,
      }}
      onSubmit={onSubmit}
    >
      <DialogTitle>Ukončenie produktu</DialogTitle>
      <DialogContent>
        <DialogContentText>Skutočne chcete stornovať produkt?</DialogContentText>
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Áno" closeLabel="Nie" />
    </Form>
  );
};

CancelProductDialogContent.displayName = 'CancelProductDialogContent';
