import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { DATETIME_FORMAT } from '@engined/client/locales.js';
import { Delete } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import React, { useMemo, useRef } from 'react';

interface OwnProps {
  id?: string;
  title: React.ReactNode;
  value: string;
  readonly?: boolean;

  onSave?(value: Date);
}

const PlanningRow: React.FunctionComponent<OwnProps> = ({ title, value, readonly = false, id, onSave }) => {
  if (!id && !readonly) {
    throw new Error('ID has to be specified for writeable planning row');
  }

  const { t } = useLocale();
  const dateValue = useMemo(() => value && new Date(value), [value]);
  const changeEventTimer = useRef<number>();

  const onChangeCallback = useEventCallback((d: Date, event) => {
    const process = () => {
      if ((d === null && value) || (d !== null && !isNaN(d.getTime()) && d.toISOString() !== value)) {
        onSave(d);
      }
    };

    if (event?.type === 'change') {
      window.clearTimeout(changeEventTimer.current);
      changeEventTimer.current = window.setTimeout(process, 300);
    } else {
      process();
    }
  });
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      height={{ xs: undefined, md: 40 }}
      mb={{ xs: 2, md: 0 }}
      sx={{
        '&::before': {
          content: '""',
          position: 'relative',
          height: '11px',
          width: '11px',
          borderRadius: '50%',
          backgroundColor: '#9a9d9f',
          display: { xs: 'none', md: 'inline-block' },
          zIndex: 1,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          borderLeft: '1px solid #e8e9ea',
          left: '5px',
          height: '40px',
          display: { xs: 'none', md: 'inline-block' },
        },
        '&:first-child::after': {
          height: '20px',
          bottom: 0,
        },
        '&:last-child::after': {
          height: '20px',
          top: 0,
        },
      }}
    >
      <Box
        flex={1}
        display="flex"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box fontWeight="bold" flex={1} ml={{ xs: 0, md: 2 }}>
          {title}
        </Box>
        <Box display="flex" alignItems="center" width={{ xs: '100%', md: '242px' }} gap={2}>
          <DateTimePicker
            format={t(DATETIME_FORMAT)}
            value={dateValue}
            onChange={onChangeCallback}
            ampm={false}
            readOnly={readonly}
            slotProps={{
              textField: {
                placeholder: '',
                fullWidth: true,
              },
              actionBar: {
                actions: ['clear', 'cancel', 'accept'],
              },
            }}
          />
          <IconButton
            color="error"
            sx={{
              visibility: !readonly && dateValue !== null ? 'visible' : 'hidden',
              display: { xs: !readonly && dateValue !== null ? 'block' : 'none', md: 'block' },
            }}
            onClick={() => {
              if (!readonly && dateValue !== null) {
                onSave(null);
              }
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

PlanningRow.displayName = 'PlanningRow';

export default React.memo<OwnProps>(PlanningRow);
