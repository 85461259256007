import ToggleButtonWithTooltip, {
  Props as ToggleButtonWithTooltipProps,
} from '@asaprint/asap/components/ToggleButtonWithTooltip.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import React from 'react';
import { Editor, MarkTypes } from 'slate';
import { useSlate } from 'slate-react';

export const toggleMark = (editor: Editor, format: MarkTypes) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: Editor, format: MarkTypes) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

interface OwnProps {
  type: MarkTypes;
  icon: React.ReactNode;
  tooltip: React.ReactNode;
}

type Props = OwnProps & Omit<ToggleButtonWithTooltipProps, 'type' | 'icon' | 'tooltip' | 'value'>;

const MarkButton: React.FunctionComponent<Props> = ({ type, icon, tooltip, ...rest }) => {
  const editor = useSlate();

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleMark(editor, type);
  });

  return (
    <ToggleButtonWithTooltip
      {...rest}
      value={type}
      selected={isMarkActive(editor, type)}
      onMouseDown={onClickCallback}
      tooltip={tooltip}
    >
      {icon}
    </ToggleButtonWithTooltip>
  );
};

MarkButton.displayName = 'MarkButton';

export default React.memo<Props>(MarkButton);
