import { useHTMLContext } from '@asaprint/asap/components/slate/context.js';
import ToggleButtonWithTooltip from '@asaprint/asap/components/ToggleButtonWithTooltip.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import CheckboxField from '@engined/client/components/forms/fields/CheckboxField.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import {
  CLOSE,
  FIELD_IS_REQUIRED,
  SLATE_WITH_LINKS_LINK_BUTTON_TOOLTIP,
  SLATE_WITH_LINKS_LINK_DIALOG_OPEN_NEW_WINDOW,
  SLATE_WITH_LINKS_LINK_DIALOG_SUBMIT_BUTTON,
  SLATE_WITH_LINKS_LINK_DIALOG_TEXT,
  SLATE_WITH_LINKS_LINK_DIALOG_TITLE,
  SLATE_WITH_LINKS_LINK_DIALOG_URL,
  SLATE_WITH_LINKS_UNLINK_BUTTON_TOOLTIP,
} from '@engined/client/locales.js';
import { getLogger } from '@engined/core/services/logger.js';
import isURL from '@engined/core/validators/isURL.js';
import { Link, LinkOff } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link as MUILink,
  ToggleButtonProps,
} from '@mui/material';
import React, { useState } from 'react';
import {
  Editor,
  Element,
  LinkElement,
  Location as SlateLocation,
  Node,
  NodeEntry,
  Range,
  Text,
  Transforms,
} from 'slate';
import { useSlate } from 'slate-react';

const logger = getLogger('@ui/components/slate/plugins/withLinks');

const withLinks = (editor: Editor): Editor => {
  const { insertData, insertText, isInline, normalizeNode } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isURL(text)) {
      wrapLink(editor, text, text, false);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isURL(text)) {
      wrapLink(editor, text, text, false);
    } else {
      insertData(data);
    }
  };

  editor.normalizeNode = ([node, path]) => {
    // Remove empty links
    if (Element.isElement(node) && node.type === 'link') {
      const text = Node.string(node);
      if (!text) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
};

const insertLink = (editor: Editor, url: string, text: string, openNewWindow: boolean) => {
  if (editor.selection) {
    wrapLink(editor, url, text, openNewWindow);
  }
};

const isLinkActive = (editor: Editor) => {
  return !!getActiveLink(editor);
};

const getActiveLink = (editor: Editor, selection?: SlateLocation): NodeEntry<LinkElement> => {
  const [link] = Editor.nodes<LinkElement>(editor, {
    at: selection,
    match: linkNodeMatch,
  });
  return link;
};

function linkNodeMatch(node: Node) {
  return Element.isElement(node) && node.type === 'link';
}

const unwrapLink = (editor: Editor) => {
  editor.withoutNormalizing(() => {
    const isCollapsed = !editor.selection || Range.isCollapsed(editor.selection);

    if (isCollapsed) {
      Transforms.unwrapNodes(editor, {
        match: linkNodeMatch,
      });
      return;
    }

    const fileAboveAnchor = getActiveLink(editor, editor.selection?.anchor);
    if (fileAboveAnchor) {
      Transforms.splitNodes(editor, {
        at: editor.selection?.anchor,
        match: linkNodeMatch,
      });
    }
    const fileAboveFocus = getActiveLink(editor, editor.selection?.focus);
    if (fileAboveFocus) {
      Transforms.splitNodes(editor, {
        at: editor.selection?.focus,
        match: linkNodeMatch,
      });
    }

    // Move offset so that we do not match next element
    if (Range.end(editor.selection).offset === 0) {
      Transforms.move(editor, { edge: 'end', distance: 1, unit: 'offset', reverse: true });
    }
    Transforms.unwrapNodes(editor, {
      match: linkNodeMatch,
    });
  });
};

const wrapLink = (editor: Editor, url: string, text: string, openNewWindow: boolean) => {
  const { selection } = editor;
  const isCollapsed = !selection || Range.isCollapsed(selection);
  if (isLinkActive(editor)) {
    if (isCollapsed) {
      const link = getActiveLink(editor);
      Transforms.setNodes(editor, { ...link, openNewWindow, url }, { at: link[1] });
      return;
    }

    unwrapLink(editor);
  }

  const [voidMatch] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && editor.isVoid(n),
  });
  const isVoid = voidMatch && Element.isElement(voidMatch[0]) && editor.isVoid(voidMatch[0]);

  const link: LinkElement = {
    type: 'link',
    openNewWindow,
    url,
    children: isCollapsed && !isVoid ? [{ text: text }] : [],
  };

  if (isVoid || !isCollapsed) {
    Transforms.wrapNodes(editor, link, { split: true, mode: 'highest' });
    Transforms.collapse(editor, { edge: 'end' });
  } else if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  }
};

export default withLinks;

interface EditorLinkOwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: LinkElement;
}

export const EditorLink: React.FunctionComponent<EditorLinkOwnProps> = ({ attributes, children, element }) => {
  // TODO: Add react-router link if element.url is local
  return (
    <MUILink {...attributes} href={element.url} target={element.openNewWindow ? '_blank' : undefined}>
      {children}
    </MUILink>
  );
};

EditorLink.displayName = 'EditorLink';

interface HTMLLinkOwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: LinkElement;
}

export const HTMLLink: React.FunctionComponent<HTMLLinkOwnProps> = ({ attributes, children, element }) => {
  const { baseUrl, wrapUrls } = useHTMLContext();

  if (
    wrapUrls &&
    element.children.some(
      // Wrap URLs or variables in links
      (ch) => Text.isText(ch) && (isURL(ch.text) || /\$\{\w+}/.test(ch.text)),
    )
  ) {
    children = (
      <Box component="span" sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
        {children}
      </Box>
    );
  }

  const href = element.url
    ? /^(https?|mailto|tel):/.test(element.url) || element.url.startsWith('${')
      ? element.url
      : `${baseUrl}${element.url}`
    : '';

  // TODO: Add react-router link if element.url is local
  return (
    <MUILink {...attributes} href={href} target={element.openNewWindow ? '_blank' : undefined}>
      {children}
    </MUILink>
  );
};

HTMLLink.displayName = 'HTMLLink';

type LinkButtonOwnProps = Omit<ToggleButtonProps, 'type' | 'icon' | 'tooltip' | 'value'>;

export const LinkButton: React.FunctionComponent<LinkButtonOwnProps> = ({ ...rest }) => {
  const { t } = useLocale();
  const editor = useSlate();
  const { open, onClose, onOpen } = useDialog(false);
  const [selection, setSelection] = useState<Range>(null);

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onOpen();
    setSelection(editor.selection);
  });

  const onCancelCallback = useEventCallback(() => {
    onClose();
    if (selection?.focus || selection?.anchor) {
      Transforms.select(editor, selection);
    }
    setSelection(null);
  });

  const onSubmitCallback = useEventCallback(() => {
    onClose();
    setSelection(null);
  });

  return (
    <>
      <ToggleButtonWithTooltip
        tooltip={t(SLATE_WITH_LINKS_LINK_BUTTON_TOOLTIP)}
        {...rest}
        value="link"
        selected={isLinkActive(editor)}
        onMouseDown={onClickCallback}
      >
        <Link />
      </ToggleButtonWithTooltip>
      <Dialog open={open} onClose={onCancelCallback} fullWidth maxWidth="sm" keepMounted={false}>
        <LinkDialogContent onCancel={onCancelCallback} selection={selection} onSubmit={onSubmitCallback} />
      </Dialog>
    </>
  );
};

LinkButton.displayName = 'LinkButton';

type UnlinkButtonOwnProps = Omit<ToggleButtonProps, 'type' | 'icon' | 'tooltip' | 'value'>;

export const UnlinkButton: React.FunctionComponent<UnlinkButtonOwnProps> = ({ ...rest }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
  });

  return (
    <>
      <ToggleButtonWithTooltip
        tooltip={t(SLATE_WITH_LINKS_UNLINK_BUTTON_TOOLTIP)}
        {...rest}
        value="unlink"
        onMouseDown={onClickCallback}
      >
        <LinkOff />
      </ToggleButtonWithTooltip>
    </>
  );
};

UnlinkButton.displayName = 'UnlinkButton';

interface FormValues {
  url: string;
  text: string;
  openNewWindow: boolean;
}

interface LinkDialogContentProps {
  selection: Range | null;
  onSubmit();
  onCancel();
}

let LinkDialogContent: React.FunctionComponent<LinkDialogContentProps> = ({ selection, onCancel, onSubmit }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const onSubmitCallback: OnSubmit<FormValues> = (values: FormValues) => {
    try {
      if (selection?.focus || selection?.anchor) {
        Transforms.select(editor, selection);
      }
      insertLink(editor, values.url, values.text, values.openNewWindow);
      if (onSubmit) {
        onSubmit();
      }
    } catch (err) {
      logger.error(err);
    }
  };

  const text = selection ? Editor.string(editor, selection) : '';
  const linkActive = selection ? getActiveLink(editor, selection) : null;
  const collapsed = linkActive ? false : selection ? Range.isCollapsed(selection) : true;

  return (
    <Form
      defaultValues={{
        url: linkActive?.[0]?.url ?? '',
        text: text ?? '',
        openNewWindow: linkActive?.[0]?.openNewWindow ?? false,
      }}
      onSubmit={onSubmitCallback}
      validate={validate}
      context={{ collapsed }}
      stopSubmitPropagation
    >
      <DialogTitle>{t(SLATE_WITH_LINKS_LINK_DIALOG_TITLE)}</DialogTitle>
      <DialogContent>
        <TextField
          name="url"
          type="url"
          fullWidth
          label={t(SLATE_WITH_LINKS_LINK_DIALOG_URL)}
          autoFocus
          variant="standard"
        />
        {collapsed && (
          <TextField name="text" fullWidth label={t(SLATE_WITH_LINKS_LINK_DIALOG_TEXT)} variant="standard" />
        )}
        <FormControlLabel
          control={<CheckboxField name="openNewWindow" color="primary" />}
          label={t(SLATE_WITH_LINKS_LINK_DIALOG_OPEN_NEW_WINDOW)}
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons
        closeOnClick={onCancel}
        closeLabel={t(CLOSE)}
        submitLabel={t(SLATE_WITH_LINKS_LINK_DIALOG_SUBMIT_BUTTON)}
      />
    </Form>
  );
};

LinkDialogContent.displayName = 'LinkDialogContent';
LinkDialogContent = React.memo(LinkDialogContent);

function validate(
  values: FormValues,
  t: LocaleContextValue['t'],
  { collapsed }: { collapsed: boolean },
): FormErrors<FormValues> {
  const errors: FormErrors<FormValues> = {};
  if (collapsed && !values.text) {
    errors.text = t(FIELD_IS_REQUIRED);
  }

  if (!values.url) {
    errors.url = t(FIELD_IS_REQUIRED);
  }

  return errors;
}
