import { useMutation, useSubscription } from '@apollo/client/index.js';
import RichTextField from '@asaprint/asap/components/forms/fields/RichTextField.js';
import ProductComment from '@asaprint/asap/components/ProductComment.js';
import {
  ProductComments_Created,
  ProductComments_Load,
  ProductComments_Save,
  ProductComments_Updated,
} from '@asaprint/asap/components/ProductComments.graphql';
import { isEmpty } from '@asaprint/asap/components/slate/helpers.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  ProductComments_CreatedSubscription,
  ProductComments_CreatedSubscriptionVariables,
  ProductComments_LoadQuery,
  ProductComments_LoadQueryVariables,
  ProductComments_SaveMutation,
  ProductComments_SaveMutationVariables,
  ProductComments_UpdatedSubscription,
  ProductComments_UpdatedSubscriptionVariables,
} from '@asaprint/asap/schema.client.types.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors, FormErrorsOverride } from '@engined/client/hooks/useFormResolver.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Descendant } from 'slate';

const logger = getLogger('components/ProductComments');

interface FormValues {
  body: Descendant[];
}

const initialComment: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const initialValues: FormValues = {
  body: initialComment,
};

interface OwnProps {
  className?: string;
  productId: string;
}

type Props = OwnProps;

const ProductComments: React.FunctionComponent<Props> = ({ className, productId }) => {
  const { requestUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [formKey, setFormKey] = useState<number>(Date.now());
  const { loading, data, error, subscribeToMore } = useQuery<
    ProductComments_LoadQuery,
    ProductComments_LoadQueryVariables
  >(ProductComments_Load, {
    variables: { productId },
  });

  const [saveComment] = useMutation<ProductComments_SaveMutation, ProductComments_SaveMutationVariables>(
    ProductComments_Save,
  );

  useSubscription<ProductComments_UpdatedSubscription, ProductComments_UpdatedSubscriptionVariables>(
    ProductComments_Updated,
    {
      variables: { product: productId },
    },
  );

  useEffect(() => {
    return subscribeToMore<ProductComments_CreatedSubscription, ProductComments_CreatedSubscriptionVariables>({
      document: ProductComments_Created,
      variables: { product: productId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data) {
          return prev;
        }

        return {
          ...prev,
          productComments: {
            ...prev.productComments,
            edges: [
              ...prev.productComments.edges,
              {
                __typename: 'ProductCommentEdge',
                node: subscriptionData.data.productCommentCreated,
              },
            ],
          },
        };
      },
    });
  }, [productId, subscribeToMore]);

  const onCreateSubmit: OnSubmit<FormValues> = async (values, form) => {
    try {
      const response = await saveComment({
        variables: {
          input: {
            ...values,
            product: productId,
          },
        },
      });
      form.reset(initialValues);
      setFormKey(Date.now());
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const productComments = data?.productComments.edges.map((edge) => edge.node);
  const mentions = useMemo(
    () =>
      data?.users.edges.map(({ node }) => ({
        label: displayName(node),
        user: node,
      })),
    [data?.users.edges],
  );

  return (
    <Box borderTop={1} borderColor="#e7eaec" px={2} py={4} bgcolor="#f9f9f9">
      {error || (loading && !data) ? (
        <Loading error={error} />
      ) : (
        <>
          {productComments.map((productComment) => (
            <ProductComment key={productComment.id} comment={productComment} />
          ))}
          <Box mt={2} display="flex">
            <Box flex="0 0 32px" mr={2} mt={2}>
              <img alt={displayName(requestUser)} src={`${requestUser.avatar}&size=64`} width={32} height={32} />
            </Box>
            <Box flex={1}>
              <Form key={formKey} defaultValues={initialValues} onSubmit={onCreateSubmit} validate={validate}>
                <RichTextField
                  id={`product-${productId}-comment-body`}
                  name="body"
                  inputProps={{
                    placeholder: 'Napíš komentár...',
                    allowImages: true,
                    allowFiles: true,
                    mentions: mentions,
                  }}
                  sx={{ bgcolor: '#fff' }}
                />
                <ConnectedSubmitButtons noBack />
              </Form>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

ProductComments.displayName = 'ProductComments';

export default React.memo<Props>(ProductComments);

function validate(values: FormValues) {
  const errors: FormErrors<FormErrorsOverride<FormValues, 'body'>> = {};
  if (!values.body || isEmpty(values.body)) {
    errors.body = 'Nezadali ste komentár';
  }

  return errors;
}
