// Append only
export enum OrderReceivedLogType {
  SYNCHRONIZED,
  PRODUCT_CHANGED,
  CHANGED,
  CHECKED_1,
  CHECKED_2,
  CARD_PAYMENT,
  CASH_PAYMENT,
  FREE_PAYMENT,
  NEED_INVOICE,
  INVOICED,
  INVOICED_AND_ESHOP_UPLOAD,
  PACKAGED,
  COURIER_PICKUP,
  PLANNED,
  ASSEMBLED,
  SKIP_WAITING,
  CREATE_PRODUCT,
  CASH_PAYMENT_WITHOUT,
  DELIVERED,
  WAITING_FOR_INVOICE_ENDED,
  CLOSED,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_ERROR,
  SMS_SENT_SUCCESS,
  SMS_SENT_ERROR,
}

export const ORDER_RECEIVED_LOG_TYPE_NAMES = {
  [OrderReceivedLogType.SYNCHRONIZED]: 'synchronizácia',
  [OrderReceivedLogType.PRODUCT_CHANGED]: 'zmena v produkte',
  [OrderReceivedLogType.CHANGED]: 'zmena',
  [OrderReceivedLogType.CHECKED_1]: 'skontrolované',
  [OrderReceivedLogType.CHECKED_2]: 'skontrolované',
  [OrderReceivedLogType.CARD_PAYMENT]: 'uhradené kartou',
  [OrderReceivedLogType.CASH_PAYMENT]: 'uhradené v hotovosti',
  [OrderReceivedLogType.FREE_PAYMENT]: 'uzavreté bez platby',
  [OrderReceivedLogType.NEED_INVOICE]: 'fakturovať',
  [OrderReceivedLogType.INVOICED]: 'vyfakturované',
  [OrderReceivedLogType.INVOICED_AND_ESHOP_UPLOAD]: 'vyfakturované a nahrané do eshopu',
  [OrderReceivedLogType.PACKAGED]: 'zabalené',
  [OrderReceivedLogType.COURIER_PICKUP]: 'odovzdané kuriérovi',
  [OrderReceivedLogType.PLANNED]: 'skontrolované',
  [OrderReceivedLogType.ASSEMBLED]: 'namontované',
  [OrderReceivedLogType.SKIP_WAITING]: 'zákazník chce faktúru skôr',
  [OrderReceivedLogType.CREATE_PRODUCT]: 'vytvorený produkt',
  [OrderReceivedLogType.CASH_PAYMENT_WITHOUT]: 'uhradené v hotovosti (bez)',
  [OrderReceivedLogType.DELIVERED]: 'odovzdané odberateľovi s dodacím listom',
  [OrderReceivedLogType.WAITING_FOR_INVOICE_ENDED]: 'skončila ochranná doba',
  [OrderReceivedLogType.CLOSED]: 'uzavretá',
  [OrderReceivedLogType.EMAIL_SENT_SUCCESS]: 'odoslaný email',
  [OrderReceivedLogType.EMAIL_SENT_ERROR]: 'nepodarilo sa odoslať email',
  [OrderReceivedLogType.SMS_SENT_SUCCESS]: 'odoslaná SMS',
  [OrderReceivedLogType.SMS_SENT_ERROR]: 'nepodarilo sa odoslať SMS',
};
