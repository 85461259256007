import { CreateProductDialogButton } from '@asaprint/asap/components/dialogs/CreateProductDialog.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import StrippedTableRow from '@asaprint/asap/components/StrippedTableRow.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';

interface Item {
  id: string;
  name: string;
  count: number;
  unit: string;
}

interface OwnProps {
  orderReceivedId: string;
  items: Item[];
  creatingOnePerItem: boolean;
  onCreateOnePerItem();
}

type Props = OwnProps;

const OrderItemsWithoutProduct: React.FunctionComponent<Props> = ({
  items,
  orderReceivedId,
  creatingOnePerItem,
  onCreateOnePerItem,
}) => {
  const [selected, setSelected] = useState<string[]>([]);
  const selectResetKey = items.map((i) => i.id).join(',');
  const [prevItems, setPrevItems] = useState<string>(selectResetKey);

  // Reset selected when items has changed
  if (prevItems !== selectResetKey) {
    setSelected([]);
    setPrevItems(selectResetKey);
  }

  const onCreateOnePerItemCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    onCreateOnePerItem();
  });

  return (
    <Box>
      <Box
        display="flex"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
      >
        <div>
          <Box component="h2" mb={0}>
            Nezaradené položky
          </Box>
          <Box mb={2} fontStyle="italic">
            Tieto položky sa nachádzajú v Money S4, ale neboli priradené do žiadného produktu v ASAPe.
          </Box>
        </div>
        <Box display="flex" alignItems="center" gap={2}>
          <div>Vytvoriť</div>
          <Box display="flex" gap={1} ml={2} flexDirection={{ xs: 'column', md: 'row' }}>
            <CreateProductDialogButton
              orderReceivedId={orderReceivedId}
              refetchQueries={['OrderReceived_Load']}
              color="primary"
              variant="contained"
            >
              Prázdny produkt
            </CreateProductDialogButton>{' '}
            <LoadingButton
              color="primary"
              onClick={onCreateOnePerItemCallback}
              loading={creatingOnePerItem}
              variant="contained"
            >
              Produkt z každej položky
            </LoadingButton>{' '}
            <CreateProductDialogButton
              orderReceivedId={orderReceivedId}
              refetchQueries={['OrderReceived_Load']}
              color="primary"
              variant="contained"
              disabled={selected.length === 0 || creatingOnePerItem}
              ids={selected}
              name={selected.map((s) => items.find((i) => i.id === s))?.[0]?.name}
            >
              Produkt z označených
            </CreateProductDialogButton>{' '}
            <CreateProductDialogButton
              orderReceivedId={orderReceivedId}
              refetchQueries={['OrderReceived_Load']}
              color="primary"
              variant="contained"
              disabled={!items.length || creatingOnePerItem}
              ids={items.map((i) => i.id)}
              name={items?.[0]?.name}
            >
              1 produkt zo všetkých
            </CreateProductDialogButton>
          </Box>
        </Box>
      </Box>

      <PagePaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Položka</TableCell>
                <TableCell>Počet</TableCell>
                <TableCell>Jednotka</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <StrippedTableRow key={item.id}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={(event, checked) => {
                        event.stopPropagation();
                        setSelected((s) => (checked ? [...s, item.id] : s.filter((i) => i !== item.id)));
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{item.unit}</TableCell>
                </StrippedTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PagePaper>
    </Box>
  );
};

OrderItemsWithoutProduct.displayName = 'OrderItemsWithoutProduct';

export default OrderItemsWithoutProduct;
