import { styled, toggleButtonGroupClasses } from '@mui/material';

const WidgetButtons = styled('div')(({ theme }) => ({
  position: 'absolute',
  padding: theme.spacing(1),
  top: -50,
  left: 0,
  zIndex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  opacity: 0.2,
  [`& .${toggleButtonGroupClasses['root']}`]: {
    margin: theme.spacing(0, 0, 1, 1),
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    opacity: 1,
  },
}));

WidgetButtons.displayName = 'WidgetButtons';

export default WidgetButtons;
