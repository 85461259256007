import { Link } from '@mui/material';
import React from 'react';

interface OwnProps {
  person: {
    telephone1Number?: string;
    telephone2Number?: string;
    telephone3Number?: string;
    telephone4Number?: string;
    email?: string;
  };
}

type Props = OwnProps;

const PersonContactInfo: React.FunctionComponent<Props> = ({ person }) => (
  <span>
    {person?.telephone1Number && (
      <>
        <br />
        <Link href={`tel:${person?.telephone1Number.replace(/ /g, '')}`}>{person?.telephone1Number}</Link>
      </>
    )}
    {person?.telephone2Number && (
      <>
        <br />
        <Link href={`tel:${person?.telephone2Number.replace(/ /g, '')}`}>{person?.telephone2Number}</Link>
      </>
    )}
    {person?.telephone3Number && (
      <>
        <br />
        <Link href={`tel:${person?.telephone3Number.replace(/ /g, '')}`}>{person?.telephone3Number}</Link>
      </>
    )}
    {person?.telephone4Number && (
      <>
        <br />
        <Link href={`tel:${person?.telephone4Number.replace(/ /g, '')}`}>{person?.telephone4Number}</Link>
      </>
    )}
    {person?.email && (
      <>
        <br />
        <Link href={`mailto:${person?.email}`}>{person?.email}</Link>
      </>
    )}
  </span>
);

PersonContactInfo.displayName = 'PersonContactInfo';

export default React.memo<Props>(PersonContactInfo);
