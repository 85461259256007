import { useMutation } from '@apollo/client/index.js';
import { FinishProductPhaseDialog_Save } from '@asaprint/asap/components/dialogs/FinishProductPhaseDialog.graphql';
import PhaseField from '@asaprint/asap/components/forms/fields/PhaseField.js';
import UserField from '@asaprint/asap/components/forms/fields/UserField.js';
import TimeEntryForm from '@asaprint/asap/components/forms/TimeEntryForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  FinishProductPhaseDialog_SaveMutation,
  FinishProductPhaseDialog_SaveMutationVariables,
  ProductActions,
} from '@asaprint/asap/schema.client.types.js';
import { Phases } from '@asaprint/common/constants/Phase.js';
import { EstimatedTimeType } from '@asaprint/common/constants/Product.js';
import { toOption as phaseToOption } from '@asaprint/common/helpers/Phase.js';
import { displayName, toOption as userToOption } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Nl2Br from '@engined/client/components/Nl2Br.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors, FormErrorsOverride } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Alert, Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Descendant } from 'slate';

const logger = getLogger('components/modals/FinishProductPhaseDialog');

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  role: string;
  username: string;
}

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  user: User;
}

interface Phase {
  id: string;
  name: string;
  color: string;
}

interface Task {
  user: User;
}

interface Product {
  id: string;
  tasks?: Task[];
  phase?: Phase;
  updatedAt: string;
  graphicAssignment?: Descendant[];
  estimatedTimeType?: EstimatedTimeType;
  estimatedTime?: number;
  productionNote?: string;
  activeTimeEntries?: TimeEntry[];
}

interface OwnProps {
  className?: string;
  open?: boolean;
  showDatesWarning: boolean;
  product: Product;
  productionNote?: string;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const FinishProductPhaseDialog: React.FunctionComponent<Props> = ({ className, open, onClose, ...rest }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <FinishProductPhaseDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

FinishProductPhaseDialog.displayName = 'FinishProductPhaseDialog';

export default FinishProductPhaseDialog;

interface TimeEntryFormValues {
  id: string;
  updatedAt: string;
  startAt: Date;
  endAt: Date;
  note: string;
  position: string;
}

interface FormValues {
  responsibleUsers: Option[];
  phase: Option;
  myActiveTimeEntry: TimeEntryFormValues;
}

interface FinishProductPhaseDialogContentOwnProps {
  product: Product;
  showDatesWarning: boolean;
  productionNote?: string;
  refetchQueries?: string[];
  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const FinishProductPhaseDialogContent: React.FunctionComponent<FinishProductPhaseDialogContentOwnProps> = ({
  product,
  onClose,
  showDatesWarning,
  productionNote,
  refetchQueries,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [saveExecute] = useMutation<
    FinishProductPhaseDialog_SaveMutation,
    FinishProductPhaseDialog_SaveMutationVariables
  >(FinishProductPhaseDialog_Save, {
    refetchQueries,
  });

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            id: product.id,
            updatedAt: product.updatedAt,
            phaseId: values.phase?.value ?? null,
            responsibleUsers: values.responsibleUsers?.map((o) => o.value),
            action: ProductActions.FinishPhase,
            myActiveTimeEntry: values.myActiveTimeEntry && {
              ...values.myActiveTimeEntry,
              position: values.myActiveTimeEntry.position ? parseInt(values.myActiveTimeEntry.position) : null,
              startAt: values.myActiveTimeEntry.startAt.toISOString(),
              endAt: values.myActiveTimeEntry.endAt.toISOString(),
            },
          },
        },
      });
      enqueueSnackbar('Úspešne ste ukončili fázu produkt.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const myActiveTimeEntry = product.activeTimeEntries?.find((timeEntry) => timeEntry.user.id === requestUser.id);
  const initialValues = useMemo((): FormValues => {
    const now = new Date();
    const startAt = myActiveTimeEntry?.startAt ? new Date(myActiveTimeEntry.startAt) : new Date();
    const endAt = startAt > now ? startAt : now;
    return {
      phase: product.phase ? phaseToOption(product.phase) : null,
      responsibleUsers: product.tasks.map((t) => userToOption(t.user)),
      myActiveTimeEntry: myActiveTimeEntry
        ? {
            id: myActiveTimeEntry.id,
            updatedAt: myActiveTimeEntry.updatedAt,
            startAt,
            endAt: myActiveTimeEntry.endAt ? new Date(myActiveTimeEntry.endAt) : endAt,
            note: myActiveTimeEntry.note ?? '',
            position: null,
          }
        : null,
    };
  }, [myActiveTimeEntry, product]);

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <DialogTitle>Ukončenie fázy</DialogTitle>
      <DialogContent>
        <Alert severity="warning" sx={{ mx: -6, borderRadius: 0 }}>
          Chystáte sa dokončiť fázu produktu v objednávke, ktorá nemá nastavená všetky dátumy v plánovaní.
        </Alert>

        <CheckAlert product={product} showDatesWarning={showDatesWarning} />

        {productionNote && (
          <Box mb={4}>
            <Box component="h3" mb={1}>
              Poznámka pre výrobu z objednávky
            </Box>
            <div>
              <Nl2Br text={productionNote} />
            </div>
          </Box>
        )}

        {product.productionNote && (
          <Box mb={4}>
            <Box component="h3" mb={1}>
              Poznámka pre výrobu z produktu
            </Box>
            <div>
              <Nl2Br text={product.productionNote} />
            </div>
          </Box>
        )}

        {myActiveTimeEntry && (
          <>
            <Box component="h3" mb={1} mt={0}>
              Strávený čas
            </Box>
            <Box mb={5}>
              <Box component="h5" mb={1} mt={1}>
                {displayName(myActiveTimeEntry.user)}
              </Box>
              <TimeEntryForm prefix={`myActiveTimeEntry.`} />
            </Box>
          </>
        )}

        <Box component="h3" mb={1}>
          Kto má na produkte pracovať?
        </Box>
        <PhaseField name="phase" inputLabel="Ďalšia fáza" />
        <UserField name="responsibleUsers" inputLabel="Priradená osoba" multiple active />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

FinishProductPhaseDialogContent.displayName = 'FinishProductPhaseDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormErrorsOverride<FormValues, 'responsibleUsers'>> = {};

  if (!values.responsibleUsers || !values.responsibleUsers.length) {
    errors.responsibleUsers = 'Vyberte aspoň jednu zodpovednú osobu';
  }

  if (!values.phase) {
    errors.phase = 'Vyberte ďalšiu fázu';
  }

  if (values.myActiveTimeEntry) {
    const myActiveTimeEntryErrors: FormErrors<TimeEntryFormValues> = {};
    if (!values.myActiveTimeEntry.startAt) {
      myActiveTimeEntryErrors.startAt = 'Začiatok je povinný';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }

    if (!values.myActiveTimeEntry.endAt) {
      myActiveTimeEntryErrors.endAt = 'Koniec je povinný';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }

    if (values.myActiveTimeEntry.startAt > values.myActiveTimeEntry.endAt) {
      myActiveTimeEntryErrors.endAt = 'Koniec je pred začiatkom';
      errors.myActiveTimeEntry = myActiveTimeEntryErrors;
    }
  }

  return errors;
}

const CheckAlert: React.FC<{ product: Product; showDatesWarning: boolean }> = ({ product, showDatesWarning }) => {
  const phase = useWatch<FormValues>({ name: 'phase' });

  if (product.phase.id !== Phases.INSPECTION) {
    return null;
  }

  if (phase?.value !== Phases.GRAPHIC_DESIGN) {
    return null;
  }

  if (product.graphicAssignment && (product.estimatedTime || product.estimatedTimeType === EstimatedTimeType.NOT_SET)) {
    return null;
  }

  return (
    <Alert severity="warning" sx={{ mx: -6, mt: showDatesWarning ? 2 : 0, borderRadius: 0 }}>
      Skontrolujte, či ste:
      <ul>
        {!product.graphicAssignment && <li>napísali zadanie pre grafika</li>}
        {!product.estimatedTime && product.estimatedTimeType !== EstimatedTimeType.NOT_SET && <li>nacenili čas</li>}
      </ul>
    </Alert>
  );
};
