import { DataProxy, isApolloError, useMutation, useSubscription } from '@apollo/client/index.js';
import AboutCustomer from '@asaprint/asap/components/AboutCustomer.js';
import AboutDelivery from '@asaprint/asap/components/AboutDelivery.js';
import CopyText from '@asaprint/asap/components/CopyText.js';
import { CreateProductDialogButton } from '@asaprint/asap/components/dialogs/CreateProductDialog.js';
import OrderReceivedDeliveryDialog from '@asaprint/asap/components/dialogs/OrderReceivedDeliveryDialog.js';
import OrderReceivedExpeditionDialog from '@asaprint/asap/components/dialogs/OrderReceivedExpeditionDialog.js';
import OrderReceivedPackagingDialog from '@asaprint/asap/components/dialogs/OrderReceivedPackagingDialog.js';
import ReorderProductsDialog from '@asaprint/asap/components/dialogs/ReorderProductsDialog.js';
import { AssignedUserOption } from '@asaprint/asap/components/forms/fields/AssignedUsersField.js';
import HasPermission from '@asaprint/asap/components/HasPermission.js';
import OrderItemsWithoutProduct from '@asaprint/asap/components/OrderItemsWithoutProduct.js';
import OrderReceivedActions from '@asaprint/asap/components/OrderReceivedActions.js';
import OrderReceivedHistory from '@asaprint/asap/components/OrderReceivedHistory.js';
import OrderReceivedPhaseNote from '@asaprint/asap/components/OrderReceivedPhaseNote.js';
import OrderReceivedPhasesNotes from '@asaprint/asap/components/OrderReceivedPhasesNotes.js';
import OrderReceivedProduct from '@asaprint/asap/components/OrderReceivedProduct.js';
import OrderReceivedTimeSpent from '@asaprint/asap/components/OrderReceivedTimeSpent.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import PlanningRow from '@asaprint/asap/components/PlanningRow.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import {
  ORDER_RECEIVED_ABOUT_CUSTOMER,
  ORDER_RECEIVED_PLANNING,
  ORDER_RECEIVED_PLANNING_RECEIVED,
  ORDER_RECEIVED_PRODUCTS,
  ORDER_RECEIVED_TITLE,
} from '@asaprint/asap/locales/client.js';
import { DASHBOARD_ROUTE, ORDERS_RECEIVED_ROUTE, TIME_ENTRIES_ORDER_RECEIVED_ROUTE } from '@asaprint/asap/routes.js';
import {
  OrderReceived_Close,
  OrderReceived_CreateProductsFromItems,
  OrderReceived_Load,
  OrderReceived_ProductCreated,
  OrderReceived_ProductUpdate,
  OrderReceived_ProductUpdated,
  OrderReceived_Update,
  OrderReceived_Updated,
} from '@asaprint/asap/routes/__authenticated/orders/received/$id.graphql';
import {
  OrderReceived_CloseMutation,
  OrderReceived_CloseMutationVariables,
  OrderReceived_CreateProductsFromItemsMutation,
  OrderReceived_CreateProductsFromItemsMutationVariables,
  OrderReceived_LoadQuery,
  OrderReceived_LoadQueryVariables,
  OrderReceived_ProductCreatedSubscription,
  OrderReceived_ProductCreatedSubscriptionVariables,
  OrderReceived_ProductUpdatedSubscription,
  OrderReceived_ProductUpdatedSubscriptionVariables,
  OrderReceived_ProductUpdateMutation,
  OrderReceived_ProductUpdateMutationVariables,
  OrderReceived_UpdatedSubscription,
  OrderReceived_UpdatedSubscriptionVariables,
  OrderReceived_UpdateMutation,
  OrderReceived_UpdateMutationVariables,
  OrderReceived_UserFragment,
  OrderReceivedInput,
} from '@asaprint/asap/schema.client.types.js';
import { Permission, Role } from '@asaprint/common/access.js';
import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';
import {
  ORDER_RECEIVED_PHASE_NAMES,
  ORDER_RECEIVED_STATUS_NAMES,
  OrderReceivedPhase,
  phaseSx,
  statusSx,
} from '@asaprint/common/constants/OrderReceived.js';
import { PaymentTypes } from '@asaprint/common/constants/PaymentType.js';
import { ProductStatus } from '@asaprint/common/constants/Product.js';
import { isCourierDelivery } from '@asaprint/common/helpers/DeliveryType.js';
import { isValidCombination } from '@asaprint/common/helpers/OrderReceived.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import Interpolate from '@engined/client/components/Interpolate.js';
import Loading from '@engined/client/components/Loading.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError, getNotFoundErrors } from '@engined/client/helpers/errors.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { List as ListIcon } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  SxProps,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const logger = getLogger('components/pages/orders/received/OrderReceived');

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  duration?: number;
  position?: number;
  user: User;
  phase?: Phase;
}

interface Phase {
  id: string;
  name: string;
  color: string;
  position: number;
}

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  role: string;
  username: string;
}

interface OwnProps {}

type Props = OwnProps;

const emptyArray = [];

const orderReceivedProductSx = { mb: 2 };
const paperSx: SxProps = {
  display: { sm: 'block', md: 'flex' },
  flexDirection: 'column',
  height: '100%',
};

function updateCachedProducts(
  cache: DataProxy,
  id: string,
  data: OrderReceived_CreateProductsFromItemsMutation['orderReceivedCreateProductsFromItems'],
) {
  const cachedData = cache.readQuery<OrderReceived_LoadQuery, OrderReceived_LoadQueryVariables>({
    query: OrderReceived_Load,
    variables: { id },
  });
  const alreadyInCache = cachedData.orderReceived.products.map((p) => p.id);
  data = data.filter((p) => !alreadyInCache.includes(p.id));

  cache.writeQuery({
    query: OrderReceived_Load,
    variables: { id },
    data: {
      ...cachedData,
      orderReceived: {
        ...cachedData.orderReceived,
        products: cachedData.orderReceived.products.concat(data),
      },
    },
  });
}

type DateFieldsOfOrderReceived = Exclude<
  keyof {
    [K in keyof OrderReceivedInput as K extends `${string}At`
      ? K extends `money${string}At`
        ? never
        : K
      : never]: OrderReceivedInput[K];
  },
  'updatedAt'
>;

const Page: React.FunctionComponent<Props> = () => {
  const location = useLocation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocale();

  const { loading, data, error, subscribeToMore } = useQuery<OrderReceived_LoadQuery, OrderReceived_LoadQueryVariables>(
    OrderReceived_Load,
    {
      variables: { id: id },
    },
  );
  const [createProductsFromItems, { loading: createProductsFromItemLoading }] = useMutation<
    OrderReceived_CreateProductsFromItemsMutation,
    OrderReceived_CreateProductsFromItemsMutationVariables
  >(OrderReceived_CreateProductsFromItems, {
    variables: { orderReceivedId: id },
    update(cache, { data: { orderReceivedCreateProductsFromItems } }) {
      updateCachedProducts(cache, id, orderReceivedCreateProductsFromItems);
    },
  });

  const [orderReceivedUpdate] = useMutation<OrderReceived_UpdateMutation, OrderReceived_UpdateMutationVariables>(
    OrderReceived_Update,
    { refetchQueries: ['OrderReceivedHistory_Load'] },
  );
  const [orderReceivedProductUpdate] = useMutation<
    OrderReceived_ProductUpdateMutation,
    OrderReceived_ProductUpdateMutationVariables
  >(OrderReceived_ProductUpdate, {
    refetchQueries: ['OrderReceived_Load'],
  });

  // Realtime updates
  useSubscription<OrderReceived_UpdatedSubscription, OrderReceived_UpdatedSubscriptionVariables>(
    OrderReceived_Updated,
    {
      variables: { id },
    },
  );
  useSubscription<OrderReceived_ProductUpdatedSubscription, OrderReceived_ProductUpdatedSubscriptionVariables>(
    OrderReceived_ProductUpdated,
    {
      variables: { orderReceived: id },
    },
  );

  // Listen to product created
  useEffect(() => {
    return subscribeToMore<OrderReceived_ProductCreatedSubscription, OrderReceived_ProductCreatedSubscriptionVariables>(
      {
        document: OrderReceived_ProductCreated,
        variables: { orderReceived: id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data) {
            return prev;
          }

          const alreadyInCache = prev.orderReceived.products.map((p) => p.id);
          if (alreadyInCache.includes(subscriptionData.data.productCreated.id)) {
            return prev;
          }

          return {
            ...prev,
            orderReceived: {
              ...prev.orderReceived,
              products: prev.orderReceived.products.concat(subscriptionData.data.productCreated),
            },
          };
        },
      },
    );
  }, [subscribeToMore, id]);

  const orderReceived = data?.orderReceived;

  const onOrderReceivedDateChange = async (field: DateFieldsOfOrderReceived, date: Date) => {
    try {
      const response = await orderReceivedUpdate({
        variables: {
          input: {
            id: orderReceived.id,
            updatedAt: orderReceived.updatedAt,
            [field]: date && date.toISOString(),
          },
        },
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const onApprovedAtSave = useEventCallback(async (date: Date) => {
    await onOrderReceivedDateChange('approvedAt', date);
  });

  const onExpeditionAtSave = useEventCallback(async (date: Date) => {
    await onOrderReceivedDateChange('expeditionAt', date);
  });

  const onInspectionAtSave = useEventCallback(async (date: Date) => {
    await onOrderReceivedDateChange('inspectionAt', date);
  });

  const onAssemblyAtSave = useEventCallback(async (date: Date) => {
    await onOrderReceivedDateChange('assemblyAt', date);
  });

  const onProductUpdate = useEventCallback(
    async (input: OrderReceived_ProductUpdateMutationVariables['input'], notify: boolean = true) => {
      try {
        const response = await orderReceivedProductUpdate({
          variables: {
            input,
          },
        });
        if (notify) {
          enqueueSnackbar('Zmeny v produkte boli úspešne uložené', { variant: 'success' });
        }
      } catch (err) {
        displayError(err, enqueueSnackbar, logger);
      }
    },
  );

  const onOrderReceivedUpdate = useEventCallback(
    async (orderReceived: OrderReceived_UpdateMutationVariables['input']) => {
      try {
        const response = await orderReceivedUpdate({
          variables: {
            input: orderReceived,
          },
        });
      } catch (err) {
        displayError(err, enqueueSnackbar, logger);
      }
    },
  );

  const itemsWithoutProduct = useMemo(() => {
    if (!orderReceived) {
      return emptyArray;
    }

    const inProducts = orderReceived.products.reduce((acc, cur) => acc.concat(cur.items.map((i) => i.id)), []);

    return orderReceived.items
      .filter((item) => !inProducts.includes(item.id) && !item.parentId)
      .sort((a, b) => a.position - b.position);
  }, [orderReceived]);

  const products =
    orderReceived?.products.slice(0) || (emptyArray as OrderReceived_LoadQuery['orderReceived']['products']);

  // Sort by position
  products.sort((a, b) => a.position - b.position);

  const createProductsFromItemsCallback = useEventCallback(async () => {
    try {
      await createProductsFromItems();
    } catch (err) {
      logger.error(err);
    }
  });

  const usersEdges = data?.users?.edges;
  const users = useMemo<OrderReceived_UserFragment[]>(() => usersEdges?.map((e) => e.node) || emptyArray, [usersEdges]);
  const assignedToOptions = useMemo<AssignedUserOption[]>(
    () =>
      users
        .filter((u) => [Role.Admin, Role.AccountManagerAndOperator].includes(u.role as Role))
        .map((u) => ({
          label: displayName(u),
          value: u.id,
        })) || emptyArray,
    [users],
  );

  const assignedToValue = useMemo<AssignedUserOption>(
    () => (orderReceived?.assignedTo ? assignedToOptions.find((o) => o.value === orderReceived.assignedTo.id) : null),
    [assignedToOptions, orderReceived?.assignedTo],
  );

  const onChangeAssignedToCallback = useEventCallback(async (event, value: AssignedUserOption) => {
    try {
      const response = await orderReceivedUpdate({
        variables: {
          input: {
            id: orderReceived.id,
            updatedAt: orderReceived.updatedAt,
            assignedToId: value?.value ?? null,
          },
        },
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  const onSkipCheck1Change = useEventCallback(async (value: boolean) => {
    try {
      const response = await orderReceivedUpdate({
        variables: {
          input: {
            id: orderReceived.id,
            updatedAt: orderReceived.updatedAt,
            skipCheck1: value,
          },
        },
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  const onHideFinishedProductsChange = useEventCallback(async (value: boolean) => {
    try {
      const response = await orderReceivedUpdate({
        variables: {
          input: {
            id: orderReceived.id,
            updatedAt: orderReceived.updatedAt,
            hideFinishedProducts: value,
          },
        },
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  const {
    open: reorderProductsDialogOpen,
    onOpen: reorderProductsDialogOnOpen,
    onClose: reorderProductsDialogOnClose,
  } = useDialog(false);

  const needPackagingAssignedTo = orderReceived
    ? orderReceived.phase === OrderReceivedPhase.PACKAGING && orderReceived.packagingAssignedTo.length === 0
    : null;
  const needDeliveryAssignedTo = orderReceived
    ? orderReceived.phase === OrderReceivedPhase.DELIVERY && orderReceived.deliveryAssignedTo.length === 0
    : null;
  const needExpeditionAssignedTo = orderReceived
    ? orderReceived.phase === OrderReceivedPhase.EXPEDITION &&
      orderReceived.expeditionAssignedTo.length === 0 &&
      isCourierDelivery(orderReceived.moneyDeliveryType.id as DeliveryTypes)
    : null;

  const timeEntriesEdges = data?.timeEntries?.edges;
  const timeEntries = useMemo(() => timeEntriesEdges?.map(({ node }) => node) ?? emptyArray, [timeEntriesEdges]);
  const timeEntriesPerProduct = useMemo(
    () =>
      timeEntriesEdges?.reduce<{ [productId: string]: TimeEntry[] }>((acc, cur) => {
        const timeEntry = cur.node;
        if (timeEntry.productId) {
          acc[timeEntry.productId] = acc[timeEntry.productId] ?? [];
          acc[timeEntry.productId].push(timeEntry);
        }
        return acc;
      }, {}) ?? {},
    [timeEntriesEdges],
  );

  const [closeExecute] = useMutation<OrderReceived_CloseMutation, OrderReceived_CloseMutationVariables>(
    OrderReceived_Close,
  );
  const onCloseCallback = useEventCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      if (window.confirm('Naozaj chcete uzavrieť túto objednávku?')) {
        try {
          await closeExecute({
            variables: {
              id: orderReceived.id,
              updatedAt: orderReceived.updatedAt,
            },
          });
          enqueueSnackbar(
            <>
              Úspešne ste uzavreli objednávku <strong>{orderReceived.moneyNumber}</strong>.
            </>,
            { variant: 'success' },
          );
        } catch (err) {
          displayError(err, enqueueSnackbar, logger);
        }
      }
    } catch (err) {
      logger.error(err);
    }
  });

  const phasesEdges = data?.phases?.edges;
  const phases = useMemo(() => phasesEdges?.map(({ node }) => node) ?? emptyArray, [phasesEdges]);

  return (
    <>
      <PageHeader
        title={
          <CopyText withIcon={false}>
            {orderReceived?.moneyNumber} -{' '}
            <Interpolate
              resource={ORDER_RECEIVED_TITLE}
              client={orderReceived?.moneyAddressName || orderReceived?.moneyAddressContactName || '...'}
              name={orderReceived?.moneyName || '...'}
            />
          </CopyText>
        }
        actions={
          !orderReceived ? null : (
            <Box
              display="flex"
              alignItems={{ xs: 'normal', md: 'center' }}
              justifyContent="flex-end"
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={1}
            >
              <HasPermission permission={Permission.OrdersReceivedEditSkipCheck1}>
                <SkipCheck1Switch value={orderReceived.skipCheck1} onChange={onSkipCheck1Change} />
              </HasPermission>
              {orderReceived.phase !== OrderReceivedPhase.DONE && (
                <HasPermission permission={Permission.OrdersReceivedClose}>
                  <Button color="danger" onClick={onCloseCallback} variant="outlined">
                    Uzavrieť objednávku
                  </Button>
                </HasPermission>
              )}{' '}
              <HasPermission permission={Permission.TimeEntriesRead}>
                <Button
                  component={RouterLink}
                  to={url(TIME_ENTRIES_ORDER_RECEIVED_ROUTE, { orderReceived: orderReceived.id })}
                  color="primary"
                  variant="outlined"
                  state={{ fromOrderReceived: true }}
                >
                  Log objednávky
                </Button>
              </HasPermission>
              <OrderReceivedActions
                sx={{ mr: { xs: 0, md: 8 } }}
                orderReceived={orderReceived}
                onOrderReceivedUpdate={onOrderReceivedUpdate}
                users={users}
              />
              <Chip sx={{ ...phaseSx[orderReceived.phase] }} label={ORDER_RECEIVED_PHASE_NAMES[orderReceived.phase]} />
              {orderReceived.phase === OrderReceivedPhase.PRODUCTION && (
                <Chip
                  sx={{ ...statusSx[orderReceived.status] }}
                  label={ORDER_RECEIVED_STATUS_NAMES[orderReceived.status]}
                />
              )}
            </Box>
          )
        }
      />

      <Box>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <>
            {!isValidCombination(
              orderReceived.moneyPaymentType.id as PaymentTypes,
              orderReceived.moneyDeliveryType.id as DeliveryTypes,
            ) && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <AlertTitle>Nepodporovaná kombinácia</AlertTitle>
                <div>
                  Objednávka má zadanú nesprávnu kombináciu platby{' '}
                  <strong>{orderReceived.moneyPaymentType.name}</strong> a dopravy{' '}
                  <strong>{orderReceived.moneyDeliveryType.name}</strong>.
                </div>
              </Alert>
            )}

            <OrderReceivedPhaseNote orderReceived={orderReceived} sx={{ mb: 2 }} />

            <Grid container spacing={1}>
              <Grid item xs={12} lg={3}>
                <PagePaper title={t(ORDER_RECEIVED_ABOUT_CUSTOMER)} sx={paperSx}>
                  <AboutCustomer orderReceived={orderReceived} />
                </PagePaper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <PagePaper title="Doprava a platba" sx={paperSx}>
                  <AboutDelivery orderReceived={orderReceived} onOrderReceivedUpdate={onOrderReceivedUpdate} />
                  <OrderReceivedPhasesNotes
                    orderReceived={orderReceived}
                    onOrderReceivedUpdate={onOrderReceivedUpdate}
                  />
                </PagePaper>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Box height="100%" display="flex" flexDirection="column" sx={paperSx} gap={2}>
                  <PagePaper title={t(ORDER_RECEIVED_PLANNING)}>
                    <Box>
                      <PlanningRow
                        title={t(ORDER_RECEIVED_PLANNING_RECEIVED)}
                        value={orderReceived.moneyCreatedAt}
                        readonly
                      />
                      <PlanningRow
                        id="inspection-at"
                        title="Dátum obhliadky"
                        value={orderReceived.inspectionAt}
                        onSave={onInspectionAtSave}
                      />
                      <PlanningRow
                        id="approved-at"
                        title="Schválenie grafiky"
                        value={orderReceived.approvedAt}
                        onSave={onApprovedAtSave}
                      />
                      <PlanningRow
                        id="expedition-at"
                        title="Dátum vyhotovenia"
                        value={orderReceived.expeditionAt}
                        onSave={onExpeditionAtSave}
                      />
                      <PlanningRow
                        id="assembly-at"
                        title="Dátum montáže"
                        value={orderReceived.assemblyAt}
                        onSave={onAssemblyAtSave}
                      />
                    </Box>
                  </PagePaper>
                  <PagePaper title="Zodpovedný account" sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Autocomplete
                      options={assignedToOptions}
                      onChange={onChangeAssignedToCallback}
                      value={assignedToValue}
                      renderInput={(params) => <TextField {...params} />}
                      isOptionEqualToValue={(a: AssignedUserOption, b: AssignedUserOption) => a.value === b.value}
                      disableClearable
                    />
                  </PagePaper>
                  <OrderReceivedTimeSpent
                    orderReceived={orderReceived}
                    photoBank={orderReceived.products.reduce((acc, cur) => acc + cur.photobank, 0)}
                    phases={phases}
                    timeEntries={timeEntries}
                  />
                </Box>
              </Grid>
            </Grid>

            {products.length > 0 && (
              <Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" my={{ xs: 2, md: 0 }}>
                  <Box>
                    <Box component="h2" mb={0} fontWeight={300}>
                      {t(ORDER_RECEIVED_PRODUCTS)}
                    </Box>
                    <Box component="h4" mt={0}>
                      {orderReceived.moneyNumber}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                    <HasPermission permission={Permission.OrdersReceivedHideFinishedProducts}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={orderReceived.hideFinishedProducts}
                            onChange={(event, checked) => {
                              onHideFinishedProductsChange(checked);
                            }}
                          />
                        }
                        label="Skryť dokončené"
                        sx={{ mr: 4 }}
                      />
                    </HasPermission>
                    <HasPermission permission={Permission.ProductsManage}>
                      <CreateProductDialogButton
                        orderReceivedId={orderReceived.id}
                        refetchQueries={['OrderReceived_Load']}
                        color="primary"
                        variant="contained"
                      >
                        Vytvoriť prázdny produkt
                      </CreateProductDialogButton>
                      <Button
                        color="primary"
                        onClick={reorderProductsDialogOnOpen}
                        startIcon={<ListIcon />}
                        variant="contained"
                      >
                        Preskupiť položky
                      </Button>
                    </HasPermission>
                  </Box>
                </Box>

                {products
                  .filter((p) =>
                    orderReceived.hideFinishedProducts
                      ? p.status !== ProductStatus.DONE && p.status !== ProductStatus.CANCELED
                      : true,
                  )
                  .map((product) => {
                    return (
                      <OrderReceivedProduct
                        key={product.id}
                        product={product}
                        orderNumber={orderReceived.moneyNumber}
                        users={users}
                        onProductChange={onProductUpdate}
                        location={location}
                        orderReceived={orderReceived}
                        timeEntries={timeEntriesPerProduct[product.id]}
                        phases={phases}
                        sx={orderReceivedProductSx}
                      />
                    );
                  })}
              </Box>
            )}

            {itemsWithoutProduct.length > 0 && orderReceived.phase !== OrderReceivedPhase.CANCELED && (
              <HasPermission permission={Permission.ProductsManage}>
                <OrderItemsWithoutProduct
                  orderReceivedId={orderReceived.id}
                  items={itemsWithoutProduct}
                  onCreateOnePerItem={createProductsFromItemsCallback}
                  creatingOnePerItem={createProductsFromItemLoading}
                />
              </HasPermission>
            )}

            <OrderReceivedHistory id={orderReceived.id} />

            <OrderReceivedPackagingDialog open={needPackagingAssignedTo} orderReceived={orderReceived} />
            <OrderReceivedDeliveryDialog open={needDeliveryAssignedTo} orderReceived={orderReceived} />
            <OrderReceivedExpeditionDialog open={needExpeditionAssignedTo} orderReceived={orderReceived} />
            <ReorderProductsDialog
              open={reorderProductsDialogOpen}
              onClose={reorderProductsDialogOnClose}
              orderReceived={orderReceived}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default authorized(Permission.OrdersReceivedRead)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs<'id'>) => {
  if (ENV.SERVER) {
    try {
      const result = await apollo.query({ query: OrderReceived_Load, variables: { id: params.id } });
      return result.data;
    } catch (err) {
      const notFoundErrors = isApolloError(err) && getNotFoundErrors(err.graphQLErrors);
      if (notFoundErrors.length) {
        throw new Response('', { status: 404 });
      }

      throw err;
    }
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Objednávky',
    },
    {
      text: 'Objednávky prijaté',
      to: ORDERS_RECEIVED_ROUTE,
    },
    {
      text: 'Detail objednávky',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Objednávky prijaté | ${meta.title}`,
  }),
};

const SkipCheck1Switch: React.FC<{ value: boolean; onChange(value: boolean) }> = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState<boolean>(value);
  return (
    <Box display="flex" alignItems="center">
      Bez kontroly 1:{' '}
      <Switch
        checked={localValue}
        onChange={(event) => {
          setLocalValue(event.currentTarget.checked);
          onChange(event.currentTarget.checked);
        }}
        sx={{ mx: 1 }}
      />
    </Box>
  );
};
