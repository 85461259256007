import CopyText from '@asaprint/asap/components/CopyText.js';
import SectionTitle from '@asaprint/asap/components/SectionTitle.js';
import useInput from '@asaprint/asap/hooks/useInput.js';
import { OrderReceivedInput } from '@asaprint/asap/schema.client.types.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { Box, Link, TextField } from '@mui/material';
import React from 'react';

interface OwnProps {
  className?: string;
  orderReceived: {
    id: string;
    updatedAt: string;
    moneyDeliveryAddressName?: string;
    moneyDeliveryAddressStreet?: string;
    moneyDeliveryAddressZIPCode?: string;
    moneyDeliveryAddressCity?: string;
    moneyDeliveryAddressCountry?: string;
    moneyAddressFinalName?: string;
    moneyAddressFinalEmail?: string;
    moneyAddressFinalPhone?: string;
    moneyPaymentType?: {
      name: string;
    };
    moneyDeliveryType?: {
      name: string;
    };
    packageNumber?: string;
    packageWeight?: number;
    packageCount?: number;
  };

  onOrderReceivedUpdate(orderReceived: OrderReceivedInput);
}

type Props = OwnProps;

const AboutDelivery: React.FunctionComponent<Props> = ({ className, orderReceived, onOrderReceivedUpdate }) => {
  const onPackageNumberBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    if (orderReceived.packageNumber !== value) {
      onOrderReceivedUpdate({
        id: orderReceived.id,
        updatedAt: orderReceived.updatedAt,
        packageNumber: value,
      });
    }
  });

  const onPackageWeightBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    if (orderReceived.packageNumber !== value) {
      onOrderReceivedUpdate({
        id: orderReceived.id,
        updatedAt: orderReceived.updatedAt,
        packageWeight: value ? parseFloat(value) : null,
      });
    }
  });

  const onPackageCountBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>, value: string) => {
    if (orderReceived.packageNumber !== value) {
      onOrderReceivedUpdate({
        id: orderReceived.id,
        updatedAt: orderReceived.updatedAt,
        packageCount: value ? parseInt(value, 10) : null,
      });
    }
  });

  const { inputProps: packageNumberInputProps } = useInput(orderReceived.packageNumber || '', {
    onBlur: onPackageNumberBlur,
    resetIfChanged: true,
  });
  const { inputProps: packageWeightInputProps } = useInput(orderReceived.packageWeight?.toString() || '', {
    onBlur: onPackageWeightBlur,
    resetIfChanged: true,
  });
  const { inputProps: packageCountInputProps } = useInput(orderReceived.packageCount?.toString() || '', {
    onBlur: onPackageCountBlur,
    resetIfChanged: true,
  });

  return (
    <div>
      <Box display="flex" borderBottom={1} borderColor="divider" mb={2}>
        <Box flex={1}>
          <SectionTitle>Dodacia adresa</SectionTitle>
          <Box mb={2}>
            <CopyText>{orderReceived.moneyDeliveryAddressName}</CopyText>
            <br />
            <CopyText>{orderReceived.moneyDeliveryAddressStreet}</CopyText>
            <br />
            <CopyText>
              {orderReceived.moneyDeliveryAddressZIPCode} {orderReceived.moneyDeliveryAddressCity}
            </CopyText>
            <br />
            <CopyText>{orderReceived.moneyDeliveryAddressCountry}</CopyText>
          </Box>
          <Box mb={2}>
            <CopyText>{orderReceived.moneyAddressFinalName}</CopyText>
            <br />
            {orderReceived.moneyAddressFinalPhone && (
              <>
                <CopyText>
                  <Link href={`tel:${orderReceived.moneyAddressFinalPhone.replace(/ /g, '')}`}>
                    {orderReceived.moneyAddressFinalPhone}
                  </Link>
                </CopyText>
                <br />
              </>
            )}
            {orderReceived.moneyAddressFinalEmail && (
              <>
                <CopyText>
                  <Link href={`mailto:${orderReceived.moneyAddressFinalEmail}`}>
                    {orderReceived.moneyAddressFinalEmail}
                  </Link>
                </CopyText>
              </>
            )}
          </Box>
          <SectionTitle>Spôsob platby</SectionTitle>
          <Box mb={2}>{orderReceived.moneyPaymentType?.name || '-'}</Box>
        </Box>
        <Box flex={1}>
          <SectionTitle>Spôsob dopravy</SectionTitle>
          <Box mb={2}>{orderReceived.moneyDeliveryType ? orderReceived.moneyDeliveryType.name : '-'}</Box>
          <SectionTitle>Číslo zásielky</SectionTitle>
          <Box mb={2}>
            <TextField type="text" name="packageNumber" inputProps={{ maxLength: 50 }} {...packageNumberInputProps} />
          </Box>
          <SectionTitle>Váha v kg</SectionTitle>
          <Box mb={2}>
            <TextField type="number" inputProps={{ step: '0.01' }} name="packageWeight" {...packageWeightInputProps} />
          </Box>
          <SectionTitle>Počet balíkov</SectionTitle>
          <Box mb={2}>
            <TextField type="number" inputProps={{ step: '1' }} name="packageCount" {...packageCountInputProps} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

AboutDelivery.displayName = 'AboutDelivery';

export default React.memo<Props>(AboutDelivery);
