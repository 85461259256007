export enum ProductLogType {
  OPENED,
  STARTED,
  PAUSED,
  RESUMED,
  CANCELED,
  FINISHED_PHASE,
  FINISHED,
  RETURNED,
  CHANGED,
}

export const PRODUCT_LOG_TYPE_NAMES = {
  [ProductLogType.OPENED]: 'spustenie',
  [ProductLogType.STARTED]: 'začatie',
  [ProductLogType.PAUSED]: 'pozastavenie',
  [ProductLogType.RESUMED]: 'začatie',
  [ProductLogType.CANCELED]: 'stornovanie',
  [ProductLogType.FINISHED_PHASE]: 'dokončenie fázy',
  [ProductLogType.FINISHED]: 'dokončenie',
  [ProductLogType.RETURNED]: 'vrátenie',
  [ProductLogType.CHANGED]: 'zmena',
};
