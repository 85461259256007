import { useHTMLContext } from '@asaprint/asap/components/slate/context.js';
import isURL from '@engined/core/validators/isURL.js';
import { Box } from '@mui/material';
import React from 'react';
import { Text } from 'slate';

interface OwnProps {
  attributes?: any;
  children?: React.ReactNode;
  leaf: Text;
}

type Props = OwnProps;

const Leaf: React.FunctionComponent<Props> = ({ attributes, children, leaf }) => {
  const { wrapUrls } = useHTMLContext();

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (wrapUrls && isURL(leaf.text)) {
    children = (
      <Box component="span" sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
        {children}
      </Box>
    );
  }

  if (attributes && Object.keys(attributes).length) {
    return <span {...attributes}>{children}</span>;
  } else {
    return <>{children}</>;
  }
};

Leaf.displayName = 'Leaf';

export default React.memo<Props>(Leaf);
