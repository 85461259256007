import ScrollContainer from '@asaprint/asap/components/ScrollContainer.js';
import { useHTMLContext } from '@asaprint/asap/components/slate/context.js';
import useFileUpload, { UploadStatus } from '@asaprint/asap/hooks/useFileUpload.js';
import { Resource } from '@asaprint/asap/schema.client.types.js';
import { getPath } from '@asaprint/common/helpers/resource.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import {
  DROPZONE_FIELD_UPLOADING,
  FILE_FIELD_REMOVE_TOOLTIP,
  FILE_FIELD_UPLOAD_LABEL,
} from '@engined/client/locales.js';
import filesize from '@engined/core/helpers/filesize.js';
import { getLogger } from '@engined/core/services/logger.js';
import { CloudUpload, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  Link,
  OutlinedInput,
  Stack,
  styled,
  Tooltip,
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Control, useController } from 'react-hook-form';

const logger = getLogger('@ui/components/forms/fields/FileField');

function fileKey(file: File) {
  return `${file.name}-${file.size}-${file.lastModified}`;
}

interface OwnProps {
  className?: string;
  id?: string;
  name: string;
  label: React.ReactNode;
  fullWidth?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  multiple?: boolean;
  uploadButtonLabel?: React.ReactNode;
  uploadMoreButtonLabel?: React.ReactNode;
  accept?: DropzoneOptions['accept'];
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  authorization?: string;
  control?: Control;
}

type Props = OwnProps;

const FileField: React.FunctionComponent<Props> = ({
  className,
  label,
  name,
  id,
  fullWidth,
  variant = 'outlined',
  multiple = false,
  uploadButtonLabel,
  uploadMoreButtonLabel,
  accept,
  startAdornment,
  endAdornment,
  authorization,
  control,
}) => {
  const { t } = useLocale();

  const [uploading, setUploading] = useState<boolean>(false);
  const { field, fieldState } = useController({
    name: name,
    control,
    rules: {
      validate: (value) => {
        if (uploading) {
          return t(DROPZONE_FIELD_UPLOADING);
        }
      },
    },
  });

  const error = fieldState.error?.message;
  const htmlId = id || name;

  const inputProps: FileInputOwnProps = useMemo(() => {
    const onUploaded = (resource: Resource) => {
      field.onChange(multiple ? [...(field.value as Resource[]), resource] : resource);
    };

    const onRemove = (resource: Resource) => {
      field.onChange(multiple ? (field.value as Resource[]).filter((r) => r !== resource) : null);
    };

    const onUploadStart = () => {
      setUploading(true);
    };

    const onUploadEnd = () => {
      setUploading(false);
    };

    return {
      multiple,
      onUploaded,
      onUploadStart,
      onUploadEnd,
      value: field.value,
      uploadButtonLabel,
      uploadMoreButtonLabel,
      onRemove,
      accept,
      authorization,
    };
  }, [multiple, field, uploadButtonLabel, uploadMoreButtonLabel, accept, authorization]);

  return (
    <FileFieldInner
      className={className}
      error={error}
      fullWidth={fullWidth}
      variant={variant}
      htmlId={htmlId}
      label={label}
      inputProps={inputProps}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
    />
  );
};

FileField.displayName = 'FileField';

export default FileField;

interface FileFieldInnerOwnProps {
  className: string;
  error: string;
  fullWidth: boolean;
  variant: OwnProps['variant'];
  htmlId: string;
  label: React.ReactNode;
  inputProps: FileInputOwnProps;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

let FileFieldInner: React.FunctionComponent<FileFieldInnerOwnProps> = ({
  className,
  error,
  fullWidth,
  variant,
  htmlId,
  label,
  inputProps,
  startAdornment,
  endAdornment,
}) => (
  <FormControl variant={variant} className={className} error={!!error} fullWidth={fullWidth}>
    <InputLabel htmlFor={htmlId} shrink>
      {label}
    </InputLabel>
    {variant === 'outlined' ? (
      <OutlinedInput
        type="file"
        notched
        label={label}
        inputComponent={FileInput}
        inputProps={inputProps}
        fullWidth={fullWidth}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
    ) : (
      <Input
        type="file"
        inputComponent={FileInput}
        inputProps={inputProps}
        fullWidth={fullWidth}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
    )}
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

FileFieldInner.displayName = 'FileFieldInner';
FileFieldInner = React.memo(FileFieldInner);

interface FileInputOwnProps {
  multiple?: boolean;
  accept?: DropzoneOptions['accept'];
  value: Resource | Resource[];
  uploadButtonLabel: React.ReactNode;
  uploadMoreButtonLabel: React.ReactNode;
  authorization?: string;
  onUploaded(resource: Resource);
  onUploadStart?();
  onUploadEnd?();
  onRemove(resource: Resource);
}

let FileInput = React.forwardRef<HTMLDivElement, FileInputOwnProps>(
  (
    {
      multiple,
      value,
      accept,
      onUploaded,
      onUploadEnd,
      onUploadStart,
      uploadButtonLabel,
      uploadMoreButtonLabel,
      onRemove,
      authorization,
    },
    ref,
  ) => {
    const { t } = useLocale();
    const [files, setFiles] = useState<File[]>([]);
    const { getRootProps, getInputProps, open } = useDropzone({
      accept,
      multiple,
      noClick: true,
      noKeyboard: true,
      onDrop: (acceptedFiles) => {
        setFiles((s) => {
          const alreadyInserted = multiple ? s.map(fileKey) : [];
          return [...(multiple ? s : []), ...acceptedFiles.filter((a) => !alreadyInserted.includes(fileKey(a)))];
        });
      },
    });
    const uploading = useRef<Map<File, boolean>>(new Map<File, boolean>());
    const resourceToFile = useRef<Map<Resource['id'], File>>(new Map<Resource['id'], File>());
    const fileToResource = useRef<Map<File, Resource['id']>>(new Map<File, Resource['id']>());

    const onStart = useEventCallback((file: File) => {
      uploading.current.set(file, true);
      if (onUploadStart && uploading.current.size === 1) {
        onUploadStart();
      }
    });

    const onDone = useEventCallback((file: File) => {
      const hasSome = uploading.current.size > 0;
      uploading.current.delete(file);
      if (onUploadEnd && hasSome && uploading.current.size === 0) {
        onUploadEnd();
      }
    });

    const onRemoveFile = useEventCallback((file: File) => {
      setFiles((s) => (multiple ? s.filter((f) => f !== file) : []));
      if (fileToResource.current.has(file)) {
        const resourceId = fileToResource.current.get(file);
        const resource = multiple
          ? (value as Resource[]).find((r) => r.id === resourceId)
          : (value as Resource).id === resourceId
          ? (value as Resource)
          : null;

        if (resource === null) {
          logger.warn('File not found in the field.value');
        } else {
          onRemove(resource);
        }
        resourceToFile.current.delete(resourceId);
        fileToResource.current.delete(file);
      }
    });

    const onUploadedCallback = useEventCallback((resource: Resource, file: File) => {
      resourceToFile.current.set(resource.id, file);
      fileToResource.current.set(file, resource.id);
      onUploaded(resource);
    });

    return (
      <ScrollContainer sx={{ p: 2 }} ref={ref}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {files.length === 0 && (multiple ? (value as Resource[])?.length === 0 : !value) ? (
            <Button variant="contained" startIcon={<CloudUpload />} onClick={open}>
              {uploadButtonLabel ?? t(FILE_FIELD_UPLOAD_LABEL)}
            </Button>
          ) : (
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              {multiple ? (
                (value as Resource[]).map((resource: Resource) =>
                  resourceToFile.current.has(resource.id) ? null : (
                    <Uploaded key={resource.id} resource={resource} onRemove={onRemove} multiple={multiple} />
                  ),
                )
              ) : value && !resourceToFile.current.has((value as Resource).id) ? (
                <Uploaded resource={value as Resource} onRemove={onRemove} multiple={multiple} />
              ) : null}
              {files.map((file, i) => (
                <Preview
                  key={fileKey(file)}
                  file={file}
                  onUploaded={onUploadedCallback}
                  onStart={onStart}
                  onDone={onDone}
                  onRemove={onRemoveFile}
                  authorization={authorization}
                />
              ))}
              {multiple && (
                <Button variant="contained" startIcon={<CloudUpload />} onClick={open}>
                  {uploadMoreButtonLabel}
                </Button>
              )}
            </Stack>
          )}
        </div>
      </ScrollContainer>
    );
  },
);

FileInput.displayName = 'FileInput';
FileInput = React.memo(FileInput);

const PreviewRoot = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

PreviewRoot.displayName = 'PreviewRoot';

const Progress = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'status',
})<{ status: UploadStatus }>(({ theme, status }) => ({
  pointerEvents: 'none',
  opacity: status === UploadStatus.COMPLETE ? 0 : 1,
  transition: status === UploadStatus.COMPLETE ? 'opacity .4s ease-in' : 'all .2s linear',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
}));

Progress.displayName = 'Progress';

const PreviewActions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

PreviewActions.displayName = 'PreviewActions';

const PreviewAction = styled('div')(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '50%',
  marginLeft: theme.spacing(1),
}));

PreviewAction.displayName = 'PreviewAction';

interface PreviewOwnProps {
  file: File;
  authorization?: string;

  onUploaded(resource: Resource, file: File);
  onStart?(file: File);
  onDone?(file: File, error?: string);
  onRemove?(file: File);
}

const Preview: React.FunctionComponent<PreviewOwnProps> = ({
  file,
  onUploaded,
  onStart,
  onDone,
  onRemove,
  authorization,
}) => {
  const { t } = useLocale();
  const [url, setUrl] = useState<string>(null);
  const { baseUrl } = useHTMLContext();

  const onUploadedCallback = useEventCallback(async (resource: Resource) => {
    const url = `${baseUrl}${getPath(resource)}`;
    setUrl(url);
    onUploaded(resource, file);
  });

  const onRemoveCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove(file);
  });

  const { status, error, progress } = useFileUpload(file, {
    onStart,
    onDone,
    onUploaded: onUploadedCallback,
    authorization,
  });

  return (
    <PreviewRoot>
      <div>
        {url ? (
          <Link target="_blank" href={url}>
            {file.name} ({filesize(file.size)})
          </Link>
        ) : (
          <span>
            {file.name} ({filesize(file.size)})
          </span>
        )}
      </div>
      {(status === UploadStatus.COMPLETE || status === UploadStatus.ERROR) && (
        <PreviewActions>
          <PreviewAction>
            <Tooltip title={t(FILE_FIELD_REMOVE_TOOLTIP)}>
              <IconButton color="danger" onClick={onRemoveCallback}>
                <Delete />
              </IconButton>
            </Tooltip>
          </PreviewAction>
        </PreviewActions>
      )}
      <Progress status={status}>
        <Box sx={{ flex: 1, p: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color={status === UploadStatus.ERROR ? 'secondary' : 'primary'}
          />
        </Box>
        <Box sx={{ fontSize: (theme) => theme.typography.pxToRem(12) }}>{error ? error : `${progress}%`}</Box>
      </Progress>
    </PreviewRoot>
  );
};

interface UploadedOwnProps {
  resource: Resource;
  multiple: boolean;

  onRemove?(resource: Resource);
}

const Uploaded: React.FunctionComponent<UploadedOwnProps> = ({ resource, onRemove, multiple }) => {
  const { t } = useLocale();
  const { baseUrl } = useHTMLContext();
  const url = `${baseUrl}${getPath(resource)}`;

  const onRemoveCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove(resource);
  });

  return (
    <PreviewRoot>
      <div>
        <Link target="_blank" href={url}>
          {resource.name} ({filesize(resource.size)})
        </Link>
      </div>
      <PreviewActions>
        <PreviewAction>
          <Tooltip title={t(FILE_FIELD_REMOVE_TOOLTIP)}>
            <IconButton color="danger" onClick={onRemoveCallback}>
              <Delete />
            </IconButton>
          </Tooltip>
        </PreviewAction>
      </PreviewActions>
    </PreviewRoot>
  );
};
