import HTML from '@asaprint/asap/components/slate/HTML.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Descendant } from 'slate';

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  username?: string;
  avatar: string;
}

interface Comment {
  id: string;
  createdAt: string;
  updatedAt: string;
  body: Descendant[];
  createdBy: User;
}

interface OwnProps {
  comment: Comment;
}

type Props = OwnProps;

const ProductComment: React.FunctionComponent<Props> = ({ comment }) => {
  return (
    <Box mt={2} display="flex">
      <Box flex="0 0 32px" mr={2} mt={2}>
        <img alt={displayName(comment.createdBy)} src={`${comment.createdBy.avatar}&size=64`} width={32} height={32} />
      </Box>
      <Box flex={1}>
        <Box color="primary.main">{displayName(comment.createdBy)}</Box>
        <Box sx={{ '& p:last-child': { marginBottom: 0 } }}>
          <HTML value={comment.body} />
        </Box>
        <Box component="small" color="#888">
          <FormatDate date={comment.createdAt} format="dd.MM.yyyy HH:mm:ss" />
          {comment.updatedAt !== comment.createdAt && (
            <>
              {' '}
              &bull; <span title={format(new Date(comment.updatedAt), 'dd.MM.yyyy HH:mm:ss')}>upravené</span>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ProductComment.displayName = 'ProductComment';

export default React.memo<Props>(ProductComment);
