import FileField from '@asaprint/asap/components/forms/fields/FileField.js';
import { useHTMLContext } from '@asaprint/asap/components/slate/context.js';
import ToggleButtonWithTooltip from '@asaprint/asap/components/ToggleButtonWithTooltip.js';
import { Resource } from '@asaprint/asap/schema.client.types.js';
import { getPath } from '@asaprint/common/helpers/resource.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { FormErrors, FormErrorsOverride } from '@engined/client/hooks/useFormResolver.js';
import {
  CLOSE,
  FIELD_IS_REQUIRED,
  SLATE_WITH_FILES_FILE_BUTTON_TOOLTIP,
  SLATE_WITH_FILES_FILE_DIALOG_RESOURCE,
  SLATE_WITH_FILES_FILE_DIALOG_RESOURCE_BUTTON,
  SLATE_WITH_FILES_FILE_DIALOG_SUBMIT_BUTTON,
  SLATE_WITH_FILES_FILE_DIALOG_TITLE,
} from '@engined/client/locales.js';
import filesize from '@engined/core/helpers/filesize.js';
import { getLogger } from '@engined/core/services/logger.js';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Link as MUILink, styled, ToggleButtonProps } from '@mui/material';
import React, { useState } from 'react';
import { Editor, Element, FileElement, Node, NodeEntry, Range, Transforms, Location as SlateLocation } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

const logger = getLogger('@ui/components/slate/plugins/withFiles');

const withFiles = (editor: Editor): Editor => {
  const { insertData, isVoid, normalizeNode, isInline } = editor;

  editor.isVoid = (element) => {
    return element.type === 'file' ? false : isVoid(element);
  };

  editor.isInline = (element) => {
    return element.type === 'file' ? true : isInline(element);
  };

  editor.insertData = (data) => {
    const { files } = data;

    if (files?.length > 0) {
      for (const file of Array.from(files)) {
        const [mime] = file.type.split('/');
        // Images are processed by another plugin
        if (mime === 'image') {
          continue;
        }

        const data = new FormData();
        const req = new XMLHttpRequest();
        data.append('file', file);
        req.addEventListener('load', (event) => {
          // Set progress
          if (req.status === 200) {
            const resource = req.response[0];
            wrapFile(editor, resource);
          } else {
            logger.error(req.response?.error || req.response);
          }
        });

        req.addEventListener('error', (event) => {
          logger.error(new Error('Cannot upload file!'));
        });

        req.responseType = 'json';
        req.open('post', `/uploads/`);
        req.setRequestHeader('CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
        req.withCredentials = true;
        req.send(data);
      }

      // Pass so that another plugin can process files
      insertData(data);
    } else {
      insertData(data);
    }
  };

  editor.normalizeNode = ([node, path]) => {
    if (Element.isElement(node) && node.type === 'file') {
      const text = Node.string(node);
      if (!text) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
};

function fileNodeMatch(node: Node) {
  return Element.isElement(node) && node.type === 'file';
}

function unwrapFile(editor: Editor) {
  editor.withoutNormalizing(() => {
    const isCollapsed = !editor.selection || Range.isCollapsed(editor.selection);

    if (isCollapsed) {
      Transforms.unwrapNodes(editor, {
        match: fileNodeMatch,
      });
      return;
    }

    const fileAboveAnchor = getActiveFile(editor, editor.selection?.anchor);
    if (fileAboveAnchor) {
      Transforms.splitNodes(editor, {
        at: editor.selection?.anchor,
        match: fileNodeMatch,
      });
    }
    const fileAboveFocus = getActiveFile(editor, editor.selection?.focus);
    if (fileAboveFocus) {
      Transforms.splitNodes(editor, {
        at: editor.selection?.focus,
        match: fileNodeMatch,
      });
    }

    if (Range.end(editor.selection).offset === 0) {
      // Move offset so that we do not match next element
      Transforms.move(editor, { edge: 'end', distance: 1, unit: 'offset', reverse: true });
    }
    Transforms.unwrapNodes(editor, {
      match: fileNodeMatch,
    });
  });
}

function wrapFile(editor: Editor, resource: Resource) {
  const { selection } = editor;
  const isCollapsed = !selection || Range.isCollapsed(selection);
  if (isFileActive(editor)) {
    if (isCollapsed) {
      const file = getActiveFile(editor);
      Transforms.setNodes(editor, { ...file, resource }, { at: file[1] });
      return;
    }

    unwrapFile(editor);
  }

  const text = { text: `${resource.name} (${filesize(resource.size).join('')})` };
  const file: FileElement = {
    type: 'file',
    children: isCollapsed ? [text] : [],
    resource,
  };

  if (!isCollapsed) {
    editor.withoutNormalizing(() => {
      Transforms.wrapNodes(editor, file, { split: true, mode: 'highest' });
      Transforms.collapse(editor, { edge: 'end' });
    });
  } else {
    editor.withoutNormalizing(() => {
      Transforms.insertNodes(editor, file);
      Transforms.collapse(editor, { edge: 'end' });
    });
  }
}

const isFileActive = (editor: Editor) => {
  return !!getActiveFile(editor);
};

const getActiveFile = (editor: Editor, selection?: SlateLocation): NodeEntry<FileElement> => {
  const [file] = Editor.nodes<FileElement>(editor, {
    at: selection,
    match: fileNodeMatch,
  });
  return file;
};

export default withFiles;

const File = styled(MUILink)(({ theme }) => ({}));

File.displayName = 'File';

interface EditorFileOwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: FileElement;
}

export const EditorFile: React.FunctionComponent<EditorFileOwnProps> = ({ attributes, children, element }) => {
  return (
    <span {...attributes}>
      <File href={getPath(element.resource)} target="_blank">
        {children}
      </File>
    </span>
  );
};

EditorFile.displayName = 'EditorFile';

interface HTMLFileOwnProps {
  attributes?: any;
  children?: React.ReactNode;
  element: FileElement;
}

export const HTMLFile: React.FunctionComponent<HTMLFileOwnProps> = ({ attributes, children, element }) => {
  const { baseUrl } = useHTMLContext();

  return (
    <File {...attributes} href={`${baseUrl}${getPath(element.resource)}`} target="_blank">
      {children}
    </File>
  );
};

HTMLFile.displayName = 'HTMLFile';

type FileButtonOwnProps = Omit<ToggleButtonProps, 'type' | 'icon' | 'tooltip' | 'value'>;

export const FileButton: React.FunctionComponent<FileButtonOwnProps> = ({ ...rest }) => {
  const editor = useSlate();
  const { t } = useLocale();
  const { open, onClose, onOpen } = useDialog(false);
  const [selection, setSelection] = useState<Range>(null);

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!ReactEditor.isFocused(editor)) {
      ReactEditor.focus(editor);
    }
    if (isFileActive(editor)) {
      unwrapFile(editor);
    } else {
      onOpen();
      setSelection(editor.selection);
    }
  });

  const onCancelCallback = useEventCallback(() => {
    onClose();
    if (selection?.focus || selection?.anchor) {
      Transforms.select(editor, selection);
    }
    setSelection(null);
    ReactEditor.focus(editor);
  });

  const onSubmitCallback = useEventCallback(() => {
    onClose();
    setSelection(null);
    ReactEditor.focus(editor);
  });

  return (
    <>
      <ToggleButtonWithTooltip
        tooltip={t(SLATE_WITH_FILES_FILE_BUTTON_TOOLTIP)}
        {...rest}
        value="file"
        onMouseDown={onClickCallback}
        selected={isFileActive(editor)}
      >
        <AttachFileIcon />
      </ToggleButtonWithTooltip>
      <Dialog open={open} onClose={onCancelCallback} fullWidth maxWidth="md" keepMounted={false}>
        <FileDialogContent onCancel={onCancelCallback} selection={selection} onSubmit={onSubmitCallback} />
      </Dialog>
    </>
  );
};

FileButton.displayName = 'FileButton';

interface FormValues {
  resource: Resource;
}

interface FileDialogContentProps {
  selection: Range | null;
  onSubmit();
  onCancel();
}

let FileDialogContent: React.FunctionComponent<FileDialogContentProps> = ({ selection, onCancel, onSubmit }) => {
  const { t } = useLocale();
  const editor = useSlate();

  const onSubmitCallback: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      if (selection?.focus || selection?.anchor) {
        Transforms.select(editor, selection);
      }
      wrapFile(editor, values.resource);
      if (onSubmit) {
        onSubmit();
      }
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Form
      defaultValues={{
        resource: null,
      }}
      onSubmit={onSubmitCallback}
      validate={validate}
      stopSubmitPropagation
    >
      <DialogTitle>{t(SLATE_WITH_FILES_FILE_DIALOG_TITLE)}</DialogTitle>
      <DialogContent>
        <FileField
          fullWidth
          name="resource"
          variant="standard"
          label={t(SLATE_WITH_FILES_FILE_DIALOG_RESOURCE)}
          uploadButtonLabel={t(SLATE_WITH_FILES_FILE_DIALOG_RESOURCE_BUTTON)}
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons
        closeOnClick={onCancel}
        closeLabel={t(CLOSE)}
        submitLabel={t(SLATE_WITH_FILES_FILE_DIALOG_SUBMIT_BUTTON)}
      />
    </Form>
  );
};

FileDialogContent.displayName = 'FileDialogContent';
FileDialogContent = React.memo(FileDialogContent);

function validate(values: FormValues, t: LocaleContextValue['t']) {
  const errors: FormErrors<FormErrorsOverride<FormValues, 'resource'>> = {};
  if (!values.resource) {
    errors.resource = t(FIELD_IS_REQUIRED);
  }

  return errors;
}
