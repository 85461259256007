import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import ToggleButtonWithTooltip, {
  Props as ToggleButtonWithTooltipProps,
} from '@asaprint/asap/components/ToggleButtonWithTooltip.js';
import React from 'react';
import { Editor, Element, Transforms } from 'slate';
import { useSlate } from 'slate-react';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const toggleBlock = (editor: Editor, format: Element['type']) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes((n as unknown as Element).type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] } as Element;
    Transforms.wrapNodes(editor, block);
  }
};

export const isBlockActive = (editor: Editor, format: Element['type']): boolean => {
  const [match] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && n.type === format,
  });

  return !!match;
};

interface OwnProps {
  type: Element['type'];
  icon: React.ReactNode;
  tooltip: React.ReactNode;
}

type Props = OwnProps & Omit<ToggleButtonWithTooltipProps, 'type' | 'icon' | 'tooltip' | 'value'>;

const BlockButton: React.FunctionComponent<Props> = ({ type, icon, tooltip, ...rest }) => {
  const editor = useSlate();

  const onClickCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleBlock(editor, type);
  });

  return (
    <ToggleButtonWithTooltip
      {...rest}
      value={type}
      selected={isBlockActive(editor, type)}
      onMouseDown={onClickCallback}
      tooltip={tooltip}
    >
      {icon}
    </ToggleButtonWithTooltip>
  );
};

BlockButton.displayName = 'BlockButton';

export default React.memo<Props>(BlockButton);
