import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import React from 'react';
import { Control, useController } from 'react-hook-form';

interface OwnProps {
  name: string;
  control?: Control;
}

type Props = OwnProps & CheckboxProps;

const CheckboxField: React.FunctionComponent<Props> = ({ name, onBlur, onChange, inputProps, control, ...rest }) => {
  const { field } = useController({
    name,
    control,
  });

  const onBlurCallback: Props['onBlur'] = (event) => {
    field.onBlur();
    if (onBlur) {
      onBlur(event);
    }
  };

  const onChangeCallback: Props['onChange'] = (event, ...rest) => {
    field.onChange(event);
    if (onChange) {
      onChange(event, ...rest);
    }
  };

  return (
    <MuiCheckbox
      checked={field.value}
      onBlur={onBlurCallback}
      onChange={onChangeCallback}
      inputProps={{
        name,
        ...inputProps,
      }}
      name={field.name}
      inputRef={field.ref}
      {...rest}
    />
  );
};

CheckboxField.displayName = 'CheckboxField';

export default React.memo<Props>(CheckboxField);
