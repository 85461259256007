import type { Props as RichTextEditorProps } from '@asaprint/asap/components/slate/Editor.js';
import RichTextEditor from '@asaprint/asap/components/slate/Editor.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import {
  FormControl,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
  SxProps,
} from '@mui/material';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Node } from 'slate';

type fixme = any;

export interface OwnProps {
  name: string;
  id?: string;
  label?: React.ReactNode;
  inputProps?: InputBaseComponentProps & Partial<RichTextEditorProps>;
  helperText?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  control?: Control;
  sx?: SxProps;
}

type Props = OwnProps;

const RichTextField: React.FunctionComponent<Props> = ({ label, helperText, control, ...rest }) => {
  const { field, fieldState } = useController({ name: rest.name, control });
  const htmlId = rest.id || rest.name;

  const onChange = useEventCallback((value: Node[]) => {
    field.onChange(value);
  });

  const error = fieldState.error?.message;

  return (
    <RichTextFieldInner
      error={error}
      helperText={helperText}
      htmlId={htmlId}
      value={field.value}
      onChange={onChange}
      label={label}
      {...rest}
    />
  );
};

export default React.memo<Props>(RichTextField);

interface RichTextFieldInnerOwnProps {
  error: string;
  helperText: React.ReactNode;
  htmlId: string;
  value: Node[];
  label: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  sx?: SxProps;
  onChange(value: Node[]);
}

let RichTextFieldInner: React.FunctionComponent<RichTextFieldInnerOwnProps> = ({
  error,
  helperText,
  htmlId,
  value,
  onChange,
  label,
  startAdornment,
  endAdornment,
  sx,
  ...rest
}) => {
  return (
    <FormControl error={!!error} fullWidth variant="outlined">
      {label && (
        <InputLabel htmlFor={htmlId} shrink>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={htmlId}
        value={value}
        fullWidth
        {...rest}
        onChange={onChange as fixme}
        multiline
        label={label}
        notched
        inputComponent={RichTextEditorInput}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        sx={sx}
      />
      <FormHelperText component="div">{error || helperText}</FormHelperText>
    </FormControl>
  );
};

RichTextFieldInner.displayName = 'RichTextFieldInner';
RichTextFieldInner = React.memo(RichTextFieldInner);

// NOTE: Cache some function so that we do not re-render if not necessary
// InputBase is not using useCallback :(
let RichTextEditorInput = React.forwardRef<HTMLDivElement, InputBaseComponentProps & RichTextEditorProps>(
  ({ inputRef, className, ...rest }, ref) => {
    return (
      <div className={className} ref={ref}>
        <RichTextEditor {...rest} />
      </div>
    );
  },
);

RichTextEditorInput.displayName = 'RichTextEditorInput';
RichTextEditorInput = React.memo(RichTextEditorInput);
