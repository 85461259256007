import { ToggleButtonProps } from '@mui/material';
import { ToggleButton, Tooltip } from '@mui/material';
import React from 'react';

interface OwnProps {
  className?: string;
  tooltip: React.ReactNode;
}

export type Props = ToggleButtonProps & OwnProps;

const ToggleButtonWithTooltip: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { tooltip, children, className, ...rest },
  ref,
) => {
  return (
    <Tooltip title={tooltip}>
      <ToggleButton {...rest} className={className} ref={ref}>
        {children}
      </ToggleButton>
    </Tooltip>
  );
};

ToggleButtonWithTooltip.displayName = 'ToggleButtonWithTooltip';

export default React.memo<Props>(React.forwardRef(ToggleButtonWithTooltip));
