import { getLogger } from '@engined/core/services/logger.js';

const logger = getLogger('helpers/MoneyOrderReceivedItem');

const KEYWORD = 'Špecifikácia pre ';
const KEYWORD_CUSTOMER = 'Špecifikácia pre zákazníka - ';
const KEYWORD_PRODUCTION = 'Špecifikácia pre výrobu - ';
const KEYWORD_ACCOUNT = 'Špecifikácia pre accounta - ';

const MAX_ITERATIONS = 50;

export function splitNote(note: string): {
  columns: {
    customer: string[];
    production: string[];
    account: string[];
  };
  firstNote: string;
  lastNote: string;
} {
  let firstNote = '';
  const columns = {
    customer: [],
    production: [],
    account: [],
  };

  let toProcess = note;
  let index = toProcess.indexOf(KEYWORD);
  if (index !== -1) {
    firstNote = toProcess.substring(0, index).trim();
    toProcess = toProcess.substring(index).trim();

    let i = 0;
    do {
      const keywordIndex = toProcess.slice(KEYWORD.length).search(KEYWORD);
      const endIndex = keywordIndex === -1 ? keywordIndex : keywordIndex + KEYWORD.length;
      const section = endIndex === -1 ? toProcess.substring(0) : toProcess.substring(0, endIndex);
      if (section.startsWith(KEYWORD_CUSTOMER)) {
        // columns.customer.push(section);
        columns.customer.push(section.substring(KEYWORD_CUSTOMER.length));
      } else if (section.startsWith(KEYWORD_PRODUCTION)) {
        // columns.production.push(section);
        columns.production.push(section.substring(KEYWORD_PRODUCTION.length));
      } else if (section.startsWith(KEYWORD_ACCOUNT)) {
        // columns.account.push(section);
        columns.account.push(section.substring(KEYWORD_ACCOUNT.length));
      }
      toProcess = endIndex === -1 ? '' : toProcess.substring(endIndex).trim();
      index = toProcess.indexOf(KEYWORD);
      i++;
    } while (index !== -1 && i < MAX_ITERATIONS);

    if (i === MAX_ITERATIONS) {
      logger.warn('Max iterations reached in OrderReceivedNote', { note });
    }
  }

  const lastNote = toProcess;
  return {
    columns,
    firstNote,
    lastNote,
  };
}
