import { useMutation } from '@apollo/client/index.js';
import { OrderReceivedPackagingDialog_Save } from '@asaprint/asap/components/dialogs/OrderReceivedPackagingDialog.graphql';
import UserField from '@asaprint/asap/components/forms/fields/UserField.js';
import {
  OrderReceivedPackagingDialog_SaveMutation,
  OrderReceivedPackagingDialog_SaveMutationVariables,
  UserField_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Role } from '@asaprint/common/access.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/OrderReceivedPackagingDialog');

interface OrderReceived {
  id: string;
  updatedAt: string;
}

interface OwnProps {
  open?: boolean;
  orderReceived: OrderReceived;

  onClose?(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const OrderReceivedPackagingDialog: React.FunctionComponent<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <OrderReceivedPackagingDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

OrderReceivedPackagingDialog.displayName = 'OrderReceivedPackagingDialog';

export default OrderReceivedPackagingDialog;

interface FormValues {
  packagingAssignedTo: Option<UserField_UserFragment>;
}

interface OrderReceivedPackagingDialogContentOwnProps {
  orderReceived: OrderReceived;

  onClose?(event?: React.MouseEvent<HTMLButtonElement>);
}

const roles = [
  Role.PrintOperator,
  Role.Operator,
  Role.AccountManagerAndOperator,
  Role.OperatorAndGraphicDesigner,
  Role.Admin,
  Role.Expediter,
];

const OrderReceivedPackagingDialogContent: React.FunctionComponent<OrderReceivedPackagingDialogContentOwnProps> = ({
  orderReceived,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saveExecute] = useMutation<
    OrderReceivedPackagingDialog_SaveMutation,
    OrderReceivedPackagingDialog_SaveMutationVariables
  >(OrderReceivedPackagingDialog_Save);

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          id: orderReceived.id,
          updatedAt: orderReceived.updatedAt,
          packagingAssignedTo: values.packagingAssignedTo?.value ?? null,
        },
      });
      enqueueSnackbar('Úspešne ste nastavili zodpovednú osobu za balenie objednávky.', {
        variant: 'success',
      });
      onClose?.();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        packagingAssignedTo: null,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <DialogTitle>Objednávka na zabalenie</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Všetky produkty z objednávky sú dokončené a objednávka čaká na zabalenie. Vyberte prosím osobu zodpovednú za
          balenie pre kuriéra.
        </DialogContentText>

        <UserField
          active
          roles={roles}
          name="packagingAssignedTo"
          inputLabel="Priradená osoba"
          inputVariant="standard"
          autoFocus
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={onClose ? () => onClose() : null} submitLabel="Uložiť" />
    </Form>
  );
};

OrderReceivedPackagingDialogContent.displayName = 'OrderReceivedPackagingDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.packagingAssignedTo) {
    errors.packagingAssignedTo = 'Vyberte zodpovednú osobu';
  }

  return errors;
}
