const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
export default function filesize(size: number): [number, string] {
  let current = size;
  let index = 0;
  while (current > 1024 && index < sizes.length) {
    current = current / 1024;
    index++;
  }

  return [Math.round(current * 100) / 100, sizes[index]];
}
