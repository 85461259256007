import { OrderReceivedLogType } from '@asaprint/common/constants/OrderReceivedLog.js';
import React from 'react';
import { ORDER_RECEIVED_PHASE_NAMES, ORDER_RECEIVED_STATUS_NAMES } from '@asaprint/common/constants/OrderReceived.js';
import { Box, Link } from '@mui/material';
import FormatDate from '@engined/client/components/FormatDate.js';
import { displayName } from '@asaprint/common/helpers/User.js';

type Changes = {
  products: {
    created: [
      {
        name: string;
        number: string;
      },
    ];
  };
} & {
  timeEstimates: {
    before: { estimate: number; phase: { name: string } }[];
    after: { estimate: number; phase: { name: string } }[];
  };
} & {
  emailSentError: {
    noEmail: boolean;
  };
} & {
  emailSentSuccess: {
    type: 'order-processing' | 'order-ready';
    emails: string[];
  };
} & {
  smsSentError: {
    noPhoneNumber: boolean;
  };
} & {
  smsSentSuccess: {
    phoneNumber: string;
    message: string;
  };
} & {
  [key: string]: {
    before?: any;
    after?: any;
  };
};

interface OwnProps {
  type: OrderReceivedLogType;
  changes: Changes;
}

const KEY_NAMES: { [key: string]: { label: string; suffix: string } } = {
  moneyCrn: { label: 'IČO', suffix: 'é' },
  moneyName: { label: 'Názov', suffix: 'é' },
  moneyVatId: { label: 'IČDPH', suffix: 'é' },
  moneyNumber: { label: 'Číslo', suffix: 'é' },
  moneyIssuedAt: { label: 'Dátum vystavenia', suffix: 'é' },
  moneyCreatedAt: { label: 'Dátum vytvorenia', suffix: 'é' },
  moneyLocalVatId: { label: 'DIČ', suffix: 'é' },
  moneyAddressCity: { label: 'Adresa - mesto', suffix: 'é' },
  moneyAddressName: { label: 'Adresa - názov', suffix: 'é' },
  moneyCreatedById: { label: 'Vytvoril - zamestnanec', suffix: 'é' },
  moneyUpdatedById: { label: 'Upravil - zamestnanec', suffix: 'é' },
  moneyAddressStreet: { label: 'Adresa - ulica', suffix: 'é' },
  moneyPaymentTypeId: { label: 'ID spôsobu platby', suffix: 'é' },
  moneyAddressCountry: { label: 'Adresa - krajina', suffix: 'é' },
  moneyAddressZIPCode: { label: 'Adresa - PSČ', suffix: 'é' },
  moneyDeliveryTypeId: { label: 'ID spôsobu dopravy', suffix: 'é' },
  moneyAddressFinalName: { label: 'Koncový príjemca - názov', suffix: 'é' },
  moneyAddressFinalEmail: { label: 'Koncový príjemca - email', suffix: 'é' },
  moneyAddressFinalPhone: { label: 'Koncový príjemca - telefón', suffix: 'é' },
  moneyAddressContactName: { label: 'Adresa - meno', suffix: 'é' },
  moneyInvoiceAddressCity: { label: 'Fakturačná adresa - mesto', suffix: 'é' },
  moneyDeliveryAddressName: { label: 'Doručovacia adresa - meno', suffix: 'é' },
  moneyInvoiceAddressName: { label: 'Fakturačná adresa - meno', suffix: 'é' },
  moneyDeliveryAddressCity: { label: 'Doručovacia adresa - mesto', suffix: 'é' },
  moneyInvoiceAddressStreet: { label: 'Fakturačná adresa - ulica', suffix: 'é' },
  moneyDeliveryAddressStreet: { label: 'Doručovacia adresa - ulica', suffix: 'é' },
  moneyInvoiceAddressCountry: { label: 'Fakturačná adresa - krajina', suffix: 'é' },
  moneyDeliveryAddressCountry: { label: 'Doručovacia adresa - krajina', suffix: 'é' },
  moneyInvoiceAddressZIPCode: { label: 'Fakturačná adresa - PSČ', suffix: 'é' },
  moneyDeliveryAddressZIPCode: { label: 'Doručovacia adresa - PSČ', suffix: 'é' },
  expeditionNote: { label: 'Poznámka pre expedíciu', suffix: 'á' },
  productionNote: { label: 'Poznámka pre výrobu', suffix: 'á' },
  packagingNote: { label: 'Poznámka pre balenie', suffix: 'á' },
  assemblyNote: { label: 'Poznámka pre montáž', suffix: 'á' },
  invoicingNote: { label: 'Poznámka pre fakturáciu', suffix: 'á' },
  accountManagerNote: { label: 'Poznámka pre kotrolu (account managera)', suffix: 'á' },
  packageWeight: { label: 'Váha', suffix: 'á' },
  packageCount: { label: 'Počet balíkov', suffix: 'ý' },
  packageNumber: { label: 'Číslo zásielky', suffix: 'é' },
  moneyInvoiceAddressPersonId: { label: 'Fakturačná adresa - osoba (ID)', suffix: 'á' },
  moneyCompanyId: { label: 'Firma', suffix: 'á' },
  moneyAddressInvoiceName: { label: 'Fakturačná adresa - osoba', suffix: 'á' },
  moneyAddressContactPersonId: { label: 'Dodávateľ - osoba', suffix: 'á' },
  moneyInvoiceAddressCompanyId: { label: 'Fakturačná adresa - firma (ID)', suffix: 'á' },
  moneyDeliveryAddressCompanyId: { label: 'Adresa doručenia - firma (ID)', suffix: 'á' },
};

type Props = OwnProps;

const OrderReceivedLogChanges: React.FunctionComponent<Props> = ({ type, changes }) => {
  if (type === OrderReceivedLogType.EMAIL_SENT_ERROR) {
    return <Box>{changes.emailSentError?.noEmail ? 'Zákazník nemá vyplnenú e-mailovú adresu' : null}</Box>;
  }

  if (type === OrderReceivedLogType.EMAIL_SENT_SUCCESS) {
    return (
      <Box>
        Zákazníkovi bol odoslaný email o{' '}
        {changes.emailSentSuccess.type === 'order-processing' ? 'spracovávaní' : 'dokončení'} objednávky na adresy:
        {changes.emailSentSuccess.emails.map((e, index, arr) => (
          <Link key={e} href={`mailto:${e}`}>
            {e}
            {index + 1 < arr.length ? ', ' : ''}
          </Link>
        ))}
      </Box>
    );
  }

  if (type === OrderReceivedLogType.SMS_SENT_ERROR) {
    return <Box>{changes.smsSentError?.noPhoneNumber ? 'Zákazník nemá vyplnené planté telefónne číslo' : null}</Box>;
  }

  if (type === OrderReceivedLogType.SMS_SENT_SUCCESS) {
    return (
      <Box>
        Zákazníkovi bola odoslaná SMS správa na číslo <strong>{changes.smsSentSuccess.phoneNumber}</strong> s textom
        <em>{changes.smsSentSuccess.message}</em>
      </Box>
    );
  }

  return (
    <Box>
      {Object.keys(changes).map((key) => {
        if (!changes[key]) {
          return null;
        }

        switch (key) {
          case 'products':
            return (
              <div key={key}>
                Vytvorené produkty:{' '}
                {changes.products.created.map((c, index) => (
                  <React.Fragment key={c.number}>
                    {c.number}
                    {index + 1 !== changes.products.created.length ? ', ' : ''}
                  </React.Fragment>
                ))}
              </div>
            );
          case 'status':
            return (
              <div key={key}>
                <strong>Stav výroby</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z <em>{ORDER_RECEIVED_STATUS_NAMES[changes[key].before]}</em>{' '}
                  </>
                ) : null}
                na <em>{ORDER_RECEIVED_STATUS_NAMES[changes[key].after]}</em>
              </div>
            );
          case 'phase':
            return (
              <div key={key}>
                <strong>Fáza objednávky</strong> zmenená{' '}
                {changes[key].before ? (
                  <>
                    z <em>{ORDER_RECEIVED_PHASE_NAMES[changes[key].before]}</em>{' '}
                  </>
                ) : null}
                na <em>{ORDER_RECEIVED_PHASE_NAMES[changes[key].after]}</em>
              </div>
            );
          case 'approvedAt':
            return (
              <div key={key}>
                <strong>Schválenie grafiky</strong> zmenené{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'expeditionAt':
            return (
              <div key={key}>
                <strong>Dátum vyhotovenia</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'inspectionAt':
            return (
              <div key={key}>
                <strong>Dátum obhliadky</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'assemblyAt':
            return (
              <div key={key}>
                <strong>Dátum montáže</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'assemblyStartedAt':
            return (
              <div key={key}>
                <strong>Dátum začatia montáže</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'moneyIssuedAt':
            return (
              <div key={key}>
                <strong>Dátum vystavenia</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy" />
                </em>
              </div>
            );
          case 'moneyCreatedAt':
            return (
              <div key={key}>
                <strong>Dátum vytvorenia</strong> zmenený{' '}
                {changes[key].before ? (
                  <>
                    z{' '}
                    <em>
                      <FormatDate date={changes[key].before} format="dd.MM.yyyy HH:mm:ss" />
                    </em>{' '}
                  </>
                ) : null}
                na{' '}
                <em>
                  <FormatDate date={changes[key].after} format="dd.MM.yyyy HH:mm:ss" />
                </em>
              </div>
            );
          case 'assignedToId':
            return null;
          case 'assignedTo':
            return (
              <div key={key}>
                <strong>Zodpovedný account</strong> zmenený z{' '}
                {changes[key].before ? <em>{displayName(changes[key].before)}</em> : <em>nikto</em>} na{' '}
                {changes[key].after ? <em>{displayName(changes[key].after)}</em> : <em>nikto</em>}
              </div>
            );
          case 'assemblyAssignedTo':
            return (
              <div key={key}>
                <strong>Zodpovedný za montáž</strong> zmenený z{' '}
                {changes[key].before ? <em>{changes[key].before.map(displayName).join(', ')}</em> : <em>nikto</em>} na{' '}
                {changes[key].after ? <em>{changes[key].after.map(displayName).join(', ')}</em> : <em>nikto</em>}
              </div>
            );
          case 'packagingAssignedTo':
            return (
              <div key={key}>
                <strong>Zodpovedný za balenie objednávky</strong> zmenený z{' '}
                {changes[key].before ? <em>{changes[key].before.map(displayName).join(', ')}</em> : <em>nikto</em>} na{' '}
                {changes[key].after?.length ? (
                  <em>{changes[key].after.map(displayName).join(', ')}</em>
                ) : (
                  <em>nikto</em>
                )}
              </div>
            );
          case 'deliveryAssignedTo':
            return (
              <div key={key}>
                <strong>Zodpovedný za dopravu ASAPRINT</strong> zmenený z{' '}
                {changes[key].before ? <em>{changes[key].before.map(displayName).join(', ')}</em> : <em>nikto</em>} na{' '}
                {changes[key].after?.length ? (
                  <em>{changes[key].after.map(displayName).join(', ')}</em>
                ) : (
                  <em>nikto</em>
                )}
              </div>
            );
          case 'expeditionAssignedTo':
            return (
              <div key={key}>
                <strong>Zodpovedný za expedíciu</strong> zmenený z{' '}
                {changes[key].before ? <em>{changes[key].before.map(displayName).join(', ')}</em> : <em>nikto</em>} na{' '}
                {changes[key].after?.length ? (
                  <em>{changes[key].after.map(displayName).join(', ')}</em>
                ) : (
                  <em>nikto</em>
                )}
              </div>
            );
          case 'timeEstimates':
            return (
              <div key={key}>
                <strong>Časové odhady</strong> zmenené z{' '}
                <em>
                  {changes[key].before
                    .map(({ estimate, phase }) => `${phase.name}: ${(estimate / 3600).toFixed(2)} h.`)
                    .join(', ')}
                </em>{' '}
                na{' '}
                <em>
                  {changes[key].after
                    .map(({ estimate, phase }) => `${phase.name}: ${(estimate / 3600).toFixed(2)} h.`)
                    .join(', ')}
                </em>
              </div>
            );
          case 'moneyIsFinished':
            return (
              <div key={key}>
                <strong>Vybavené</strong> zmenené{' '}
                {changes[key].before !== undefined ? (
                  <>
                    z <em>{changes[key].before ? 'Áno' : 'Nie'}</em>{' '}
                  </>
                ) : null}
                na <em>{changes[key].after ? 'Áno' : 'Nie'}</em>
              </div>
            );
          default:
            return (
              <div key={key}>
                <strong>{KEY_NAMES[key]?.label || key}</strong> zmenen{KEY_NAMES[key]?.suffix || 'é'}{' '}
                {changes[key].before ? (
                  <>
                    z <em>{JSON.stringify(changes[key].before)}</em>{' '}
                  </>
                ) : null}
                na <em>{JSON.stringify(changes[key].after)}</em>
              </div>
            );
        }
      })}
    </Box>
  );
};

OrderReceivedLogChanges.displayName = 'OrderReceivedLogChanges';

export default React.memo<Props>(OrderReceivedLogChanges);
