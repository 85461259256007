import { useMutation } from '@apollo/client/index.js';
import { CreateProductDialogButton } from '@asaprint/asap/components/dialogs/CreateProductDialog.js';
import {
  ReorderProductsDialog_AddItem,
  ReorderProductsDialog_MoveItem,
  ReorderProductsDialog_RemoveItem,
} from '@asaprint/asap/components/dialogs/ReorderProductsDialog.graphql';
import {
  ReorderProductsDialog_AddItemMutation,
  ReorderProductsDialog_AddItemMutationVariables,
  ReorderProductsDialog_MoveItemMutation,
  ReorderProductsDialog_MoveItemMutationVariables,
  ReorderProductsDialog_RemoveItemMutation,
  ReorderProductsDialog_RemoveItemMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { getLogger } from '@engined/core/services/logger.js';
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from '@hello-pangea/dnd';
import { Add as AddIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';

const UNKNOWN_DROPPABLE = 'unknown';

const logger = getLogger('components/modals/ReorderProductsDialog');

interface OrderReceivedItem {
  id: string;
  name: string;
  position?: number;
  parentId?: string;
}

interface Product {
  id: string;
  name: string;
  position: number;
  productionNote?: string;
  updatedAt: string;
  items?: OrderReceivedItem[];
}

interface OrderReceived {
  id: string;
  moneyNumber?: string;
  products?: Product[];
  items?: OrderReceivedItem[];
}

interface OwnProps {
  open?: boolean;
  orderReceived: OrderReceived;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const ReorderProductsDialog: React.FunctionComponent<Props> = ({ open = false, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" PaperProps={{ sx: { backgroundColor: '#f8f8f8' } }}>
      <DialogTitle sx={{ bgcolor: 'white', borderBottom: 1, borderColor: 'divider' }}>Preskupenie položiek</DialogTitle>
      <ReorderProductsDialogContent onClose={onClose} isOpen={open} {...rest} />
    </Dialog>
  );
};

ReorderProductsDialog.displayName = 'ReorderProductsDialog';

export default ReorderProductsDialog;

interface ReorderProductsDialogContentOwnProps {
  orderReceived: OrderReceived;
  isOpen: boolean;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const ReorderProductsDialogContent: React.FunctionComponent<ReorderProductsDialogContentOwnProps> = ({
  orderReceived,
  isOpen,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [moveExecute] = useMutation<
    ReorderProductsDialog_MoveItemMutation,
    ReorderProductsDialog_MoveItemMutationVariables
  >(ReorderProductsDialog_MoveItem);
  const [addItemExecute] = useMutation<
    ReorderProductsDialog_AddItemMutation,
    ReorderProductsDialog_AddItemMutationVariables
  >(ReorderProductsDialog_AddItem);
  const [removeItemExecute] = useMutation<
    ReorderProductsDialog_RemoveItemMutation,
    ReorderProductsDialog_RemoveItemMutationVariables
  >(ReorderProductsDialog_RemoveItem);

  const onDragEnd = useEventCallback<DragDropContextProps['onDragEnd']>(
    async ({ source, destination, draggableId }) => {
      // setDraggingTask(null);

      // Drop outside of droppable
      if (!destination) {
        return;
      }

      // Nothing has moved
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      const fromProduct = orderReceived.products.find((p) => p.id === source.droppableId);
      const toProduct = orderReceived.products.find((p) => p.id === destination.droppableId);

      const updatedProducts: Product[] = [];
      if (source.droppableId === destination.droppableId) {
        const newItems = fromProduct.items.slice(0);
        [newItems[source.index], newItems[destination.index]] = [newItems[destination.index], newItems[source.index]];
        updatedProducts.push({
          ...fromProduct,
          items: newItems,
        });
      } else if (source.droppableId === UNKNOWN_DROPPABLE) {
        const item = orderReceived.items.find((i) => i.id === draggableId);
        const newItems = toProduct.items.slice(0);
        newItems.splice(destination.index, 0, item);
        updatedProducts.push({
          ...toProduct,
          items: newItems,
        });
      } else if (destination.droppableId === UNKNOWN_DROPPABLE) {
        updatedProducts.push({
          ...fromProduct,
          items: fromProduct.items.filter((i) => i.id !== draggableId),
        });
      } else {
        const item = fromProduct.items.find((i) => i.id === draggableId);
        updatedProducts.push({
          ...fromProduct,
          items: fromProduct.items.filter((i) => i.id !== draggableId),
        });

        const newItems = toProduct.items.slice(0);
        newItems.splice(destination.index, 0, item);
        updatedProducts.push({
          ...toProduct,
          items: newItems,
        });
      }

      try {
        if (source.droppableId === UNKNOWN_DROPPABLE) {
          await addItemExecute({
            variables: {
              productId: toProduct.id,
              productUpdatedAt: toProduct.updatedAt,
              id: draggableId,
              position: destination.index,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              productItemAdd: updatedProducts[0],
            },
          });
        } else if (destination.droppableId === UNKNOWN_DROPPABLE) {
          await removeItemExecute({
            variables: {
              productId: fromProduct.id,
              productUpdatedAt: fromProduct.updatedAt,
              id: draggableId,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              productItemRemove: updatedProducts[0],
            },
          });
        } else {
          await moveExecute({
            variables: {
              fromProductId: fromProduct.id,
              fromProductUpdatedAt: fromProduct.updatedAt,
              toProductId: toProduct.id,
              id: draggableId,
              position: destination.index,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              productItemMove: updatedProducts,
            },
          });
        }
      } catch (err) {
        displayError(err, enqueueSnackbar, logger);
      }
    },
  );

  const itemsWithoutProduct = useMemo(() => {
    const inProducts = orderReceived.products.reduce((acc, cur) => acc.concat(cur.items.map((i) => i.id)), []);
    return orderReceived.items
      .filter((item) => !inProducts.includes(item.id) && !item.parentId)
      .sort((a, b) => a.position - b.position);
  }, [orderReceived]);

  return (
    <>
      <DialogContent>
        <Box sx={{ py: 4 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Box textAlign="right" mb={4}>
              <CreateProductDialogButton
                orderReceivedId={orderReceived.id}
                refetchQueries={['OrderReceived_Load']}
                color="primary"
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Pridať produkt
              </CreateProductDialogButton>
            </Box>

            <Box>
              {orderReceived.products.map((product) => (
                <ReorderProduct
                  key={product.id}
                  moneyNumber={orderReceived.moneyNumber}
                  product={product}
                  isOpen={isOpen}
                />
              ))}
              <Droppable droppableId={UNKNOWN_DROPPABLE} isDropDisabled={!isOpen}>
                {(provided, snapshot) => (
                  <Box
                    p={4}
                    sx={{
                      backgroundColor: snapshot.isDraggingOver ? '#ffe0cf' : undefined,
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {itemsWithoutProduct.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isOpen}>
                        {(draggableProvided, draggableSnapshot) => (
                          <Box
                            mb={2}
                            sx={{ cursor: 'grab' }}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Box
                              p={1}
                              sx={{
                                background: '#eee',
                                transform:
                                  draggableSnapshot.isDragging && !draggableSnapshot.isDropAnimating
                                    ? 'transform: rotateZ(3deg)'
                                    : undefined,
                              }}
                            >
                              {item.name}
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions sx={{ bgcolor: 'white', borderTop: 1, borderColor: 'divider' }}>
        <Button type="button" onClick={onClose} size="medium">
          Zavrieť
        </Button>
      </DialogActions>
    </>
  );
};

ReorderProductsDialogContent.displayName = 'ReorderProductsDialogContent';

interface ReorderProductOwnProps {
  product: Product;
  isOpen: boolean;
  moneyNumber: string;
}

const ReorderProduct: React.FunctionComponent<ReorderProductOwnProps> = ({ product, moneyNumber, isOpen }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onToggleCollapse = useEventCallback((event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    event.preventDefault();
    setCollapsed((s) => !s);
  });

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: (theme) => `3px solid ${theme.palette.primary.main}`,
        background: '#fff',
        mb: 4,
      }}
    >
      <Box
        sx={{ display: 'flex', cursor: 'pointer', px: 4, py: 2, borderBottom: 1, borderColor: 'divider' }}
        onClick={onToggleCollapse}
      >
        <Box flex={1}>
          <Box>
            <strong>{product.name}</strong>
          </Box>
          <Box fontSize="0.9em">
            {moneyNumber}-{product.position}
          </Box>
        </Box>

        <IconButton size="small">{collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
      </Box>

      <Collapse in={!collapsed}>
        <Droppable droppableId={product.id} isDropDisabled={!isOpen}>
          {(droppableProvided, droppableSnapshot) => (
            <Box
              p={4}
              sx={{
                backgroundColor: droppableSnapshot.isDraggingOver ? '#ffe0cf' : '#fff',
              }}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {product.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isOpen}>
                  {(draggableProvided, draggableSnapshot) => (
                    <Box
                      mb={2}
                      sx={{ cursor: 'grab' }}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <Box
                        p={1}
                        sx={{
                          background: '#eee',
                          transform:
                            draggableSnapshot.isDragging && !draggableSnapshot.isDropAnimating
                              ? 'transform: rotateZ(3deg)'
                              : undefined,
                        }}
                      >
                        {item.name}
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </Box>
          )}
        </Droppable>
      </Collapse>
    </Box>
  );
};

ReorderProduct.displayName = 'ReorderProduct';
