import { Descendant, Element, Text } from 'slate';

const everyEmpty = (node: Descendant) => {
  return (Text.isText(node) && !node.text) || (Element.isElement(node) && node.children.every(everyEmpty));
};

export function isEmpty(value: Descendant | Descendant[]): boolean {
  if (!value) {
    return true;
  }

  if (Array.isArray(value)) {
    return !value?.length || value.every(everyEmpty);
  } else {
    return everyEmpty(value);
  }
}

export function convertToString(node: Descendant | Descendant[]): string {
  if (Text.isText(node)) {
    return node.text;
  }

  if (Element.isElement(node)) {
    switch (node.type) {
      case 'paragraph': {
        const text = node.children.reduce((acc, cur) => acc + convertToString(cur), '').trim();
        return text ? text + '\n\n' : text;
      }

      case 'bulleted-list': {
        const text = node.children.reduce((acc, cur) => acc + ` • ${convertToString(cur)}\n`, '').trim();
        return text ? text + '\n\n' : text;
      }

      case 'numbered-list': {
        const text = node.children
          .reduce((acc, cur, index) => acc + ` ${index + 1}) ${convertToString(cur)}\n`, '')
          .trim();
        return text ? text + '\n\n' : text;
      }

      default: {
        console.log(node);
        return node.children.reduce((acc, cur) => acc + convertToString(cur), '').trim();
      }
    }
  }

  return node.reduce((acc, cur) => acc + convertToString(cur), '');
}
