import React from 'react';

interface OwnProps {
  text: string;
}

type Props = OwnProps;

const Nl2Br: React.FunctionComponent<Props> = ({ text }) => (
  <>
    {text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {i !== 0 && <br />}
        {line}
      </React.Fragment>
    ))}
  </>
);

Nl2Br.displayName = 'Nl2Br';

export default React.memo<Props>(Nl2Br);
