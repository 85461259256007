import { DROPZONE_UNKNOWN_ERROR } from '@asaprint/asap/locales/client.js';
import { Resource } from '@asaprint/asap/schema.client.types.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { useSSR } from '@engined/client/contexts/SSRContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { Resource as I18nResource } from '@engined/core/services/i18n.js';
import { getLogger } from '@engined/core/services/logger.js';
import { useEffect, useState } from 'react';

const logger = getLogger('@ui/hooks/useFileUpload');

export enum UploadStatus {
  PROCESSING,
  COMPLETE,
  ERROR,
}

interface Options {
  errorMessage?: I18nResource;
  authorization?: string;
  uploadUrl?: string;
  onUploaded?(resource: Resource);
  onStart?(file: File);
  onDone?(file: File, error?: string);
}

interface Return {
  preview: string;
  status: UploadStatus;
  error: string | null;
  progress: number;
}

export default function useFileUpload(
  file: File,
  {
    onStart,
    onDone,
    onUploaded,
    authorization,
    errorMessage = DROPZONE_UNKNOWN_ERROR,
    uploadUrl = '/uploads/',
  }: Options = {},
): Return {
  const { t } = useLocale();
  const { csrfToken } = useSSR();
  const [status, setStatus] = useState<UploadStatus>(UploadStatus.PROCESSING);
  const [preview, setPreview] = useState<string>(null);
  const [error, setError] = useState<string>(null);
  const [progress, setProgress] = useState<number>(0);

  const onUploadedCallback = useEventCallback((resource: Resource) => {
    if (onUploaded) {
      onUploaded(resource);
    }
  });

  const onStartCallback = useEventCallback((file: File) => {
    if (onStart) {
      onStart(file);
    }
  });

  const onDoneCallback = useEventCallback((file: File, error?: string) => {
    if (onDone) {
      onDone(file, error);
    }
  });

  useEffect(() => {
    setPreview(URL.createObjectURL(file));

    const data = new FormData();
    const req = new XMLHttpRequest();
    data.append('file', file);
    req.addEventListener('load', (event) => {
      // Set progress
      if (req.status === 200) {
        setStatus(UploadStatus.COMPLETE);
        setError(null);
        onDoneCallback(file);
        onUploadedCallback(req.response[0]);
      } else {
        setStatus(UploadStatus.ERROR);
        setError(req.response?.error || req.response);
        logger.error(new Error(`Cannot upload file - server status: ${req.status}`), { response: req.response });
        onDoneCallback(file, req.response?.error || req.response);
      }
    });

    req.addEventListener('error', (event) => {
      // Set progress
      setStatus(UploadStatus.ERROR);
      setError(t(errorMessage));
      logger.error(new Error('An error occurred during upload'), { event });
      onDoneCallback(file);
    });

    // Upload progress on request.upload
    req.upload.addEventListener('progress', (event) => {
      // Set progress
      setProgress((event.loaded / event.total) * 100);
    });

    req.responseType = 'json';
    req.open('post', uploadUrl);
    if (csrfToken) {
      req.setRequestHeader('CSRF-Token', csrfToken);
    }
    if (authorization) {
      req.setRequestHeader('Authorization', authorization);
    }
    req.withCredentials = true;
    req.send(data);
    onStartCallback(file);

    return () => {
      req.abort();
      onDoneCallback(file);
    };
  }, [
    file,
    onStartCallback,
    onDoneCallback,
    onUploadedCallback,
    t,
    setProgress,
    authorization,
    csrfToken,
    uploadUrl,
    errorMessage,
  ]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview);
    };
  }, [preview]);

  return {
    status,
    preview,
    error,
    progress,
  };
}
