import { useMutation } from '@apollo/client/index.js';
import FinishOrderReceivedDialog from '@asaprint/asap/components/dialogs/FinishOrderReceivedDialog.js';
import OrderReceivedPlannedDialog from '@asaprint/asap/components/dialogs/OrderReceivedPlannedDialog.js';
import PauseOrderReceivedDialog from '@asaprint/asap/components/dialogs/PauseOrderReceivedDialog.js';
import AssignedUsersField, { AssignedUserOption } from '@asaprint/asap/components/forms/fields/AssignedUsersField.js';
import { OrderReceivedActions_StartTracking } from '@asaprint/asap/components/OrderReceivedActions.graphql';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  OrderReceivedActions as Actions,
  OrderReceivedActions_StartTrackingMutation,
  OrderReceivedActions_StartTrackingMutationVariables,
  OrderReceivedInput,
} from '@asaprint/asap/schema.client.types.js';
import { hasPermission, Permission } from '@asaprint/common/access.js';
import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';
import { OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import { PaymentTypes } from '@asaprint/common/constants/PaymentType.js';
import { isAssemblyDelivery, isCourierDelivery, isPickupDelivery } from '@asaprint/common/helpers/DeliveryType.js';
import { areCustomerEmailsIgnored, isOrderReceivedFromEshop } from '@asaprint/common/helpers/OrderReceived.js';
import { isCardPayment, isCashPayment, isFreePayment, isInvoiceNeeded } from '@asaprint/common/helpers/PaymentType.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import ConfirmDialog from '@engined/client/components/dialogs/ConfirmDialog.js';
import FontAwesomeSvgIcon from '@engined/client/components/FontAwesomeSvgIcon.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { MailOutline as MailOutlineIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonGroup, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';

const logger = getLogger('components/OrderReceivedActions');

interface TimeEntry {
  id: string;
  updatedAt: string;
  user: User;
  startAt: string;
}

interface Task {
  id: string;
  activeTimeEntry?: TimeEntry;
  user: User;
}

interface OrderReceived {
  id: string;
  updatedAt: string;
  moneyNumber?: string;
  moneyDeliveryType?: {
    id: string;
  };
  moneyPaymentType?: {
    id: string;
  };
  phase: string;
  packageNumber?: string;
  tasks?: Task[];
}

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  username: string;
  role: string;
}

interface OwnProps {
  sx?: SxProps;
  orderReceived: OrderReceived;
  users: User[];

  onOrderReceivedUpdate(orderReceived: OrderReceivedInput);
}

type Props = OwnProps;

const OrderReceivedActions: React.FunctionComponent<Props> = ({ sx, orderReceived, onOrderReceivedUpdate, users }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [orderReceivedPlannedModalShow, setOrderReceivedPlannedModalShow] = useState<boolean>(false);

  const onOrderReceivedPlannedModalClose = useEventCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    setOrderReceivedPlannedModalShow(false);
  });

  const onAction = useEventCallback((event: React.MouseEvent<HTMLButtonElement>, action: Actions) => {
    event.preventDefault();
    onOrderReceivedUpdate({
      id: orderReceived.id,
      updatedAt: orderReceived.updatedAt,
      action,
    });
  });

  const onChecked1 = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) =>
    onAction(event, Actions.Checked_1),
  );

  const onCardPayment = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isCardPayment(orderReceived.moneyPaymentType?.id as PaymentTypes)) {
      enqueueSnackbar(
        'V Money S4 je nastavený odlišný spôsob platby. Ak sa spôsob platby zmenil, najskôr ho zmeňte v Money S4.',
        { variant: 'error' },
      );

      return;
    }

    onAction(event, Actions.CardPayment);
  });

  const onCashPayment = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isCashPayment(orderReceived.moneyPaymentType?.id as PaymentTypes)) {
      enqueueSnackbar(
        'V Money S4 je nastavený odlišný spôsob platby. Ak sa spôsob platby zmenil, najskôr ho zmeňte v Money S4.',
        { variant: 'error' },
      );

      return;
    }

    onAction(event, Actions.CashPayment);
  });

  const onCashPaymentWithout = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isCashPayment(orderReceived.moneyPaymentType?.id as PaymentTypes)) {
      enqueueSnackbar(
        'V Money S4 je nastavený odlišný spôsob platby. Ak sa spôsob platby zmenil, najskôr ho zmeňte v Money S4.',
        { variant: 'error' },
      );

      return;
    }

    onAction(event, Actions.CashPaymentWithout);
  });

  const onFreePayment = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isFreePayment(orderReceived.moneyPaymentType?.id as PaymentTypes)) {
      enqueueSnackbar(
        'V Money S4 je nastavený odlišný spôsob platby. Ak sa spôsob platby zmenil, najskôr ho zmeňte v Money S4.',
        { variant: 'error' },
      );

      return;
    }

    onAction(event, Actions.FreePayment);
  });

  const onNeedInvoice = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isInvoiceNeeded(orderReceived.moneyPaymentType?.id as PaymentTypes)) {
      enqueueSnackbar(
        'V Money S4 je nastavený odlišný spôsob platby. Ak sa spôsob platby zmenil, najskôr ho zmeňte v Money S4.',
        { variant: 'error' },
      );

      return;
    }

    onAction(event, Actions.NeedInvoice);
  });

  const onPlanned = useEventCallback(() => {
    setOrderReceivedPlannedModalShow(true);
  });

  const onChecked2 = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) =>
    onAction(event, Actions.Checked_2),
  );

  const onSkipWaiting = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) =>
    onAction(event, Actions.SkipWaiting),
  );

  const {
    open: confirmInvoicedDialogOpen,
    onClose: onConfirmInvoicedDialogOnClose,
    onOpen: onConfirmInvoicedDialogOnOpen,
  } = useDialog(false);

  let actions: React.ReactNode;
  if (
    orderReceived.phase === OrderReceivedPhase.CHECK_1 &&
    hasPermission(requestUser.permissions as Permission[], Permission.OrdersReceivedEditPhaseCheck1)
  ) {
    actions = (
      <Button
        color="primary"
        onClick={onChecked1}
        variant="contained"
        startIcon={
          !areCustomerEmailsIgnored(orderReceived.moneyNumber) &&
          (!orderReceived.moneyDeliveryType ||
            isPickupDelivery(orderReceived.moneyDeliveryType.id as DeliveryTypes)) ? (
            <FontAwesomeSvgIcon icon={faEnvelope} />
          ) : null
        }
      >
        Skontrolované
      </Button>
    );
  } else if (
    orderReceived.phase === OrderReceivedPhase.EXPEDITION &&
    hasPermission(requestUser.permissions as Permission[], Permission.OrdersReceivedEditPhaseExpedition)
  ) {
    if (
      !orderReceived.moneyDeliveryType ||
      orderReceived.moneyDeliveryType.id === DeliveryTypes.PICKUP ||
      orderReceived.moneyDeliveryType.id === DeliveryTypes.EMAIL
    ) {
      actions = (
        <>
          <Button color="primary" onClick={onCardPayment} variant="contained" sx={{ mb: { xs: 1, md: 0 } }}>
            Uhradené kartou
          </Button>{' '}
          <Button color="primary" onClick={onCashPayment} variant="contained" sx={{ mb: { xs: 1, md: 0 } }}>
            Uhradené v hotovosti
          </Button>{' '}
          <Button color="primary" onClick={onCashPaymentWithout} variant="contained" sx={{ mb: { xs: 1, md: 0 } }}>
            Uhradené v hotovosti (bez)
          </Button>{' '}
          <Button color="primary" onClick={onFreePayment} variant="contained" sx={{ mb: { xs: 1, md: 0 } }}>
            Uzavrieť bez platby
          </Button>{' '}
          <Button color="primary" onClick={onNeedInvoice} variant="contained" sx={{ mb: { xs: 1, md: 0 } }}>
            Fakturovať
          </Button>
        </>
      );
    } else if (isCourierDelivery(orderReceived.moneyDeliveryType?.id as DeliveryTypes)) {
      actions = (
        <TrackingActions
          orderReceived={orderReceived}
          users={users}
          label="Odovzdané kuriérovi s dodacím listom"
          icon={!areCustomerEmailsIgnored(orderReceived.moneyNumber) && <MailOutlineIcon />}
          onOrderReceivedUpdate={onOrderReceivedUpdate}
        />
      );
    } else if (isAssemblyDelivery(orderReceived.moneyDeliveryType?.id as DeliveryTypes)) {
      actions = (
        <>
          <Button color="primary" onClick={onCardPayment} variant="contained">
            Uhradené kartou
          </Button>{' '}
          <Button color="primary" onClick={onCashPayment} variant="contained">
            Uhradené v hotovosti
          </Button>{' '}
        </>
      );
    } else if (isInvoiceNeeded(orderReceived.moneyPaymentType?.id as PaymentTypes)) {
      actions = (
        <>
          <Button color="primary" onClick={onNeedInvoice} variant="contained">
            Fakturovať
          </Button>
        </>
      );
    }
  } else if (
    orderReceived.phase === OrderReceivedPhase.INVOICE &&
    hasPermission(requestUser.permissions as Permission[], Permission.OrdersReceivedEditPhaseInvoice)
  ) {
    const fromEshop = isOrderReceivedFromEshop(orderReceived);
    actions = (
      <>
        <Button color="primary" onClick={onConfirmInvoicedDialogOnOpen} variant="contained">
          Vyfakturované
        </Button>
        <ConfirmDialog
          open={confirmInvoicedDialogOpen}
          question="Skutočne bola objednávka vyfakturovaná?"
          onCancel={onConfirmInvoicedDialogOnClose}
          onOK={(event) => {
            onConfirmInvoicedDialogOnClose();
            if (fromEshop) {
              onAction(event, Actions.InvoicedAndEshopUpload);
            } else {
              onAction(event, Actions.Invoiced);
            }
          }}
        />
      </>
    );
  } else if (
    orderReceived.phase === OrderReceivedPhase.PLANNING &&
    hasPermission(requestUser.permissions as Permission[], Permission.OrdersReceivedEditPhasePlanning)
  ) {
    actions = (
      <LoadingButton color="primary" onClick={onPlanned} loading={orderReceivedPlannedModalShow} variant="contained">
        Skontrolované
      </LoadingButton>
    );
  } else if (
    orderReceived.phase === OrderReceivedPhase.CHECK_2 &&
    hasPermission(requestUser.permissions as Permission[], Permission.OrdersReceivedEditPhaseCheck2)
  ) {
    actions = (
      <Button color="primary" onClick={onChecked2} variant="contained">
        Skontrolované
      </Button>
    );
  } else if (
    orderReceived.phase === OrderReceivedPhase.WAITING_FOR_INVOICE &&
    hasPermission(requestUser.permissions as Permission[], Permission.OrdersReceivedEditPhaseWaitingForInvoice)
  ) {
    actions = (
      <Button color="primary" onClick={onSkipWaiting} variant="contained">
        Zákazník chce faktúru skôr
      </Button>
    );
  } else if (orderReceived.phase === OrderReceivedPhase.PACKAGING) {
    actions = (
      <TrackingActions
        orderReceived={orderReceived}
        users={users}
        label="Zabalené"
        onOrderReceivedUpdate={onOrderReceivedUpdate}
      />
    );
  } else if (orderReceived.phase === OrderReceivedPhase.ASSEMBLY) {
    actions = (
      <TrackingActions
        orderReceived={orderReceived}
        users={users}
        label="Namontované"
        onOrderReceivedUpdate={onOrderReceivedUpdate}
      />
    );
  } else if (orderReceived.phase === OrderReceivedPhase.DELIVERY) {
    actions = (
      <TrackingActions
        orderReceived={orderReceived}
        users={users}
        label="Odovzdané odberateľovi s dodacím lístom"
        icon={!areCustomerEmailsIgnored(orderReceived.moneyNumber) && <MailOutlineIcon />}
        onOrderReceivedUpdate={onOrderReceivedUpdate}
      />
    );
  }

  return (
    <Box gap={1} sx={sx}>
      {actions}
      <OrderReceivedPlannedDialog
        open={orderReceivedPlannedModalShow}
        onClose={onOrderReceivedPlannedModalClose}
        orderReceived={orderReceived}
      />
    </Box>
  );
};

OrderReceivedActions.displayName = 'OrderReceivedActions';

export default React.memo<Props>(OrderReceivedActions);

interface FormValues {
  assignedUsers: AssignedUserOption[];
}

const TrackingActions: React.FunctionComponent<{
  label: React.ReactNode;
  icon?: React.ReactNode;
  orderReceived: OrderReceived;
  users: User[];
  onOrderReceivedUpdate(orderReceived: OrderReceivedInput);
}> = ({ label, icon, orderReceived, users, onOrderReceivedUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();

  const assignedUsersOptions = useMemo<AssignedUserOption[]>(
    () =>
      users?.map(
        (u): AssignedUserOption => ({
          label: displayName(u),
          value: u.id,
          data: {
            disableRemove: orderReceived.tasks.some((t) => t.activeTimeEntry?.user.id === u.id),
            isWorking: orderReceived.tasks.some((t) => t.activeTimeEntry?.user.id === u.id),
          },
        }),
      ) ?? [],
    [users, orderReceived],
  );

  const isIWorking = orderReceived?.tasks
    .filter((t) => t.activeTimeEntry)
    .some((t) => t.activeTimeEntry.user.id === requestUser.id);
  const isOthersWorking = orderReceived?.tasks
    .filter((t) => t.activeTimeEntry)
    .some((t) => t.activeTimeEntry.user.id !== requestUser.id);

  const {
    open: pauseOrderReceivedDialogOpen,
    onClose: pauseOrderReceivedDialogOnClose,
    onOpen: pauseOrderReceivedDialogOnOpen,
  } = useDialog(false);
  const {
    open: finishOrderReceivedDialogOpen,
    onClose: finishOrderReceivedDialogOnClose,
    onOpen: finishOrderReceivedDialogOnOpen,
  } = useDialog(false);

  const onFinishOrderReceived = useEventCallback((event) => {
    if (orderReceived.phase === OrderReceivedPhase.EXPEDITION && !orderReceived.packageNumber) {
      enqueueSnackbar('Zadaj číslo zásielky.', { variant: 'error' });
    } else {
      finishOrderReceivedDialogOnOpen(event);
    }
  });

  const canPause = isIWorking;
  const canProcess = !isIWorking;
  const canFinish = !isOthersWorking;

  const [startTrackingExecute] = useMutation<
    OrderReceivedActions_StartTrackingMutation,
    OrderReceivedActions_StartTrackingMutationVariables
  >(OrderReceivedActions_StartTracking);
  const onStartCallback = useEventCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const response = await startTrackingExecute({
        variables: {
          id: orderReceived.id,
          updatedAt: orderReceived.updatedAt,
        },
      });
    } catch (err) {
      logger.error(err);
    }
  });

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    const value = values.assignedUsers.map((v) => v.value);
    const usersKey = {
      [OrderReceivedPhase.PACKAGING]: 'packagingAssignedTo',
      [OrderReceivedPhase.DELIVERY]: 'deliveryAssignedTo',
      [OrderReceivedPhase.EXPEDITION]: 'expeditionAssignedTo',
      [OrderReceivedPhase.ASSEMBLY]: 'assemblyAssignedTo',
    }[orderReceived.phase];
    onOrderReceivedUpdate({
      id: orderReceived.id,
      updatedAt: orderReceived.updatedAt,
      [usersKey]: value,
    });
  };

  const values = useMemo(
    () => ({
      assignedUsers: (
        orderReceived?.tasks
          .map((t) => t.user)
          .map((u) => assignedUsersOptions.find((o) => o.value === u.id))
          .filter(Boolean) ?? []
      ).sort((a, b) => a.label.localeCompare(b.label)),
    }),
    [orderReceived?.tasks, assignedUsersOptions],
  );

  return (
    <Box display="flex" alignItems={{ xs: 'normal', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }} gap={1}>
      <Form
        values={values}
        onSubmit={onSubmit}
        validate={validate}
        onChange={(data, { name, type }, form) => {
          form.handleSubmit((values) => onSubmit(values, form))();
        }}
      >
        <AssignedUsersField
          name="assignedUsers"
          options={assignedUsersOptions}
          inputLabel="Priradená osoba"
          size="small"
          sx={{ ml: { xs: 0, md: 8 }, mr: { xs: 0, md: 4 }, minWidth: { xs: '100%', md: '300px' } }}
        />
      </Form>
      <ButtonGroup sx={{ mb: { xs: 2, md: 0 } }}>
        <Button
          disabled={!canProcess}
          color={canProcess ? 'primary' : 'secondary'}
          variant={canProcess ? 'contained' : 'outlined'}
          onClick={onStartCallback}
          size="small"
          disableElevation
        >
          Začať
        </Button>
        <Button
          disabled={!canPause}
          color={canPause ? 'primary' : 'secondary'}
          variant={canPause ? 'contained' : 'outlined'}
          onClick={pauseOrderReceivedDialogOnOpen}
          size="small"
          disableElevation
        >
          Pozastaviť
        </Button>
      </ButtonGroup>
      <Button
        disabled={!canFinish}
        color={canFinish ? 'primary' : 'secondary'}
        variant={canFinish ? 'contained' : 'outlined'}
        onClick={onFinishOrderReceived}
        size="small"
        startIcon={icon}
        sx={{ mb: { xs: 2, md: 0 } }}
      >
        {label}
      </Button>

      <PauseOrderReceivedDialog
        orderReceived={orderReceived}
        open={pauseOrderReceivedDialogOpen}
        onClose={pauseOrderReceivedDialogOnClose}
      />
      <FinishOrderReceivedDialog
        orderReceived={orderReceived}
        open={finishOrderReceivedDialogOpen}
        onClose={finishOrderReceivedDialogOnClose}
      />
    </Box>
  );
};
TrackingActions.displayName = 'TrackingActions';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};
  return errors;
}
