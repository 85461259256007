import { PRODUCTS_PRINT_LABEL_ROUTE } from '@asaprint/asap/routes.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { Dialog, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/PrintPackageLabelDialog');

interface OwnProps {
  className?: string;
  open?: boolean;
  productId: string;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const PrintPackageLabelDialog: React.FunctionComponent<Props> = ({ className, productId, open, onClose }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
      <PrintPackageLabelDialogContent productId={productId} onClose={onClose} />
    </Dialog>
  );
};

PrintPackageLabelDialog.displayName = 'PrintPackageLabelDialog';

export default PrintPackageLabelDialog;

interface FormValues {
  count: number;
}

interface PrintPackageLabelDialogOwnProps {
  productId: string;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const PrintPackageLabelDialogContent: React.FunctionComponent<PrintPackageLabelDialogOwnProps> = ({
  productId,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      window.open(url(PRODUCTS_PRINT_LABEL_ROUTE, { id: productId, ext: 'pdf' }, { count: values.count }));
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        count: 1,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <DialogTitle>Tlač štítku</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          name="count"
          label="Počet"
          type="number"
          fullWidth
          InputProps={{ endAdornment: <InputAdornment position="end">ks</InputAdornment> }}
          inputProps={{
            min: '1',
            max: '100',
            step: '1',
          }}
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Vytlačiť" />
    </Form>
  );
};

PrintPackageLabelDialogContent.displayName = 'PrintPackageLabelDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.count) {
    errors.count = 'Počet musí byť zadaný';
  } else if (values.count < 0 || values.count >= 100) {
    errors.count = 'Počet musí byť kladný a menší ako 100';
  }

  return errors;
}
