import { useMutation } from '@apollo/client/index.js';
import { OrderReceivedPlannedDialog_Save } from '@asaprint/asap/components/dialogs/OrderReceivedPlannedDialog.graphql';
import UserField from '@asaprint/asap/components/forms/fields/UserField.js';
import {
  OrderReceivedPlannedDialog_SaveMutation,
  OrderReceivedPlannedDialog_SaveMutationVariables,
  UserField_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Role } from '@asaprint/common/access.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const logger = getLogger('components/modals/OrderReceivedPlannedModal');

interface OrderReceived {
  id: string;
  updatedAt: string;
}

interface OwnProps {
  open?: boolean;
  orderReceived: OrderReceived;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const OrderReceivedPlannedDialog: React.FunctionComponent<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <OrderReceivedPlannedDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

OrderReceivedPlannedDialog.displayName = 'OrderReceivedPlannedModal';

export default OrderReceivedPlannedDialog;

interface FormValues {
  assemblyAssignedTo: Option<UserField_UserFragment>;
}

interface OrderReceivedPlannedModalContentOwnProps {
  orderReceived: OrderReceived;

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const roles = [
  Role.PrintOperator,
  Role.Operator,
  Role.AccountManagerAndOperator,
  Role.OperatorAndGraphicDesigner,
  Role.Admin,
  Role.Expediter,
];

const OrderReceivedPlannedDialogContent: React.FunctionComponent<OrderReceivedPlannedModalContentOwnProps> = ({
  orderReceived,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saveExecute] = useMutation<
    OrderReceivedPlannedDialog_SaveMutation,
    OrderReceivedPlannedDialog_SaveMutationVariables
  >(OrderReceivedPlannedDialog_Save);

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          id: orderReceived.id,
          updatedAt: orderReceived.updatedAt,
          assemblyAssignedTo: values.assemblyAssignedTo?.value ?? null,
        },
      });
      enqueueSnackbar('Úspešne ste zmenili fázu objednávky.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <Form
      defaultValues={{
        assemblyAssignedTo: null,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <DialogTitle>Skontrolované</DialogTitle>
      <DialogContent>
        <DialogContentText>Vyberte osobu, ktorá bude zodpovedná za montáž</DialogContentText>
        <UserField
          active
          roles={roles}
          name="assemblyAssignedTo"
          inputLabel="Priradená osoba"
          inputVariant="standard"
          autoFocus
        />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

OrderReceivedPlannedDialogContent.displayName = 'OrderReceivedPlannedModalContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (!values.assemblyAssignedTo) {
    errors.assemblyAssignedTo = 'Vyberte zodpovednú osobu';
  }

  return errors;
}
