import { useMutation } from '@apollo/client/index.js';
import { PauseOrderReceivedDialog_Save } from '@asaprint/asap/components/dialogs/PauseOrderReceivedDialog.graphql';
import TimeEntryForm from '@asaprint/asap/components/forms/TimeEntryForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  PauseOrderReceivedDialog_SaveMutation,
  PauseOrderReceivedDialog_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('components/modals/PauseOrderReceivedDialog');

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  role: string;
  username: string;
}

interface TimeEntry {
  id: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  note?: string;
  user: User;
}

interface Task {
  id: string;
  updatedAt?: string;
  user: User;
  activeTimeEntry?: TimeEntry;
}

interface OrderReceived {
  id: string;
  updatedAt: string;
  tasks?: Task[];
}

interface OwnProps {
  open?: boolean;
  orderReceived: OrderReceived;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const PauseOrderReceivedDialog: React.FunctionComponent<Props> = ({ open, onClose, ...rest }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <PauseOrderReceivedDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

PauseOrderReceivedDialog.displayName = 'PauseOrderReceivedDialog';

export default PauseOrderReceivedDialog;

interface TimeEntryFormValues {
  id: string;
  updatedAt: string;
  startAt: Date;
  endAt: Date;
  note: string;
  position: string;
}

interface FormValues {
  timeEntry: TimeEntryFormValues;
}

interface PauseOrderReceivedDialogContentOwnProps {
  orderReceived: OrderReceived;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const PauseOrderReceivedDialogContent: React.FunctionComponent<PauseOrderReceivedDialogContentOwnProps> = ({
  orderReceived,
  refetchQueries,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [saveExecute] = useMutation<
    PauseOrderReceivedDialog_SaveMutation,
    PauseOrderReceivedDialog_SaveMutationVariables
  >(PauseOrderReceivedDialog_Save, {
    refetchQueries,
  });

  const onSubmit: OnSubmit<FormValues> = async (values: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          id: orderReceived.id,
          updatedAt: orderReceived.updatedAt,
          timeEntry: values.timeEntry && {
            ...values.timeEntry,
            position: values.timeEntry.position ? parseInt(values.timeEntry.position) : null,
            startAt: values.timeEntry.startAt.toISOString(),
            endAt: values.timeEntry.endAt.toISOString(),
          },
        },
      });
      enqueueSnackbar('Úspešne ste pozastavili práce.', { variant: 'success' });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const myTask = orderReceived.tasks.find((t) => t.user.id === requestUser.id && t.activeTimeEntry);
  const myTimeEntry = myTask?.activeTimeEntry;
  const initialValues = useMemo((): FormValues => {
    const now = new Date();
    const startAt = myTimeEntry?.startAt ? new Date(myTimeEntry.startAt) : new Date();
    const endAt = startAt > now ? startAt : now;
    return {
      timeEntry: myTimeEntry
        ? {
            id: myTimeEntry.id,
            updatedAt: myTimeEntry.updatedAt,
            startAt,
            endAt: myTimeEntry.endAt ? new Date(myTimeEntry.endAt) : endAt,
            note: myTimeEntry.note ?? '',
            position: null,
          }
        : null,
    };
  }, [myTimeEntry]);

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <DialogTitle>Pozastavenie</DialogTitle>
      <DialogContent>
        {myTimeEntry && (
          <>
            <Box component="h3" mb={1}>
              Strávený čas
            </Box>
            <Box mb={5}>
              <Box component="h5" mb={1}>
                {displayName(myTask.user)}
              </Box>
              <TimeEntryForm prefix={`timeEntry.`} />
            </Box>
          </>
        )}
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Uložiť" />
    </Form>
  );
};

PauseOrderReceivedDialogContent.displayName = 'PauseOrderReceivedDialogContent';

function validate(values: FormValues) {
  const errors: FormErrors<FormValues> = {};

  if (values.timeEntry) {
    const timeEntryErrors: FormErrors<TimeEntryFormValues> = {};
    if (!values.timeEntry.startAt) {
      timeEntryErrors.startAt = 'Začiatok je povinný';
      errors.timeEntry = timeEntryErrors;
    }

    if (!values.timeEntry.endAt) {
      timeEntryErrors.endAt = 'Koniec je povinný';
      errors.timeEntry = timeEntryErrors;
    }

    if (values.timeEntry.startAt > values.timeEntry.endAt) {
      timeEntryErrors.endAt = 'Koniec je pred začiatkom';
      errors.timeEntry = timeEntryErrors;
    }
  }

  return errors;
}
