import PersonContactInfo from '@asaprint/asap/components/PersonContactInfo.js';
import SectionTitle from '@asaprint/asap/components/SectionTitle.js';
import {
  ORDER_RECEIVED_CRN,
  ORDER_RECEIVED_LOCAL_VAT_ID,
  ORDER_RECEIVED_VAT_ID,
} from '@asaprint/asap/locales/client.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { Box } from '@mui/material';
import React from 'react';

interface Person {
  telephone1Number?: string;
  telephone2Number?: string;
  telephone3Number?: string;
  telephone4Number?: string;
  email?: string;
}

const AboutCustomer: React.FC<{
  orderReceived: {
    moneyAddressName?: string;
    moneyAddressStreet?: string;
    moneyAddressZIPCode?: string;
    moneyAddressCity?: string;
    moneyAddressCountry?: string;
    moneyCrn?: string;
    moneyLocalVatId?: string;
    moneyVatId?: string;
    moneyAddressContactName?: string;
    moneyAddressContactPerson?: Person;
    moneyInvoiceAddressName?: string;
    moneyInvoiceAddressStreet?: string;
    moneyInvoiceAddressZIPCode?: string;
    moneyInvoiceAddressCity?: string;
    moneyInvoiceAddressCountry?: string;
    moneyAddressInvoiceName?: string;
    moneyInvoiceAddressPerson?: Person;
  };
}> = ({ orderReceived }) => {
  const { t } = useLocale();
  return (
    <>
      <SectionTitle>Odberateľ</SectionTitle>
      <Box mb={2}>
        {orderReceived.moneyAddressName}
        <br />
        {orderReceived.moneyAddressStreet}
        <br />
        {orderReceived.moneyAddressZIPCode} {orderReceived.moneyAddressCity}
        <br />
        {orderReceived.moneyAddressCountry}
      </Box>
      <Box mb={2}>
        {t(ORDER_RECEIVED_CRN)}: {orderReceived.moneyCrn}
        <br />
        {t(ORDER_RECEIVED_LOCAL_VAT_ID)}: {orderReceived.moneyLocalVatId}
        <br />
        {t(ORDER_RECEIVED_VAT_ID)}: {orderReceived.moneyVatId}
        <br />
      </Box>
      <Box mb={2}>
        {orderReceived.moneyAddressContactName}
        <PersonContactInfo person={orderReceived.moneyAddressContactPerson} />
      </Box>
      <SectionTitle>Fakturačná adresa</SectionTitle>
      <Box mb={2}>
        {orderReceived.moneyInvoiceAddressName}
        <br />
        {orderReceived.moneyInvoiceAddressStreet}
        <br />
        {orderReceived.moneyInvoiceAddressZIPCode} {orderReceived.moneyInvoiceAddressCity}
        <br />
        {orderReceived.moneyInvoiceAddressCountry}
      </Box>
      <Box mb={2}>
        {orderReceived.moneyAddressInvoiceName}
        <PersonContactInfo person={orderReceived.moneyInvoiceAddressPerson} />
      </Box>
    </>
  );
};

export default AboutCustomer;
