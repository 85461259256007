import { getLogger } from '@engined/core/services/logger.js';
import { Box } from '@mui/material';
import Element from '@asaprint/asap/components/slate/Element.js';
import Leaf from '@asaprint/asap/components/slate/Leaf.js';
import { SxProps } from '@mui/system';
import React from 'react';
import { Descendant, Text, Element as SlateElement, Node } from 'slate';

const logger = getLogger('@ui/components/slate/HTML');

interface OwnProps {
  value: Descendant[];
  noMargin?: boolean;
  sx?: SxProps;
}

type Props = OwnProps;

const serialize = (node: Descendant, index: number) => {
  if (Text.isText(node)) {
    return (
      <Leaf key={`leaf-${index}`} leaf={node}>
        {node.text}
      </Leaf>
    );
  }

  return (
    <Element key={`element-${index}`} element={node} mode="html">
      {node.children.map(serialize)}
    </Element>
  );
};

function removeEmptyParagraphsOnEdges(value: Descendant[]): Descendant[] {
  const res: Descendant[] = [];
  let empties: Descendant[] = [];
  for (const node of value) {
    const isEmpty = SlateElement.isElement(node) && node.type === 'paragraph' && Node.string(node).trim() === '';
    if (res.length === 0 && isEmpty) {
      continue;
    }

    if (isEmpty) {
      empties.push(node);
    } else {
      res.push(...empties);
      empties = [];
      res.push(node);
    }
  }
  return res;
}

const HTML: React.FunctionComponent<Props> = ({ value, noMargin, sx }) => {
  if (!value) {
    logger.error(new Error('Value of HTML is null or undefined'));
    return null;
  }

  return (
    <Box
      sx={{
        display: 'contents',
        whiteSpace: 'pre-wrap',
        ...(noMargin
          ? {
              '> p:first-child': {
                mt: 0,
              },
              '> p:last-child': {
                mb: 0,
              },
            }
          : {}),
        ...sx,
      }}
    >
      {removeEmptyParagraphsOnEdges(value).map(serialize)}
    </Box>
  );
};

HTML.displayName = 'HTML';

export default React.memo<Props>(HTML);
