import Editor from '@asaprint/asap/components/slate/Editor.js';
import HTML from '@asaprint/asap/components/slate/HTML.js';
import { ProductInput } from '@asaprint/asap/schema.client.types.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { Descendant } from 'slate';
import { isEqual } from 'lodash-es';

const initialGraphicAssignment: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

interface Props {
  product: {
    id: string;
    updatedAt: string;
    graphicAssignment?: Descendant[];
  };
  onProductChange(product: ProductInput, notify?: boolean);
}

const GraphicAssignment: React.FC<Props> = ({ product, onProductChange }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [graphicAssignment, setGraphicAssignment] = useState<Descendant[]>(
    product.graphicAssignment || initialGraphicAssignment,
  );

  const onGraphicAssignmentChange = useEventCallback((value: Descendant[]) => {
    setGraphicAssignment(value);
  });

  const onGraphicAssignmentSaveClickCallback = useEventCallback((notify: boolean) => {
    if (!isEqual(product.graphicAssignment, graphicAssignment)) {
      onProductChange(
        {
          id: product.id,
          updatedAt: product.updatedAt,
          graphicAssignment: graphicAssignment,
        },
        notify,
      );
    }
  });

  return (
    <Box>
      {isEditing ? (
        <>
          <Box p={2} border={1} borderRadius={1} borderColor="divider" mb={4}>
            <Editor
              id={`graphic-assignment-${product.id}`}
              value={graphicAssignment}
              placeholder="Sem napíš textové zadanie pre grafika"
              onChange={onGraphicAssignmentChange}
              allowFiles
              allowImages
            />
          </Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onGraphicAssignmentSaveClickCallback(true);
              setIsEditing(false);
            }}
          >
            Uložiť
          </Button>
        </>
      ) : (
        <>
          <HTML value={graphicAssignment} />
          <Button color="primary" variant="contained" onClick={() => setIsEditing(true)} sx={{ mt: 2 }}>
            Upraviť
          </Button>
        </>
      )}
    </Box>
  );
};

export default GraphicAssignment;
